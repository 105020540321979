<ngx-spinner type="ball-spin-clockwise">
  <h3 class="loading">BML MKTPL Loading...</h3>
</ngx-spinner>


<ng-container *ngIf="!isMaintenanceScheduled">
  <bml-main-nav-bar>
    <div class="main-content">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
      <bml-footer></bml-footer>
    </div> 
  </bml-main-nav-bar>
</ng-container>

<ng-container *ngIf="isMaintenanceScheduled">
  <bml-nav-bar></bml-nav-bar>
  <bml-maintenance></bml-maintenance>
</ng-container>
