import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';
import { IUser } from 'src/app/shared/models/user';
import { UserGenre } from 'src/app/shared/static-data/addressType';
import { ToastrService } from 'ngx-toastr';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';
import { CountriesWithDialCodeAndFalgs } from 'src/app/shared/static-data/country-with-dialcode-and-flags';

@Component({
  selector: 'bml-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
})
export class UserProfileFormComponent implements OnInit {
  userAccountForm!: UntypedFormGroup;
  errors!: string[];
  genres = UserGenre.genres;
  user!: IUser;

  @Input() componentName!: string;
  @Input() isAdmin: boolean = false;

  countriesList = CountriesWithDialCodeAndFalgs.data;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private toastr: ToastrService,
    private logger: LoggingErrorService
  ) {}

  ngOnInit(): void {
    this.createUserUpdateForm();
    this.getUserFormValues();
  }

  createUserUpdateForm() {
    this.userAccountForm = this.fb.group({
      firstName: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      lastName: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      userName: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      countryCode: ['', Validators.required],
      phoneNumber: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      genre: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
    });
  }

  getUserFormValues() {
    this.accountService.getUserProfile().subscribe(
      (response) => {
        if (response) {
          this.user = response;
          this.userAccountForm.patchValue(response);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {
    this.accountService.updateUserProfile(this.userAccountForm.value).subscribe(
      () => {
        this.toastr.success('Profile updated successfully');
        this.router.navigateByUrl('/' + this.componentName + '/profile');
      },
      (error) => {
        this.errors = error.errors;
        this.logger.log(error, this.componentName);
      }
    );
  }

  get genre() {
    return this.userAccountForm.get('genre');
  }
}
