import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { RatingModule } from 'ngx-bootstrap/rating';
import { StepperComponent } from './components/stepper/stepper.component';
import { ZippyComponent } from './components/zippy/zippy.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { SelectInputIdComponent } from './components/select-input-id/select-input-id.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { SelectPhoneCodeInputComponent } from './components/select-phone-code-input/select-phone-code-input.component';
import { SelectCountryWithFlagCodeInputComponent } from './components/select-country-with-flag-code-input/select-country-with-flag-code-input.component';
import { UserAddressComponent } from './components/user-address/user-address.component';
import { UserAddressFormComponent } from './components/user-address/user-address-form/user-address-form.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserProfileFormComponent } from './components/user-profile/user-profile-form/user-profile-form.component';
import { UserPasswordChangeComponent } from './components/user-profile/user-password-change/user-password-change.component';
import { PasswordRuleComponent } from './components/password-rule/password-rule.component';
import { MaterialSharedModule } from './material-shared.module';
import { UniquePipe, UniqueNamePipe } from './pipes/unique.pipe';
import { TimerCountDownComponent } from './components/timer-count-down/timer-count-down.component';
import { PagingHeaderComponent } from './components/paging-header/paging-header.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CartTableComponent } from './components/cart-table/cart-table.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { ProductPreviewComponent } from './components/product-preview/product-preview.component';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { ProductSliderComponent } from './components/product-slider/product-slider.component';
import { SearchComponent } from './components/search/search.component';
import { SpecialCharacterPipe } from './pipes/special-character.pipe';

@NgModule({
  declarations: [
    CartTableComponent,
    PaginationComponent,
    PagingHeaderComponent,
    ProductItemComponent,
    ProductPreviewComponent,
    TextInputComponent,
    TextAreaComponent,
    TextEditorComponent,
    SanitizeHtmlPipe,
    UniquePipe,
    UniqueNamePipe,
    SpecialCharacterPipe,
    StepperComponent,
    ZippyComponent,
    RecaptchaComponent,
    SelectInputComponent,
    SelectInputIdComponent,
    PhoneMaskDirective,
    SelectPhoneCodeInputComponent,
    SelectCountryWithFlagCodeInputComponent,
    UserAddressComponent,
    UserAddressFormComponent,
    UserProfileComponent,
    UserProfileFormComponent,
    UserPasswordChangeComponent,
    PasswordRuleComponent,
    TimerCountDownComponent,
    AnnouncementComponent,
    ProductSliderComponent,
    SearchComponent,
  ],
  imports: [
    CommonModule,
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    RatingModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    ReactiveFormsModule,
    SlickCarouselModule,
    FormsModule,
    MaterialSharedModule,
    CKEditorModule,
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule,
  ],
  exports: [
    CartTableComponent,
    CarouselModule,
    BsDropdownModule,
    PopoverModule,
    RatingModule,
    ModalModule,
    TooltipModule,
    PaginationModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MaterialSharedModule,
    CKEditorModule,
    SlickCarouselModule,
    //Component
    PaginationComponent,
    PagingHeaderComponent,
    ProductItemComponent,
    ProductPreviewComponent,
    TextInputComponent,
    TextAreaComponent,
    StepperComponent,
    TextEditorComponent,
    SanitizeHtmlPipe,
    UniquePipe,
    UniqueNamePipe,
    SpecialCharacterPipe,
    ZippyComponent,
    RecaptchaComponent,
    SelectInputComponent,
    SelectInputIdComponent,
    SelectPhoneCodeInputComponent,
    PhoneMaskDirective,
    SelectCountryWithFlagCodeInputComponent,
    UserAddressComponent,
    UserAddressFormComponent,
    UserProfileComponent,
    UserProfileFormComponent,
    UserPasswordChangeComponent,
    PasswordRuleComponent,
    TimerCountDownComponent,
    AnnouncementComponent,
    ProductSliderComponent,
    SearchComponent,
  ],
  providers: [],
})
export class SharedModule {}
