import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';
import { Department, IProduct, ProductParams } from 'src/app/shared/models/product';
import { SalesOfferService } from 'src/app/shop/sales-offer/sales-offer.service';
import { ShopService } from 'src/app/shop/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/shop/category/category.service';

@Component({
  selector: 'bml-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.scss', '../../../app.custom-row.scss'],
})
export class ProductPreviewComponent implements OnInit, AfterViewInit {
  products: IProduct[] = [];
  loadMoreProducts: IProduct[] = [];
  productParams!: ProductParams;
  totalCount: number = 0;
  @Input() isBackgroundNotWhite: boolean = false;
  @Input() bmlCol8: boolean = false;
  @Input() pageSize: number = 24;
  @Input() isLoadPagination: boolean = false;
  @Input() initialCount: number = 21;
  addOrRemoveCount: number = 7;

  onSale: boolean = false;

  component = "product-preview";

  constructor(
    private productService: ShopService,
    private categoryService: CategoryService,
    private service: SalesOfferService,
    private logger: LoggingErrorService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.productParams = productService.getProductsParams();
    this.productParams.pageSize = this.pageSize;
    if(this.bmlCol8) {
      this.addOrRemoveCount = 8;
    }
  }
  ngAfterViewInit(): void {
    this.getProducts(true);
  }

  ngOnInit(): void {
    //this.getOfferProducts();
    //this.getProducts(true);
  }

  

  getProducts(useCache = false) {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.productService.getProduct(id).subscribe(
        (response) => {
          this.getProductsByCategory(useCache, response.productCategory)
        },
        (error) => {
          this.logger.log(error, this.component);
        }
      );
    }
    else {
      this.getProductsByCategory(useCache, Department.clothes)
    }
  }

  getProductsByCategory(useCache = false, categoryName: string) {
    this.categoryService.getDepartmentProductsByName(useCache, categoryName).subscribe(
      (response: any) => {
        this.products = response.data;
        this.totalCount = response.count;
      },
      (error) => {
        this.logger.log(error, this.component);
      }
    );
  }

  getOfferProducts() {
    this.service.getSpecialOfferProducts().subscribe(response => {
      this.products = response;
      this.onSale = true;
    }, error => {
      console.log("Error: ", error);
    })
  }

  onPageChanged(event: any) {
    const params = this.productService.getProductsParams();
    if (params.pageNumber !== event) {
      params.pageNumber = event;
      this.productService.setProductsParams(params);
      this.getProducts(true);
    }
  }

  loadMore2() {
    const params = this.productService.getProductsParams();
    params.pageNumber = 1;

    if(this.bmlCol8) {
      params.pageSize += 8;
    }
    else {
      params.pageSize += 7;
    }
    
    this.productService.setProductsParams(params);
    this.getProducts(true);
  }

  loadMore() {
    const params = this.productService.getProductsParams();
    params.pageNumber = 1;
    params.pageSize += this.addOrRemoveCount;
    this.productService.setProductsParams(params);
    this.getProducts(false);
  }

  showLess() {
    if (this.products.length > this.initialCount) {
      const params = this.productService.getProductsParams();
      params.pageNumber = 1;
      params.pageSize -= this.addOrRemoveCount;
      this.productService.setProductsParams(params);
      this.getProducts(false);
    }
  }

  reload() {
    setTimeout(() => {
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }, 100);
  }
}


