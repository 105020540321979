import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SharedModule } from '../shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { NotFoundComponent } from './not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { MainNavBarComponent } from './main-nav-bar/main-nav-bar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MainNavItemComponent } from './main-nav-item/main-nav-item.component';
import { SideNavItemComponent } from './side-nav-item/side-nav-item.component';
import { MainSubNavItemComponent } from './main-sub-nav-item/main-sub-nav-item.component';
import { FooterMobileToolbarComponent } from './footer-mobile-toolbar/footer-mobile-toolbar.component';

@NgModule({
  declarations: [
    NavBarComponent, 
    NotFoundComponent, 
    FooterComponent,
    MainNavBarComponent,
    MainNavItemComponent,
    SideNavItemComponent,
    MainSubNavItemComponent,
    FooterMobileToolbarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule
  ],
  exports: [
    MainNavBarComponent,
    NavBarComponent,
    FooterComponent,
    NotFoundComponent,
    RouterModule
  ]
})
export class CoreModule { }
