import { IProductProductStyle } from './product-style';

export enum Department {
  clothes = 'Clothes',
  shoes = 'Shoes',
  accessories = 'Accessories',
}

export interface IProductGenre {
  name: string;
  selected: boolean;
}

export interface IProductImage {
  id: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  productId: string;
  product: string;
  thumbNailPhoto: string;
  thumbnailPhotoFileName: string;
  largePhoto: string;
  largePhotoFileName: string;
  updateImageFlag: boolean;
  isMain: boolean;
}

export interface IProduct {
  id: string;
  sellerAccountId: string,
  name: string;
  productNumber: string;
  shortDescription: string;
  longDescription: string;
  productDetail: string;
  color: string;
  size: string;
  sizeUnitMeasure: string;
  listPrice: number;
  price: number;
  productDiscountCode: string;
  productDiscount: string;
  productDiscountPercent: number;
  quantity: number;
  safetyStockLevel: number;
  rating: number;
  pictureUrl: string;
  productCategoryId: string;
  productCategory: string;
  productSubcategoryId: string;
  productSubcategory: string;
  productBrandId: string;
  productBrand: string;
  productStyleId: string;
  productStyle: string;
  productLine: string;
  genre: string;
  weight: string;
  weightUnitMeasure: string;
  class: string;
  style: string;
  finishedGoodsFlag: boolean;
  isOnSpecialOfferSell: boolean;
  specialOfferId: string;
  specialOfferDescription: string;
  specialOfferPercentage: number;
  sellStartDate: Date;
  sellEndDate: Date;
  discontinuedDate: Date;
  updateImageFlag: boolean;
  slug: string;
  productImages: IProductImage[];
  productSizes: IProductSize[];
  productColors: IProductColor[];
  productStyles: IProductProductStyle[];
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IProductUnitMeasure {
  id: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  unitMeasureCode: string;
  name: string;
}

export interface IProductDiscount {
  id: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  discountCode: string;
  discountPercent: number;
  isDiscountActive: boolean;
}

export interface IProductReview {
  id: string;
  productId: string;
  productName: IProduct;
  customerId: string;
  customer: string;
  reviewDate: Date;
  customerEmail: string;
  rating: number;
  reviewTitle: string;
  helpful: number;
  comment: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  hasCustomerRated: boolean;
}

export interface IProductSize {
  id: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  activeStatusFlag: boolean;
  style: string;
  number: string;
  size: string;
  code: string;
  quantity: number;
  productId: string;
  product: string;
}

export interface IProductColor {
  id: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  activeStatusFlag: boolean;
  name: string;
  code: string;
  quantity: number;
  colorPictureUrl: string;
  mainProductPictureUrl: string;
  thumbnailPictureUrl: string;
  isMain: boolean;
  productId: string;
  product: string;
  slug: string;
  productColorImages: IProductColorImage[];
  productColorSizes: IProductColorSize[];
}

export interface IProductColorImage {
  id: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  activeStatusFlag: boolean;
  productId: string;
  product: string;
  productColorId: string;
  productColor: string;
  fileName: string;
  fileTag: string;
  fileUrl: string;
  isMain: boolean;
}

export interface IProductColorSize {
  id: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  activeStatusFlag: boolean;
  style: string;
  number: string;
  size: string;
  code: string;
  quantity: number;
  productColorId: string;
  productColor: string;
}

export interface IProductClickstream {
  sessionId: string;
  productId: string;
}

export class ProductClickstream implements IProductClickstream {
  sessionId: string = '';
  productId: string = '';
}

export interface IProductPagination {
  pageNumber: number;
  pageSize: number;
  count: number;
  data: IProduct[];
}

export class ProductPagination implements IProductPagination {
  pageNumber: number = 1;
  pageSize: number = 24;
  count: number = 0;
  data: IProduct[] = [];
}

export class ProductParams {
  productCategoryId: string = "";
  productSubcategoryId: string = "";
  productBrandId: string = "";
  productId: string = "";
  productGenre: string = "";
  productStyleId: string = ""
  sort = 'name';
  pageNumber = 1;
  pageSize = 24;
  search: string = "";
}
