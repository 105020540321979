<ng-container *ngIf="navbarService.visible">
  <nav
    class="navbar navbar-expand-md navbar-dark d-flex align-items-center fixed-top"
  >
    <img
      class="bml-logo"
      src="/assets/images/bml-shopping-2.png"
      alt="BML Shopping Center"
      routerLink="/"
      (click)="reloadPage()"
    />
    <button
      class="navbar-toggler collapsed"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span
        class="navbar-toggler-icon"
        (click)="toggleMenu()"
        [class.menu-overlay]="isMenuOpen"
      ></span>
    </button>

    <div class="navbar-collapse collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" (click)="goToBmlServices()">
            <span class="top-link">High Quality</span><br />
            <strong>Software Services</strong>
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" (click)="reloadPage()">
          <a class="nav-link" routerLink="/shop">
            <span class="top-link">Our Brands</span><br />
            <strong>BML Shopping</strong> <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/orders">
            <span class="top-link">History</span><br />
            <strong>Orders History</strong>
          </a>
        </li>
        <!-- <ng-container *ngIf="(currentUser$ | async) === null">
          <div class="dropdown" dropdown>
            <a
              class="dropdown-toggle nav-link"
              id="button-animated"
              dropdownToggle
              aria-controls="dropdown-animated"
            >
              <span class="top-link">Sign in</span><br />
              <strong>Account</strong> <span class="caret"></span>
            </a>
            <div
              id="dropdown-animated"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="button-animated"
            >
              <a class="dropdown-item py-2" routerLink="/account/login">Login</a>
              <a class="dropdown-item py-2" routerLink="/account/register">Register</a>
              <a class="dropdown-item py-2" href="#">Register as Supplier</a>
            </div>
          </div>
        </ng-container> -->
        <!-- <ng-container *ngIf="currentUser$ | async as user">
          <div class="dropdown" dropdown>
            <a
              class="dropdown-toggle nav-link"
              id="button-animated"
              dropdownToggle
              aria-controls="dropdown-animated"
            >
              <span class="top-link">Hello</span><br />
              <strong>Welcome {{ user.firstName }}</strong>
              <span class="caret"></span>
            </a>
            <div
              id="dropdown-animated"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="button-animated"
            >
              <a class="dropdown-item py-2"><i class="fa fa-user mr-3"></i> Acc. #: {{ user.accountNumber }}</a>
              <a class="dropdown-item py-2" routerLink="/account/profile" ><i class="fa fa-user mr-3"></i> Your Profile</a>
              <a class="dropdown-item py-2" routerLink="/shopping-cart"><i class="fa fa-shopping-cart mr-3"></i> View your Cart</a>
              <a class="dropdown-item py-2" routerLink="/orders"><i class="fa fa-history mr-3"></i> View your Orders</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item py-2" (click)="logout()"><i class="fa fa-sign-out mr-3"></i> Logout</a>
            </div>
          </div>
        </ng-container> -->
        <!-- <ng-container>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/shopping-cart">
              <i
                matBadge="{{ cartTotalItems }}"
                matBadgeSize="large"
                matBadgeColor="accent"
                class="fa fa-shopping-cart fa-2x"
              ></i>
              <strong>Cart</strong>
            </a>
          </li>
        </ng-container> -->
      </ul>
    </div>
  </nav>

  <!-- <div class="lumus bg-white shadow-lg py-1">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <ul class="nav">
          
          <ng-container>
            <li class="nav-item">
              <a class="nav-link" routerLink="/shop">Shop</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/shop/products/departments">Departments</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/shop/products">Products</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/shop/products/brands">Brands</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/shop/products/collections">Collections</a>
            </li>
          </ng-container>

          <div class="nav ml-lg-auto ml-sm-0 contact-side">
            <a class="nav-link" routerLink="/contact">Sales & Deals</a>
          </div>
        </ul>
      </div>
    </div>
  </div> -->
</ng-container>
