import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { NavBarService } from './nav-bar.service';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { IUserToReturn } from '../../shared/models/user';
import { Roles } from 'src/app/shared/models/role';
import { Router } from '@angular/router';
import { ShoppingCartService } from 'src/app/shopping-cart/shopping-cart.service';
import { IBasket } from 'src/app/shared/models/shopping-cart';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CategoryService } from 'src/app/shop/category/category.service';
import { IProductCategory } from 'src/app/shared/models/product-category';

@Component({
  selector: 'bml-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
})
export class NavBarComponent implements OnInit {
  cart$!: Observable<IBasket>;
  cartTotalItems = 0;
  currentUser$!: Observable<IUserToReturn>;
  userRole = Roles;
  showShopLink = false;
  isServiceCategory = false;

  productCategories: IProductCategory[] = [];

  isMenuOpen: boolean = false;

  constructor(
    public navbarService: NavBarService,
    private accountService: AccountService,
    private cartService: ShoppingCartService,
    private categoryService: CategoryService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.cart$ = this.cartService.basket$;
    this.getCartItems();
    this.currentUser$ = this.accountService.currentUser$;
    this.getProductCategories();
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;

    console.log(this.isMenuOpen);
  }
  closeMenu() {
    this.isMenuOpen = false;
  }

  reloadPage() {
    setTimeout(() => {
      location.reload();
    }, 100);
  }

  getRole(user: IUserToReturn, role: string) {
    return user && user?.roles?.find((x) => x.name === role);
  }

  getCartItems() {
    this.cart$
      .pipe(
        map((res: IBasket) => {
          return res;
        })
      )
      .subscribe((res) => {
        if (res) {
          this.cartTotalItems = res.basketItems.reduce(
            (a, b) => b.quantity + a,
            0
          );
        }
      });
  }

  getProductCategories() {
    this.categoryService.getCategories(false).subscribe(response => {
      this.productCategories = response;
    }, error => {
      console.log(error);
    })
  }

  logout() {
    this.accountService.logout();
    //this.reloadPage();
  }

  goToBmlECommerce() {
    window.open(environment.clientUrl, "_blank");
  }

  goToBmlServices() {
    window.open(environment.serviceUrl, "_blank");
  }
}
