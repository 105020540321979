import {Component, OnInit} from '@angular/core';
import {LoggingErrorService} from 'src/app/logging-error/logging-error.service';
import {IProductBrand} from 'src/app/shared/models/product-brand';
import {BrandService} from '../../brand/brand.service';

@Component({
  selector: 'bml-brand-preview',
  templateUrl: './brand-preview.component.html',
  styleUrls: ['./brand-preview.component.scss'],
})
export class BrandPreviewComponent implements OnInit {
  brands: IProductBrand[] = [];
  itemsPerSlide = 3;
  singleSlideOffset = true;

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    arrows: true,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  constructor(
    private brandService: BrandService,
    private logger: LoggingErrorService
  ) {

  }

  ngOnInit(): void {
    this.getBrands(true);
    //this.signalRConnection();
  }

  // signalRConnection() {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .configureLogging(signalR.LogLevel.Information)
  //     .withUrl(environment.apiUrl + 'notify')
  //     .build();
  //
  //   connection.start().then(function() {
  //     console.log('SignalR Connected')
  //   }).catch( err => {
  //     return console.error(err.toString())
  //   })
  //
  //   connection.on("BroadcastMessage", () => {
  //     this.getBrands(true);
  //   })
  // }

  getBrands(useCache = false) {
    this.brandService.getBrands(useCache).subscribe(
      (response) => {
        this.brands = response.data;
      },
      (error) => {
        this.logger.log(error, 'brand-preview');
      }
    );
  }
}
