import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IProduct, IProductPagination, ProductPagination, ProductParams } from 'src/app/shared/models/product';
import { IProductSubcategory, SubcategoryPagination, ISubcategoryPagination } from 'src/app/shared/models/product-subcategory';
import { environment } from 'src/environments/environment';
import { ISubcategoryProducts } from '../../shared/models/product-subcategory';
import { IProductBrand } from '../../shared/models/product-brand';
import { IProductGenre } from '../../shared/models/product';
import { IProductProductStyle } from 'src/app/shared/models/product-style';

@Injectable({
  providedIn: 'root'
})
export class SubcategoryService {
  baseUrl = environment.apiUrl;
  collections: IProductSubcategory[] = [];
  collectionParams = new ProductParams();
  collectionPagination = new SubcategoryPagination();

  collectionSubcategoryProductParams = new ProductParams();
  collectionSubcategoryProducts: ISubcategoryProducts[] = [];
  

  collectionProducts: IProduct[] = [];
  collectionProductParams = new ProductParams();
  collectionProductPagination = new ProductPagination();

  products: IProduct[] = [];
  productPagination = new ProductPagination();
  productParams = new ProductParams();

  constructor(private http: HttpClient) { }

  getCollections(useCache: boolean) {
    if (!useCache) {
      this.collections = [];
    }

    if (this.collections.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.collections.length / this.collectionParams.pageSize);

      if (this.collectionParams.pageNumber <= pagesReceived) {
        this.collectionPagination.data = this.collections
          .slice(
            (this.collectionParams.pageNumber - 1) * this.collectionParams.pageSize,
              this.collectionParams.pageNumber * this.collectionParams.pageSize
          );

        return of(this.collectionPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.collectionParams)

    return this.http.get<ISubcategoryPagination>(this.baseUrl + 'productSubcategories', {observe: 'response', params})
      .pipe(
        map(response =>{
            this.collections = response.body ? [...this.collections, ...response.body.data]: this.collections;
            this.collectionPagination = response.body ? response.body : new SubcategoryPagination();
            return this.collectionPagination;
        })
      );
  }

  setCollectionParams(params: ProductParams) {
    this.collectionParams = params;
  }

  getCollectionParams() {
    return this.collectionParams;
  }

  getCollection(id: string) {
    const collection = this.collections.find(s => s.id === id);
    if (collection) {
      return of(collection);
    }

    return this.http.get<IProductSubcategory>(this.baseUrl + 'productSubcategories/' + id);
  }

  setCollectionWithProductsParams(params: ProductParams) {
    this.collectionSubcategoryProductParams = params;
  }

  getCollectionWithProductsParams() {
    return this.collectionSubcategoryProductParams;
  }

  getCollectionsWithProducts(useCache: boolean) {
    if (!useCache) {
      this.collectionProducts = [];
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.collectionSubcategoryProductParams)

    return this.http.get<ISubcategoryProducts[]>(this.baseUrl + 'productSubcategories/preview', {observe: 'response', params})
      .pipe(
        map(response =>{
            this.collectionSubcategoryProducts = response.body ? [...this.collectionSubcategoryProducts, ...response.body]: this.collectionSubcategoryProducts;
            return this.collectionSubcategoryProducts;
        })
      );
  }

  getCollectionBrands(collectionId: string) {
    return this.http.get<IProductBrand[]>(this.baseUrl + 'productSubcategories/subcategoryBrands/' + collectionId)
      .pipe(
        map(response => {
          return response;
        })
      )
  }

  getCollectionGenres(collectionId: string) {
    return this.http.get<IProductGenre[]>(this.baseUrl + 'productSubcategories/subcategoryGenres/' + collectionId)
      .pipe(
        map(response => {
          return response;
        })
      )
  }

  getCollectionStyles(collectionId: string) {
    return this.http.get<IProductProductStyle[]>(this.baseUrl + "productSubcategories/subcategoryStyles/" + collectionId)
      .pipe(
        map(response => {
          return response;
        })
      )
  }

  setCollectionProductsParams(params: ProductParams) {
    this.collectionProductParams = params;
  }

  getCollectionProductsParams() {
    return this.collectionProductParams;
  }

  getCollectionProducts(collectionId: string, useCache: boolean) {
    if (!useCache) {
      this.collectionProducts = [];
    }

    if (this.collectionProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.collectionProducts.length / this.collectionProductParams.pageSize);

      if (this.collectionProductParams.pageNumber <= pagesReceived) {
        this.collectionProductPagination.data = this.collectionProducts
          .slice(
            (this.collectionProductParams.pageNumber - 1) * this.collectionProductParams.pageSize,
            this.collectionProductParams.pageNumber * this.collectionProductParams.pageSize
          );

        return of(this.collectionProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.collectionProductParams)

    return this.http.get<IProductPagination>(this.baseUrl + 'productSubcategories/products/' + collectionId, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.collectionProducts = response.body ? [...this.collectionProducts, ...response.body.data] : this.collectionProducts;
          this.collectionProductPagination = response.body ? response.body : new ProductPagination();
          return this.collectionProductPagination;
        })
      );
  }

  private setParamsMethod(params: HttpParams, productParams: ProductParams) {

    if (productParams.productCategoryId) {
      params = params.append('productCategoryId', productParams.productCategoryId.toString());
    }
    if (productParams.productSubcategoryId) {
      params = params.append('productSubcategoryId', productParams.productSubcategoryId.toString());
    }
    if (productParams.productBrandId) {
      params = params.append('productBrandId', productParams.productBrandId.toString());
    }
    if (productParams.productGenre) {
      params = params.append('productGenre', productParams.productGenre.toString());
    }
    if (productParams.productStyleId) {
      params = params.append('productStyleId', productParams.productStyleId.toString());
    }
    if (productParams.productId) {
      params = params.append('productId', productParams.productId.toString());
    }

    if (productParams.search) {
      params = params.append('search', productParams.search);
    }

    params = params.append('sort', productParams.sort);
    params = params.append('pageIndex', productParams.pageNumber.toString());
    params = params.append('pageSize', productParams.pageSize.toString());

    return params;
  }
}
