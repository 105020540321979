import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {LoggingErrorService} from 'src/app/logging-error/logging-error.service';
import {IProduct} from 'src/app/shared/models/product';
import {Department} from '../../../shared/models/product';
import {ShopService} from '../../shop.service';

@Component({
  selector: 'bml-deal-of-day',
  templateUrl: './deal-of-day.component.html',
  styleUrls: ['./deal-of-day.component.scss', '../../../app.custom-row.scss'],
})
export class DealOfDayComponent implements OnInit {
  noClothProducts: IProduct[] = [];
  clothProducts: IProduct[] = [];
  totalCount: number = 0;
  itemsPerSlide = 6;
  singleSlideOffset = true;
  isLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width: 1700px)']) // Fits Handset and Tablet
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private productService: ShopService,
    private router: Router,
    private logger: LoggingErrorService
  ) {
  }

  ngOnInit(): void {
    this.getDealsProducts(false);
  }

  getDealsProducts(useCache: boolean = false) {
    this.productService.getDiscountProducts(useCache).subscribe(
      (response: any) => {
        this.clothProducts = response.data.filter((x: {
          productCategory: Department;
        }) => x.productCategory === Department.clothes);
        this.noClothProducts = response.data.filter((x: {
          productCategory: Department;
        }) => x.productCategory !== Department.clothes);
        this.totalCount = response.count;
      },
      error => {
        this.logger.log(error, "deal-of-day");
      }
    );
  }

  createProductClickstream(productId: string) {
    this.productService.createProductClickstream(productId).subscribe(() => {
    });
  }

  reload() {
    setTimeout(() => {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    }, 100);
  }
}
