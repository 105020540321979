<div class="zippy">
    <div class="zippy-heading"
        [class.expanded]="isExpanded"
        (click)="toggle()"
    >
        <h1 class="title">
            {{ title }}

            <span class="fa"
            [ngClass]="{
                'fa-angle-up': isExpanded,
                'fa-angle-down': !isExpanded
            }"
            >
            </span>
        </h1> 
        
    </div>
    <div *ngIf="isExpanded" class="zippy-body">
        <ng-content></ng-content>
    </div>
</div>
