import { IProduct } from './product';

export interface IProductSubcategory {
    id: string;
    userId: string;
    name: string;
    shortDescription: string;
    longDescription: string;
    pictureUrl: string;
    category: string;
    categoryId: string;
    slug: string;
    selected: boolean;
}

export interface ISubcategoryProducts {
    subcategory: IProductSubcategory;
    productCount: number;
    products: IProduct[];
}

export interface ISubcategoryPagination {
    pageIndex: number;
    pageSize: number;
    count: number;
    data: IProductSubcategory[];
}

export class SubcategoryPagination implements ISubcategoryPagination {
    pageIndex: number = 1;
    pageSize: number = 12;
    count: number = 0;
    data!: IProductSubcategory[];
}

export class SubcategoryParams {
    productCategoryId: string = "";
    sort = 'name';
    pageNumber = 1;
    pageSize = 12;
    search: string = "";
}