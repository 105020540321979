<div class="arrivals" *ngIf="isNewArrived">
    <h3 class="title">New Arrivals</h3>
    <ng-container *ngIf="noClothesProducts && noClothesProducts.length > 0">
      <bml-product-slider 
        [products]="noClothesProducts" 
        [sliderBackgroundColor]="sliderBackground"
        [sliderTextColor]="textColor"
        [productBackgroundColor]="productBackground">
      </bml-product-slider>
    </ng-container>
    <div class="mt-3" *ngIf="clothesProducts && clothesProducts.length > 0">
        <bml-product-slider 
        [products]="clothesProducts"
        [sliderBackgroundColor]="'#322323'"
        [sliderTextColor]="textColor">
      </bml-product-slider>
    </div>
</div>