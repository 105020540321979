<div *ngIf="specialOffers" class="bml-carousel mb-5">
  <carousel [noPause]="false">
    <slide *ngFor="let offer of specialOffers" class="bml-slide">
      <div class="carousel-caption d-md-block">
        <h1 class="description">{{ offer.description }} Offer Deals</h1>
        <div class="percentage">{{ offer.offerDiscountPercentage }}% OFF</div>
        <div class="count-down">
          <h3 class="sales-end">Sales End in </h3>
          <bml-timer-count-down endDate="{{ offer.endDate | date:'medium' }}"></bml-timer-count-down>
        </div>
        <button *ngIf="isHomePage" class="btn btn-danger offer-btn"
                (click)="goToSalesAndDeals()">
          Explore <span class="offer-description">{{ offer.description }}</span> Deals
        </button>
      </div>
    </slide>
  </carousel>
</div>
