<table class="table">
  <thead>
    <tr *ngIf="!(isSavedForLater || isChechout)">
      <th scope="col" class="delete-header">
        <span class="btn btn-danger btn-sm delete-btn mr-3" (click)="deleteSelectedItems()">Delete all selected items</span>
      </th>
      <th scope="" class="product-header"> 
        <span class="product-title">Products</span>
        <h1 class="shopping-title">Shopping Cart</h1>
      </th>
      <th scope="col" class="price-container-header">Price</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let product of basket.basketItems; index as i">
      <td >
        <div class="form-check" *ngIf="!(isSavedForLater || isChechout)">
          <input type="checkbox" 
            class="form-check-input " 
            [checked]="isProductSelected"
            (change)="onCheckboxChange($event, product)">
        </div>
        <div class="text-center pl-2">
          <img
            src="{{ product.pictureUrl }}"
            alt="{{ product.productName }}"
            class="product-image"
          />
        </div>       
      </td>
      <td>
        <span class="name" routerLink="/shop/products/{{ product.productId }}/{{product.productName.replace(' ', '-') | lowercase}}">
          <span>{{ product.productName }}</span> 
          <span *ngIf="product.color">, {{ product.color }}</span> 
          <span *ngIf="product.size">, {{ product.size }}</span>
        </span>
        <div class="stock-info" *ngIf="!isChechout">
          <ng-container *ngIf="product.stockQuantity">
            <span class="out-of-stock" *ngIf="product.stockQuantity <= 0">Out of stock</span>            
            <span class="in-stock" *ngIf="product.stockQuantity > 15">In Stock</span>     
            <span class="few-in-stock" *ngIf="product.stockQuantity <= 15 && product.stockQuantity > 0">
              Only {{ product.stockQuantity }} left in stock - Order soon
            </span>                   
          </ng-container>
        </div> 
        <span class="price-container-mobile">
          <h3 class="product-price" >
            <span class="list-price" *ngIf="product.productDiscount">{{ product.listPrice | currency }}</span>
            <span class="actual-price"
              [ngStyle]="product.productDiscount ? {color: 'maroon'} : {color: 'black'}"
            >{{ product.price | currency }}</span> <br>
            <span class="discount" *ngIf="product.productDiscount">You saved {{product.productDiscount}}% </span>
          </h3>
        </span>       
        <p class="collection-brand">
          <span *ngIf="product.subcategory" class="collection">
            {{ product.subcategory }} Collection
          </span>
          <span class="mx-3" style="font-size: 25px">&#8226;</span>
          <span *ngIf="product.brand">{{ product.brand }} Brand</span>
        </p>
        <p>
          <ng-container *ngIf="product.size">
            <span class="size">Size:</span>  {{product.size}} <br/> 
          </ng-container>          
          <span class="color">Color:</span>  {{product.color}} <br>
          <span *ngIf="isSavedForLater"><span class="size">Quantity: </span> {{product.quantity}}</span>
        </p>

        <div
          class="align-middle quantity-container"
          *ngIf="!(isChechout || isSavedForLater)"
        >
          <span
            class="fa fa-minus-circle fa-2x"
            (click)="decrementCartItem(product)"
          ></span>
          <span class="mx-3 quantity">{{ product.quantity }}</span>
          <span
            class="fa fa-plus-circle fa-2x pr-3"
            (click)="incrementCartItem(product)"
          ></span>
          <span class="px-3 delete" (click)="removeCartItem(product)">
            Delete <span class="no-mobile">product</span>
          </span>
          <!-- <span class="separator"></span> -->
          <span
            *ngIf="currentUser$ | async as user"
            class="ps-3 save-for-later"
            (click)="saveProductForLater(product)"
          >
            Save for later
          </span>
        </div>
        <ng-container *ngIf="product.isLimit">
          <hr>
          <p class="limit-number">
            <i class="fa fa-exclamation-circle fa-2x"></i> Sorry! You have reach the limit number for this item
          </p>
        </ng-container>
        <div class="align-middle quantity-container" *ngIf="isChechout">
          <p>
            <span>
              Quantity: {{ product.quantity }}
            </span>
            
            <span
              class="btn btn-warning btn-sm mx-3"
              routerLink="/shopping-cart"
            >
              Change
            </span>
          </p>
        </div>
        <div class="align-middle quantity-container" *ngIf="isSavedForLater">
          <span
            class="pe-3 delete-saved-item"
            (click)="deleteSavedItem(product)"
          >
            Delete
          </span>
          <!-- <span class="separator"></span> -->
          <span
            class="ps-3 save-for-later"
            (click)="moveProductToCart(product)"
          >
            Move to cart
          </span>
        </div>
      </td>
      <td class="price-container">
        <h3 class="product-price" >
          <span class="list-price" *ngIf="product.productDiscount">{{ product.listPrice | currency }}</span>
          <span class="actual-price"
            [ngStyle]="product.productDiscount ? {color: 'maroon'} : {color: 'black'}"
          >{{ product.price | currency }}</span> <br>
          <span class="discount" *ngIf="product.productDiscount">You saved {{product.productDiscount}}% </span>
        </h3>
      </td>
    </tr>
  </tbody>
</table>
