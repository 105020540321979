import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';
import { IPolicy } from 'src/app/shared/models/policy';
import { PoliciesService } from '../policies.service';

@Component({
  selector: 'bml-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  policies: IPolicy[] = [];

  constructor(
    private service: PoliciesService,
    public bsModalRef: BsModalRef,
    private logger: LoggingErrorService
  ) {}

  ngOnInit(): void {
    this.getPolicies();
  }

  getPolicies() {
    this.service.getTermsAndConditions().subscribe(
      (response) => {
        this.policies = response;
      },
      (error) => {
        this.logger.log(error, 'terms-and-conditions');
      }
    );
  }
}
