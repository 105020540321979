import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserToReturn } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bml-footer-mobile-toolbar',
  templateUrl: './footer-mobile-toolbar.component.html',
  styleUrls: ['./footer-mobile-toolbar.component.scss']
})
export class FooterMobileToolbarComponent implements OnInit {
  @Input() cartTotalItems = 0;
  @Input() currentUser$!: Observable<IUserToReturn>;

  constructor(public platform: Platform) { }

  ngOnInit(): void {
  }

  goToBmlServices() {
    window.open(environment.serviceUrl, "_blank");
  }

}
