<div #stickyMenu [class.sticky]="sticky">
  <mat-toolbar [ngClass]="{'ios-mobile-sidenav': platform.IOS, 'no-ios-sidenav': !platform.IOS}"
               class="mat-toolbar  d-flex justify-content-between">
    <div>
      <ng-container *ngIf="sticky">
        <img
          [queryParams]="{
                        sessionId: sessionId,
                        'home': 'home-page' | lowercase
                    }"
          alt="BML Marketplace"
          class="bml-logo"
          queryParamsHandling="merge"
          routerLink="/"
          src="/assets/images/bml-marketplace-2.png"
        />
      </ng-container>

      <li class="nav-item">
        <button [cdkMenuTriggerFor]="menu" class="nav-link">
          <!-- <mat-icon>menu</mat-icon> Shop -->
          <i class="fa fa-bars"></i> Shop
        </button>
      </li>
      <li class="nav-item">
        <button [queryParams]="{
                        sessionId: sessionId,
                        'page': 'home' | lowercase
                    }"
                class="nav-link"
                routerLink="/shop"
                routerLinkActive="active"
        >Home
        </button>
      </li>
    </div>
    <span class="spacer"></span>
    <div>
      <a class="nav-item">
                <span class="d-flex">
                    <bml-search></bml-search>
                </span>
      </a>
    </div>
    <span class="spacer"></span>
    <div>
      <ng-container *ngIf="!sticky">
        <button [queryParams]="{
                        sessionId: sessionId,
                        'products': 'sales-and-deals' | lowercase
                    }"
                class="nav-item"
                queryParamsHandling="merge"
                routerLink="/shop/products/sales-and-deals"
                routerLinkActive="active"
        >
          <i class=""></i> Sales & Deals
        </button>
      </ng-container>
      <ng-container *ngIf="sticky">
        <a [queryParams]="{
                        sessionId: sessionId,
                        'products': 'sales-and-deals' | lowercase
                    }"
           class="register-btn"
           queryParamsHandling="merge"
           routerLink="/shop/products/sales-and-deals"
           routerLinkActive="active"
        >
          <i class=""></i> Sales & Deals
        </a>
        <a [queryParams]="{
                        sessionId: sessionId,
                        'basketId': (randomUUID +'-'+ cartId) | lowercase
                    }"
           class="nav-item basket"
           queryParamsHandling="merge"
           routerLink="/shopping-cart"
        >
          <i
            class="fa fa-shopping-cart fa-2x"
            matBadge="{{ cartTotalItems }}"
            matBadgeColor="accent"
            matBadgeSize="large"
          ></i>
          <strong>Cart</strong>
        </a>
      </ng-container>
    </div>
  </mat-toolbar>
</div>

<ng-template #menu>
  <div cdkMenu class="menu">
    <p [queryParams]="{
                sessionId: sessionId,
                'products': 'all' | lowercase
            }"
       cdkMenuItem
       class="menu-title"
       queryParamsHandling="merge"
       routerLink="/shop/products"
    >Shop Products</p>
    <button [queryParams]="{
                sessionId: sessionId,
                'products': 'all' | lowercase
            }"
            cdkMenuItem
            class="menu-item"
            queryParamsHandling="merge"
            routerLink="/shop/products"
    >All Products
    </button>
    <hr/>

    <p [queryParams]="{
                sessionId: sessionId,
                'departments': 'all' | lowercase
            }"
       cdkMenuItem
       class="menu-title"
       queryParamsHandling="merge"
       routerLink="/shop/products/departments"
    >
      Shop By Department
    </p>
    <button [cdkMenuTriggerFor]="productCategories" cdkMenuItem class="menu-item">
      All Departments <span>&#10148;</span>
    </button>
    <ng-container *ngFor="let category of categories">
      <button (click)="reload()"
              [cdkMenuTriggerFor]="categoryCollections"
              [queryParams]="{
                    sessionId: sessionId,
                    'department': (category.name | lowercase)
              }"
              cdkMenuItem
              class="menu-item"
              queryParamsHandling="merge"
              routerLink="/shop/products/departments/{{category.id}}/{{ category.name | lowercase }}">{{ category.name }}
        <span>&#10148;</span>
      </button>
      <ng-template #categoryCollections>
        <div cdkMenu class="menu large-menu">
          <p [queryParams]="{
                            sessionId: sessionId,
                            'department': (category.name | lowercase)
                        }"
             cdkMenuItem
             class="l-menu-item"
             queryParamsHandling="merge"
             routerLink="/shop/products/departments/{{category.id}}/{{ category.name | lowercase }}"
          >{{ category.name }}</p>
          <hr/>
          <ng-container *ngFor="let collection of category.productSubcategories" class="collections">
            <button (click)="reload()"
                    [queryParams]="{
                          sessionId: sessionId,
                          'collection': (collection.name | lowercase)
                      }"
                    cdkMenuItem
                    class="menu-item"
                    queryParamsHandling="merge"
                    routerLink="/shop/products/collections/{{collection.id}}/{{collection.name | lowercase}}">{{collection.name}}
            </button>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
    <hr/>

    <p [queryParams]="{
                sessionId: sessionId,
                'department': ('all-genres' | lowercase)
            }"
       cdkMenuItem
       class="menu-title"
       queryParamsHandling="merge"
       routerLink="/shop/products/departments/genres"
    >Shop By Genres</p>
    <ng-container *ngFor="let genre of genres">
      <button (click)="reload()"
              [queryParams]="{
                    sessionId: sessionId,
                    'department': (genre.name | lowercase)
                }"
              cdkMenuItem
              class="menu-item"
              queryParamsHandling="merge"
              routerLink="/shop/products/departments/genres/{{genre.name | lowercase}}">{{genre.name}}
      </button>
    </ng-container>
    <button (click)="reload()"
            [queryParams]="{
                sessionId: sessionId,
                'department': ('kids' | lowercase)
            }"
            cdkMenuItem
            class="menu-item"
            queryParamsHandling="merge"
            routerLink="/shop/products/departments/genres/kids">Kids
    </button>
    <hr/>

    <p [queryParams]="{
                sessionId: sessionId,
                'collections': ('all-collections' | lowercase)
            }"
       cdkMenuItem
       class="menu-title"
       queryParamsHandling="merge"
       routerLink="/shop/products/collections"
    >Shop By Collections</p>
    <button [queryParams]="{
                sessionId: sessionId,
                'collections': ('all-collections' | lowercase)
            }"
            cdkMenuItem
            class="menu-item"
            queryParamsHandling="merge"
            routerLink="/shop/products/collections"
    >
      All Collections
    </button>
    <hr/>

    <p [queryParams]="{
                sessionId: sessionId,
                'brands': ('all-brands' | lowercase)
            }"
       cdkMenuItem
       class="menu-title"
       queryParamsHandling="merge"
       routerLink="/shop/products/brands"
    >Shop By Brand</p>
    <button [cdkMenuTriggerFor]="productbrands" cdkMenuItem class="menu-item">
      All Brands <span>&#10148;</span>
    </button>

    <!-- <hr>
    <p class="menu-title" routerLink="/shop/products/collections" cdkMenuItem>Shop By Collection</p>
    <button [cdkMenuTriggerFor]="collections" class="menu-item" cdkMenuItem>
        All Collections <span>&#10148;</span>
    </button> -->
  </div>
</ng-template>

<ng-template #productCategories>
  <div cdkMenu class="menu large-menu">
    <div class="row">
      <div *ngFor="let category of categories" class="col-sm-3 mb-3 category">
        <p (click)="reload()"
           [queryParams]="{
                        sessionId: sessionId,
                        'department': (category.name | lowercase)
                    }"
           cdkMenuItem
           class="l-menu-item"
           queryParamsHandling="merge"
           routerLink="/shop/products/departments/{{category.id}}/{{ category.name | lowercase }}">{{ category.name }}
        </p>
        <hr/>
        <ng-container *ngFor="let collection of category.productSubcategories">
          <button (click)="reload()"
                  [queryParams]="{
                            sessionId: sessionId,
                            'collection': (collection.name | lowercase)
                        }"
                  cdkMenuItem
                  class="menu-item"
                  queryParamsHandling="merge"
                  routerLink="/shop/products/collections/{{collection.id}}/{{collection.name | lowercase}}">{{collection.name}}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #productbrands>
  <div cdkMenu class="menu large-menu">
    <p cdkMenuItem class="l-menu-item" routerLink="/shop/products/brands">Brands</p>
    <hr/>
    <ng-container *ngFor="let brand of brands">
      <button (click)="reload()"
              [queryParams]="{
                    sessionId: sessionId,
                    'brand': (brand.name | lowercase)
                }"
              cdkMenuItem
              class="menu-item"
              queryParamsHandling="merge"
              routerLink="/shop/products/brands/{{brand.id}}/{{brand.name | lowercase}}">{{brand.name}}</button>
    </ng-container>
  </div>
</ng-template>

<!-- <ng-template #collections>
    <div class="menu large-menu" cdkMenu>
        <p class="l-menu-item" cdkMenuItem>Collections</p>
        <hr/>
        <ng-container *ngFor="let collection of subcategories" class="collections">
            <button class="menu-item"
                routerLink="/shop/products/brands/{{collection.id}}/{{collection.name | lowercase}}"
                (click)="reload()"
                cdkMenuItem>{{collection.name}}</button>
        </ng-container>
    </div>
</ng-template> -->

<ng-container *ngTemplateOutlet="template"></ng-container>
