import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import * as moment from 'moment';
import {interval, Subscription} from 'rxjs';
import {IProduct} from 'src/app/shared/models/product';
import {ISpecialOffer} from 'src/app/shared/models/special-offer';
import {LoggingErrorService} from '../../logging-error/logging-error.service';
import {SalesOfferService} from './sales-offer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bml-sales-offer',
  templateUrl: './sales-offer.component.html',
  styleUrls: ['./sales-offer.component.scss']
})
export class SalesOfferComponent implements OnInit {
  @Input() isHomePage: boolean = false;

  specialOffers!: ISpecialOffer[];
  products: IProduct[] = []

  sessionId: string = "current-session";

  private subscription!: Subscription;

  constructor(private service: SalesOfferService, 
    private router: Router, 
    private logger: LoggingErrorService) {
    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "";
  }

  ngOnInit(): void {
    this.getSpecialOffers();    
  }

  getSpecialOffers() {
    this.service.getSpecialOffers().subscribe(response => {
      this.specialOffers = response;
      response.forEach(element => {
        this.subscription = interval(1000)
          .subscribe(x => {
            let now = moment();
            let startTime = moment(element.startDate);
            let endTime = moment(element.endDate)
            let timeDifference = startTime.diff(now, 'seconds');
            if (endTime > now && timeDifference <= 0) {
              //this.reload();
            }
          });
      });
    });
  }

  // signalRConnection() {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .configureLogging(signalR.LogLevel.Information)
  //     .withUrl(environment.apiUrl + 'notify')
  //     .build();
  //
  //   connection.start().then(function () {
  //   }).catch(err => {
  //     return console.error(err.toString())
  //   })
  //
  //   connection.on("BroadcastMessage", () => {
  //     this.getSpecialOffers();
  //   })
  // }

  goToSalesAndDeals() {
    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "current-session";
    this.router.navigate(
      ['/shop/products/sales-and-deals'],
      {
        queryParams: {
          sessionId: this.sessionId,
          'page': "sales-and-deals"
        },
        queryParamsHandling: ""
      }
    )
    this.reload()
  }

  reload() {
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }, 100);
  }
}
