<div class="sidenav-item" [ngClass]="{'ios-mobile-sidenav': platform.IOS, 'no-ios-sidenav': !platform.IOS}">
    <div class="nav-items">
        <div class="menu">
            <mat-divider></mat-divider>
            <p class="menu-title d-flex justify-content-between" (click)="toggleShop()">
                Shop Products
                <span class="fa "
                    [ngClass]="{
                        'fa-plus': !isShop,
                        'fa-minus': isShop
                    }"
                ></span>
            </p>
            <div class="nav-items-content" *ngIf="isShop">
                <button 
                    [queryParams]="{
                        sessionId: sessionId,
                        'products': 'all' | lowercase
                    }"
                    class="menu-item"
                    queryParamsHandling=""
                    routerLink="/shop/products"
                    (click)="closeSideNavigation()"
                >All Products
                </button>
            </div>

            <mat-divider></mat-divider>          
        
            <p (click)="toggleCategories()" class="menu-title d-flex justify-content-between">
              Shop By Department
              <span class="fa "
                [ngClass]="{
                    'fa-plus': !isCategories,
                    'fa-minus': isCategories
                }"
              ></span>
            </p>
            <div class="nav-items-content" *ngIf="isCategories">
                <button class="menu-item"
                    routerLink="/shop/products/departments"
                    [queryParams]="{
                        sessionId: sessionId,
                        'departments': 'all' | lowercase
                    }"
                    queryParamsHandling=""
                    (click)="closeSideNavigation()"
                >
                    All Departments
                </button>
                <ng-container *ngFor="let category of categories">
                    <button (click)="reload()"
                        [queryParams]="{
                            sessionId: sessionId,
                            'department': (category.name | lowercase)
                        }"
                        class="menu-item"
                        queryParamsHandling=""
                        routerLink="/shop/products/departments/{{category.id}}/{{ category.name | lowercase }}"
                        (click)="closeSideNavigation()"
                    >
                        {{ category.name }}
                    </button>
                </ng-container>
            </div>

            <mat-divider></mat-divider>

            <p (click)="toggleGenres()" class="menu-title d-flex justify-content-between">
                Shop By Genres
                <span class="fa "
                    [ngClass]="{
                        'fa-plus': !isGenres,
                        'fa-minus': isGenres
                    }"
                ></span>
            </p>
            <div class="nav-items-content" *ngIf="isGenres">
                <button (click)="reload()"
                    [queryParams]="{
                        sessionId: sessionId,
                        'department': ('all-genres' | lowercase)
                    }"
                    class="menu-item"
                    queryParamsHandling=""
                    routerLink="/shop/products/departments/genres"
                    (click)="closeSideNavigation()">
                    All Genre
                </button>
                <ng-container *ngFor="let genre of genres">
                    <button (click)="reload()"
                        [queryParams]="{
                                sessionId: sessionId,
                                'department': (genre.name | lowercase)
                            }"
                        class="menu-item"
                        queryParamsHandling=""
                        routerLink="/shop/products/departments/genres/{{genre.name | lowercase}}"
                        (click)="closeSideNavigation()"
                    >
                        {{genre.name}}
                    </button>
                </ng-container>
                <button (click)="reload()"
                    [queryParams]="{
                        sessionId: sessionId,
                        'department': ('kids' | lowercase)
                    }"
                    class="menu-item"
                    queryParamsHandling=""
                    routerLink="/shop/products/departments/genres/kids"
                    (click)="closeSideNavigation()"
                >
                    Kids
                </button>
            </div>
            
            <mat-divider></mat-divider>

            <p (click)="toggleSubcategories()" class="menu-title d-flex justify-content-between">
                Shop By Collections
                <span class="fa "
                    [ngClass]="{
                        'fa-plus': !isSubcategories,
                        'fa-minus': isSubcategories
                    }"
                ></span>
            </p>
            <div class="nav-items-content" *ngIf="isSubcategories">
                <button 
                    [queryParams]="{
                        sessionId: sessionId,
                        'collections': ('all-collections' | lowercase)
                    }"
                    class="menu-item"
                    queryParamsHandling="merge"
                    routerLink="/shop/products/collections"
                    (click)="closeSideNavigation()"
                >
                    All Collections
                </button>
            </div>

            <mat-divider></mat-divider>
        
            <p (click)="toggleBrands()" class="menu-title d-flex justify-content-between">
                Shop By Brand
                <span class="fa "
                    [ngClass]="{
                        'fa-plus': !isBrands,
                        'fa-minus': isBrands
                    }"
                ></span>
            </p>
            <div class="nav-items-content" *ngIf="isBrands">
                <button [queryParams]="{
                    sessionId: sessionId,
                    'brands': ('all-brands' | lowercase)
                            }"
                    class="menu-item"
                    queryParamsHandling=""
                    routerLink="/shop/products/brands"
                    (click)="closeSideNavigation()"
                >
                    All Brands
                </button>
            </div>
            <mat-divider></mat-divider>
        </div>

        <div class="user-account"
            [ngClass]="{
                'stick-at-bottom': !isSideNavOpen
            }"
        >
            <ng-container *ngIf="currentUser$ | async as user">
                <p class="menu-title account">Account</p>
                <div class="nav-items-content">
                    <button class="menu-item">No. {{ user.accountNumber }}</button>
                    <button class="menu-item" routerLink="/account/profile" (click)="closeSideNavigation()">
                        <i class="fa fa-user mr-3"></i> Profile
                    </button>
                    <button class="menu-item" routerLink="shopping-cart" (click)="closeSideNavigation()">                
                        <i class="fa fa-shopping-basket mr-3"></i> Cart
                    </button>
                    <button class="menu-item" routerLink="/orders" (click)="closeSideNavigation()">
                        <i class="fa fa-history mr-3"></i> Orders
                    </button>
                    <div class="logout">
                        <mat-divider></mat-divider>
                        <button class="menu-item" (click)="logout()" >
                            <i class="fa fa-power-off mr-3"></i> Logout
                        </button>
                    </div>
                </div>            
            </ng-container>

            <ng-container *ngIf="(currentUser$ | async) === null"> 
                <p class="menu-title account">Account</p>
                <div class="nav-items-content">
                    <button class="menu-item" routerLink="/account/login">
                        <i class="fa fa-user"></i> Login
                    </button>
                    <button class="menu-item" routerLink="/account/register" (click)="closeSideNavigation()">
                        <i class="fa fa-user-o"></i> Register
                    </button>
                </div>                   
            </ng-container>
        </div>
    </div>    
</div>