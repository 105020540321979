import { v4 as uuidv4 } from 'uuid';

export interface IBasket {
  id: string;
  customerId?: string;
  customerName?: string;
  basketItems: IBasketItem[];
  deliveryMethodId?: string;
  clientSecret?: string;
  paymentIntentId?: string;
  shippingPrice?: number;
  tax?: number;
  isPromotion?: boolean;
  promotionCode?: string;
  promotionPercent?: number;
}

export interface IBasketItem {
  id?: string;
  sellerId?: string;
  customerId?: string;
  basketId?: string;
  productId: string;
  productName: string;
  shortDescription: string;
  price: number;
  listPrice: number;
  productDiscount: number;
  quantity: number;
  stockQuantity?: number;
  isLimit?: boolean;
  pictureUrl: string;
  size: string;
  color: string;
  category: string;
  subcategory: string;
  genre: string;
  brand: string;
}

export class Basket implements IBasket {
  id: string = uuidv4();
  basketItems: IBasketItem[] = [];
}

export class BasketInitialization implements IBasket {
  id: string = '';
  basketItems: IBasketItem[] = [];
}

export interface IBasketTotals {
  listPriceSubtotal: number;
  subtotal: number;
  amountSaved: number;
  promotionAmount: number;
  subtotalAfterPromotion: number;
  tax: number;
  subtotalAfterTax: number;
  shipping: number;
  originalTotal: number
  total: number;
}

export interface IBasketItemSavedForLater {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  userId?: string;
  productId: string;
  productName: string;
  shortDescription: string;
  price: number;
  listPrice: number;
  productDiscount: number;
  quantity: number;
  stockQuantity?: number;
  pictureUrl: string;
  size: string;
  color: string;
  category: string;
  subcategory: string;
  genre: string;
  brand: string;
  basketSavedForLaterId?: string;
}

export interface IBasketSavedForLater {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  customerName: string;
  basketItems: IBasketItemSavedForLater[];
}

export class BasketTotals implements IBasketTotals {
  listPriceSubtotal = 0;
  subtotal: number = 0;
  amountSaved: number = 0;
  promotionAmount: number = 0;
  subtotalAfterPromotion: number = 0;
  tax: number = 0;
  subtotalAfterTax: number = 0;
  shipping: number = 0;
  originalTotal: number = 0;
  total: number = 0;
}
