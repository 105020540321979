<ng-container *ngIf="allProducts && allProducts.length > 0">
    <h3 class="title">Products Recently Viewed</h3>
    <ng-container *ngIf="noClothesProducts && noClothesProducts.length > 0">
        <bml-product-slider 
            [products]="noClothesProducts"
            [sliderBackgroundColor]="sliderBackground1"
            [sliderTextColor]="textColor" 
        ></bml-product-slider>
    </ng-container>
    <div class="mt-3" *ngIf="clothesProducts && clothesProducts.length > 0">
        <bml-product-slider 
            [products]="clothesProducts"
            [sliderBackgroundColor]="sliderBackground2"
            [sliderTextColor]="textColor2"
        ></bml-product-slider>
    </div>   
</ng-container>
