import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';
import { PasswordValidator } from 'src/app/shared/models/password.validator';
import { IUser } from 'src/app/shared/models/user';
import { ToastrService } from 'ngx-toastr';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';

@Component({
  selector: 'bml-user-password-change',
  templateUrl: './user-password-change.component.html',
  styleUrls: ['./user-password-change.component.scss'],
})
export class UserPasswordChangeComponent implements OnInit {
  userPasswordChangeForm!: UntypedFormGroup;
  errors!: string[];
  user!: IUser;
  isChanged: boolean = false;

  @Input() componentName!: string;
  @Input() isAdmin: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private toastr: ToastrService,
    private logger: LoggingErrorService
  ) {}

  ngOnInit(): void {
    this.createPasswordChangeForm();
  }

  createPasswordChangeForm() {
    this.userPasswordChangeForm = this.fb.group(
      {
        currentPassword: [null, [Validators.required]],
        newPassword: [
          null,
          [
            Validators.required,
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)\\S{6,50}$'),
          ],
        ],
        confirmNewPassword: [
          null,
          [
            Validators.required,
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)\\S{6,50}$'),
          ],
        ],
      },
      { validator: PasswordValidator.newPasswordShouldMatch }
    );
  }

  onSubmit() {
    this.accountService
      .changePassword(this.userPasswordChangeForm.value)
      .subscribe(
        () => {
          this.isChanged = true;
          this.toastr.success('Password updated successfully');
          this.router.navigateByUrl('/' + this.componentName + '/profile');
        },
        (error) => {
          this.errors = error.errors;
          this.logger.log(error, this.componentName);
        }
      );
  }

  get password() {
    return this.userPasswordChangeForm.get('newPassword');
  }

  get confirmPassword() {
    return this.userPasswordChangeForm.get('confirmNewPassword');
  }
}
