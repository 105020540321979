import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from 'src/app/account/account.service';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';
import { IUser } from 'src/app/shared/models/user';

@Component({
  selector: 'bml-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  userProfile!: IUser;
  @Input() componentName!: string;
  @Input() isAdmin: boolean = false;

  constructor(
    private accountService: AccountService,
    private logger: LoggingErrorService
  ) {}

  ngOnInit(): void {
    this.loadUserProfile();
  }

  loadUserProfile() {
    this.accountService.getUserProfile().subscribe(
      (response) => {
        this.userProfile = response;
        console.log("User profile: ", response)
      },
      (error) => {
        this.logger.log(error, this.componentName);
      }
    );
  }
}
