import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { IProductGenre } from 'src/app/shared/models/product';
import { IProductBrand } from 'src/app/shared/models/product-brand';
import { IProductCategory } from 'src/app/shared/models/product-category';
import { IProductSubcategory } from 'src/app/shared/models/product-subcategory';
import { IUserToReturn } from 'src/app/shared/models/user';

@Component({
  selector: 'bml-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.scss']
})
export class SideNavItemComponent implements OnInit {
  @Input() currentUser$!: Observable<IUserToReturn>;
  @Input() sessionId!: string;
  @Input() categories!: IProductCategory[];
  @Input() subcategories!: IProductSubcategory[];
  @Input() brands!: IProductBrand[];
  @Input() genres!: IProductGenre[];

  @Output() closeSideNav = new EventEmitter<any>();

  isShop: boolean = true;
  isCategories: boolean = false;
  isSubcategories: boolean = false;
  isBrands: boolean = false;
  isGenres: boolean = false;

  isSideNavOpen: boolean = false;

  constructor(private accountService: AccountService, 
    private router: Router,
    public platform: Platform) { }

  ngOnInit(): void {
  }

  closeSideNavigation() {
    this.closeSideNav.emit();
  }

  logout() {
    this.accountService.logout();
    this.closeSideNavigation();
  }

  toggleShop() {
    this.isShop = !this.isShop
    if (this.isShop) {
      this.isSubcategories = false;
      this.isBrands = false;
      this.isGenres = false;
    }
  }

  toggleCategories() {
    this.isCategories = !this.isCategories
    if (this.isCategories) {
      this.isSubcategories = false;
      this.isBrands = false;
      this.isGenres = false;
      this.isSideNavOpen = true;
    }
    else {
      this.isSideNavOpen = false;
    }
  }

  toggleSubcategories() {
    this.isSubcategories = !this.isSubcategories
    if (this.isSubcategories) {
      this.isCategories = false;
      this.isBrands = false;
      this.isGenres = false;
    }
  }

  toggleBrands() {
    this.isBrands = !this.isBrands
    if (this.isBrands) {
      this.isSubcategories = false;
      this.isCategories = false;
      this.isGenres = false;
    }
  }

  toggleGenres() {
    this.isGenres = !this.isGenres
    if (this.isGenres) {
      this.isSubcategories = false;
      this.isBrands = false;
      this.isCategories = false;
      this.isSideNavOpen = true;
    }
    else
    {
      this.isSideNavOpen = false;
    }
  }

  reload() {
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
    }, 100);
  }
}
