import { IProduct } from './product';

export interface IProductBrand {
  id: string;
  userId: string;
  name: string;
  shortDescription: string;
  longDescription: string;
  pictureUrl: string;
  slug: string;
  selected: boolean;
}

export interface IBrandPagination {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: IProductBrand[];
}

export class BrandPagination implements IBrandPagination {
  pageIndex: number = 1;
  pageSize: number = 12;
  count: number = 0;
  data!: IProductBrand[];
}

export class ProductBrandParams {
  productCategoryId: string = "";
  productSubcategoryId: string = "";
  sort = 'name';
  pageNumber = 1;
  pageSize = 12;
  search: string = "";
}
