import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoggingErrorService} from 'src/app/logging-error/logging-error.service';
import {Department, IProduct} from 'src/app/shared/models/product';
import {IProductCategory} from 'src/app/shared/models/product-category';
import {ShopService} from '../../shop.service';
import { CategoryService } from '../../category/category.service';

@Component({
  selector: 'bml-shoes-slider',
  templateUrl: './shoes-slider.component.html',
  styleUrls: ['./shoes-slider.component.scss'],
})
export class ShoesSliderComponent implements OnInit {
  products: IProduct[] = [];
  totalCount: number = 0;
  categories: IProductCategory[] = [];
  categoryId = ''

  productBackground = "white";
  sliderBackground = "linear-gradient(to bottom, #ff9900 0%, #66ccff 100%)"//"#42eff5" // "#1b0000";
  textColor = "black";

  constructor(
    private productService: ShopService,
    private categoryService: CategoryService,
    private router: Router,
    private logger: LoggingErrorService
  ) {
  }

  ngOnInit(): void {
    this.getShoesProducts(false);
    //this.signalRConnection();
  }

  // signalRConnection() {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .configureLogging(signalR.LogLevel.Information)
  //     .withUrl(environment.apiUrl + 'notify')
  //     .build();
  //
  //   connection.start().then(function () {
  //     console.log('SignalR Connected')
  //   }).catch(err => {
  //     return console.error(err.toString())
  //   })
  //
  //   connection.on("BroadcastMessage", () => {
  //     this.getShoesProducts(true);
  //   })
  // }

  getShoesProducts(useCache: boolean = false) {
    this.categoryService.getDepartmentProductsByName(useCache, Department.shoes).subscribe(
      (response: any) => {
        this.products = response.data;
        this.totalCount = response.count;
      },
      (error) => {
        this.logger.log(error, 'shoes-slider');
      }
    );
  }

  createProductClickstream(productId: string) {
    this.productService.createProductClickstream(productId).subscribe(() => {
    });
  }

  reload() {
    setTimeout(() => {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    }, 100);
  }
}
