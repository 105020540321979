import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IMaintenance } from '../shared/models/maintenance';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getMaintenances() {
    return this.http.get<IMaintenance[]>(this.baseUrl + 'maintenances').pipe(
      map((response) => response),
      retry(2)
    );
  }

  getMaintenance(id: string) {
    return this.http.get<IMaintenance>(this.baseUrl + 'maintenances/' + id).pipe(
      map((response) => response),
      retry(2)
    );
  }
}
