import { Component, ElementRef, Input, OnInit, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'bml-select-phone-code-input',
  templateUrl: './select-phone-code-input.component.html',
  styleUrls: ['./select-phone-code-input.component.scss']
})
export class SelectPhoneCodeInputComponent implements OnInit {
  @ViewChild('select', {static: true}) select!: ElementRef;
  @Input() type = 'text';
  @Input() label!: string;
  @Input() valuekey: any;
  @Input() valuename: any;
  @Input() readonly!: boolean;
  @Input() options!: any[];

  constructor(@Self() public controlDir: NgControl) { 
    this.controlDir.valueAccessor = this;
  }

  onChange(event: any) {}

  onTouch() {}

  writeValue(obj: any): void {
    this.select.nativeElement.value = obj || '';
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit(): void {
    const control = this.controlDir.control;
    const validators = control?.validator ? [control.validator] : [];
    const asyncValidators = control?.asyncValidator ? [control.asyncValidator] : [];

    control?.setValidators(validators);
    control?.setAsyncValidators(asyncValidators);
    control?.updateValueAndValidity();
  }

}
