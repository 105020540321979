import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomEncoder } from '../shared/models/paramEncode';
import {
  IChangePassword,
  IForgotPassword,
  IResetPassword,
  IRole,
  IUser,
  IUserAddress,
  IUserToReturn,
  UserToReturn,
} from '../shared/models/user';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  baseUrl = environment.apiUrl;
  accountUrl = '';
  userTokeStringId = environment.userTokenStringId;
  private currentUserSource = new ReplaySubject<IUserToReturn | any>(1);
  currentUser$ = this.currentUserSource.asObservable();
  accountUsers: IUser[] = [];
  loggedInUser!: IUserToReturn;
  isLoggedIn: boolean = false;
  roles: IRole[] = [];

  constructor(private http: HttpClient, private router: Router) {
    this.accountUrl = this.baseUrl + 'customerAccount';
  }

  loadCurrentUser(token: string) {
    if (token === null) {
      this.currentUserSource.next(undefined);
      return of(null);
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    if (token !== null) {
      const helper = new JwtHelperService();
      const decodedToken: IUserToReturn | null = helper.decodeToken(token);
      if (decodedToken) this.roles = decodedToken.roles;
      this.isLoggedIn = true;
    }

    return this.http.get<IUserToReturn>(this.accountUrl, { headers }).pipe(
      map((user: IUserToReturn) => {
        if (user) {
          localStorage.setItem(this.userTokeStringId, user.token);
          this.currentUserSource.next(user);
          this.isLoggedIn = true;
          this.roles = user.roles;
        }
      })
    );
  }

  login(values: any) {
    return this.http.post(this.accountUrl + '/login', values).pipe(
      map((user: any) => {
        if (user) {
          localStorage.setItem(this.userTokeStringId, user.token);
          this.currentUserSource.next(user);
          this.loggedInUser = user;
          this.isLoggedIn = true;
        }
      })
    );
  }

  register(values: any) {
    return this.http.post(this.accountUrl + '/register', values).pipe(
      map((user: any) => {
        if (user) {
          localStorage.setItem(this.userTokeStringId, user.token);
          this.currentUserSource.next(user);
        }
      })
    );
  }

  getUserProfile() {
    return this.http.get<IUser>(this.accountUrl + '/profile');
  }

  updateUserProfile(user: IUser) {
    return this.http.post<IUser>(this.accountUrl + '/', user);
  }

  logout() {
    localStorage.removeItem(this.userTokeStringId);
    this.currentUserSource.next(undefined);
    this.navigateToHomePage();
  }

  checkEmailExists(email: string) {
    return this.http.get(this.accountUrl + '/emailExist?email=' + email);
  }

  checkUsernameExists(username: string) {
    return this.http.get(
      this.accountUrl + '/usernameExist?username=' + username
    );
  }

  getUserAddresses() {
    return this.http.get<IUserAddress[]>(this.accountUrl + '/addresses');
  }

  UpdateUserAddresses(address: IUserAddress) {
    return this.http.put(this.accountUrl + '/addresses', address).pipe(
      map((response) => {
        if (!response) {
          console.log('Error Occured during update.');
          throw new Error('Value Expected');
        }
        return response;
      }),
      catchError((err) => of([]))
    );
  }

  forgotPassword(data: IForgotPassword) {
    return this.http.post(this.accountUrl + '/forgotPassword', data);
  }

  resetPassword(data: IResetPassword) {
    return this.http.post(this.accountUrl + '/resetPassword', data);
  }

  changePassword(data: IChangePassword) {
    return this.http.post(this.accountUrl + '/changePassword', data);
  }

  confirmEmail(email: string, token: string) {
    let params = new HttpParams({ encoder: new CustomEncoder() });

    params = params.append('token', token);
    params = params.append('email', email);

    return this.http.get(this.accountUrl + '/emailConfirmation', {
      params: params,
    });
  }

  navigateToHomePage() {
    this.router.navigateByUrl('/').then(() => {
      location.reload();
    });
  }
}
