<div [class.admin-container]="isAdmin">
  <div class="container">       
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h3>Change your password</h3> 
        <div class="card">
          <div class="card-body">
            <form [formGroup]="userPasswordChangeForm" (ngSubmit)="onSubmit()">
              <ul class="alert alert-danger" *ngIf="errors">
                <li class="ml-3" *ngFor="let error of errors">{{ error }}</li>
              </ul>
      
              <bml-password-rule [passwordElement]="password"></bml-password-rule>
      
              <bml-text-input
                formControlName="currentPassword"
                [label]="'Current Password'"
                [type]="'password'"
              ></bml-text-input>
              <bml-text-input
                formControlName="newPassword"
                [label]="'New Password'"
                [type]="'password'"
              ></bml-text-input>
              <bml-text-input
                formControlName="confirmNewPassword"
                [label]="'Confirm New Password'"
                [type]="'password'"
                [bmlFormName]="userPasswordChangeForm"
              ></bml-text-input>
      
              <button
                [disabled]="userPasswordChangeForm.invalid"
                class="bml-stepper-custom-outline-btn"
                type="submit"
                pb-role="submit"
              >
                Submit
              </button>
            </form>
            <hr />
            <div class="btn btn-warning" routerLink="/{{ componentName }}/profile">
              <i class="fa fa-backward"></i> Back to Profile
            </div>
          </div>
        </div>       
      </div>
    </div>
  </div>
</div>
