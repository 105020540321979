import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IAnnouncement } from '../../models/announcement';

@Component({
  selector: 'bml-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  announcement!: IAnnouncement;

  constructor(public bsModalRef: BsModalRef ) { }

  ngOnInit(): void {
  }

}
