import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {

  visible: boolean = true;
  admin: boolean = false;

  constructor() { 
    this.visible = true;
    this.admin = false;
  }

  hide() {this.visible = false}
  show() {this.visible = true}
  toggle() {this.visible = !this.visible}
  hideAdminToolBar() {
    this.admin = false;
  }
  showAdminToolBar() {
    this.admin = true;
  }

  showMenuEvent: EventEmitter<any> = new EventEmitter<any>();

}
