<div 
  [ngClass]="{
    'ios-mobile': platform.IOS,
    'android-mobile': platform.ANDROID
  }"   
>
  <a class="nav-item" (click)="goToBmlServices()">
    <p class="top-link">High Quality</p>
    <strong>Software Services</strong>
    <span class="sr-only">(current)</span>
  </a>

  <a class="nav-item" (click)="goToBmlECommerce()">
    <p class="top-link">Our Brands</p>
    <strong>BML Shopping</strong> <span class="sr-only">(current)</span>
  </a>

  <a class="nav-item" 
    routerLink="/orders"
    [queryParams]="{
      sessionId: sessionId,
      'page': 'orders'
    }"
    queryParamsHandling="merge" 
  >
    <p class="top-link">History</p>
    <strong>Orders History</strong>
  </a>

  <ng-container *ngIf="(currentUser$ | async) === null">
    <li class="nav-item dropdown">
      <button [cdkMenuTriggerFor]="accountMenu" class="standalone-trigger">
        <mat-icon>person</mat-icon>
        <strong>
          Account
          <span
              class="ml-1 fa"
              [ngClass]="{
              'fa-caret-up': isAccountOpen,
              'fa-caret-down': !isAccountOpen
              }"
          ></span>
        </strong>
      </button>

      <ng-template #accountMenu>
        <div class="menu" cdkMenu>
          <button class="menu-item" 
            routerLink="/account/login" 
            [queryParams]="{
              sessionId: sessionId,
              'page': 'login'
            }"
            queryParamsHandling="merge"
            cdkMenuItem
          >Login</button>
          <button class="menu-item" 
            routerLink="/account/register"
            [queryParams]="{
              sessionId: sessionId,
              'page': 'register'
            }"
            queryParamsHandling="merge"
            cdkMenuItem
          >Register</button>
        </div>
      </ng-template>
    </li>
  </ng-container>

  <ng-container *ngIf="currentUser$ | async as user">
    <li class="nav-item dropdown">
      <button [cdkMenuTriggerFor]="loggedIMenu" class="standalone-trigger">
        <strong>
          Welcome {{ user.firstName }}
          <span
              class="ml-1 fa"
              [ngClass]="{
              'fa-caret-up': isAccountOpen,
              'fa-caret-down': !isAccountOpen
              }"
          ></span>
        </strong>
      </button>

      <ng-template #loggedIMenu>
        <div class="menu" cdkMenu>
          <button class="menu-item" 
            routerLink="/account/profile" cdkMenuItem
            [queryParams]="{
              sessionId: sessionId,
              'name': (user.firstName + '-' + user.lastName) | lowercase
            }"
            queryParamsHandling="merge"
          >
            <i class="fa fa-user me-3"></i> Acc. #: {{ user.accountNumber }}
          </button>
          <button class="menu-item" 
            routerLink="/account/profile" 
            [queryParams]="{
              sessionId: sessionId,
              'page': 'profile',
              'name': (user.firstName + '-' + user.lastName) | lowercase
            }"
            queryParamsHandling="merge"
            cdkMenuItem
          >
            <i class="fa fa-user me-3"></i> Your Profile
          </button>
          <button class="menu-item" 
            routerLink="/shopping-cart" 
            [queryParams]="{
              sessionId: sessionId,
              'page': 'basket',
              'basketId': (radomUUID +'-'+ cartId) | lowercase
            }"
            queryParamsHandling="merge"
            cdkMenuItem
          >
            <i class="fa fa-shopping-cart me-3"></i> View your Cart
          </button>
          <button class="menu-item" routerLink="/orders"
            [queryParams]="{
              sessionId: sessionId,
              'page': 'orders'
            }"
            queryParamsHandling="merge" 
            cdkMenuItem
          >
            <i class="fa fa-history me-3"></i> View your Orders
          </button>
          <mat-divider></mat-divider>
          <button class="menu-item" (click)="logout()" cdkMenuItem>
            <i class="fa fa-power-off me-3"></i> Logout
          </button>
        </div>
      </ng-template>
    </li>
  </ng-container>
  
  <ng-container>
      <a class="nav-item" 
        routerLink="/shopping-cart"
        [queryParams]="{
          sessionId: sessionId,
          'basketId': (radomUUID +'-'+ cartId) | lowercase
        }"
        queryParamsHandling="merge"
      >
        <i
          matBadge="{{ cartTotalItems }}"
          matBadgeSize="large"
          matBadgeColor="accent"
          class="fa fa-shopping-cart fa-2x"
        ></i>
        <strong>Cart</strong>
      </a>
  </ng-container>
</div>