<mat-sidenav-container class="sidenav-container">
  <mat-sidenav 
    #drawer
    class="sidenav"
    [ngClass]="{'ios-mobile-sidenav': platform.IOS, 'no-ios-sidenav': !platform.IOS}"
    [ngClass]="{hidden: (isHandset$ | async)  === false}"  
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <mat-toolbar
      [ngClass]="{'ios-mobile': platform.IOS, 'android-mobile': platform.ANDROID}" 
    >
      <img
        *ngIf="!platform.IOS"
        class="bml-logo"
        src="/assets/images/bml-marketplace-1.png"
        alt="BML Marketplace"
        routerLink="/"
      />
      <img
        *ngIf="platform.IOS"
        class="bml-logo"
        src="/assets/images/bml-marketplace-2.png"
        alt="BML Marketplace"
        routerLink="/"
      />
      <span class="spacer"></span>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon 
          aria-label="Side nav toggle icon"
          [ngClass]="{
            'ios-mobile-menu': platform.IOS
          }"
        >close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-nav-list [ngClass]="{'ios-mobile-sidenav': platform.IOS, 'no-ios-sidenav': !platform.IOS}">
      <bml-side-nav-item 
        [currentUser$]="currentUser$"
        [categories]="categories"
        [subcategories]="subcategories"
        [brands]="brands"
        [genres]="genres"
        (closeSideNav)="drawer.toggle()"
      >
      </bml-side-nav-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-container *ngIf="navbarService.visible">
      <mat-toolbar color="primary"
        [ngClass]="{
          'ios-mobile': platform.IOS,
          'android-mobile': platform.ANDROID
        }"      
      >
        <span>
          <img
            *ngIf="!platform.IOS"
            class="bml-logo"
            src="/assets/images/bml-marketplace-1.png"
            alt="BML Marketplace"
            routerLink="/"
          />
          <img
            *ngIf="platform.IOS"
            class="bml-logo"
            src="/assets/images/bml-marketplace-2.png"
            alt="BML Marketplace"
            routerLink="/"
          />
        </span>
        <ng-container *ngIf="isHandset$ | async">
          <span class="spacer"></span>
        </ng-container>
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon 
            aria-label="Side nav toggle icon"
            [ngClass]="{
              'ios-mobile-menu': platform.IOS
            }"
          >menu</mat-icon>
        </button>
        <ng-container *ngIf="(isHandset$ | async) === false">
          <span class="spacer"></span>
        </ng-container>
        <ng-container *ngIf="(isHandset$ | async) === false">
          <bml-main-nav-item
            [cart$]="cart$"
            [currentUser$]="currentUser$"
            [cartTotalItems]="cartTotalItems"
          >
          </bml-main-nav-item>
        </ng-container>
      </mat-toolbar>
      <ng-container *ngIf="(isHandset$ | async) === false">
        <bml-main-sub-nav-item
          [cart$]="cart$"
          [currentUser$]="currentUser$"
          [cartTotalItems]="cartTotalItems"
          [categories]="categories"
          [subcategories]="subcategories"
          [brands]="brands"
          [genres]="genres"
        ></bml-main-sub-nav-item>
      </ng-container> 
      <ng-container *ngIf="isHandset$ | async">
        <bml-search></bml-search>
      </ng-container>
      <ng-container *ngIf="isHandset$ | async">
        <bml-footer-mobile-toolbar 
          [cartTotalItems]="cartTotalItems"
          [currentUser$]="currentUser$"
        ></bml-footer-mobile-toolbar>
      </ng-container>       
    </ng-container>
    <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
