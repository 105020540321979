import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';
import { IAnnouncement } from '../models/announcement';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private logger: LoggingErrorService) { }

  getActivatedAnnouncements() {
    return this.http.get<IAnnouncement[]>(this.baseUrl + 'announcements/activatedAnnouncements/shopping-center')
    .pipe(map(response => {
      if (!response) {
        this.logger.log('Error Occured while retrieving announcement.', 'Announcement Service');
        throw new Error('Value Expected');
      }
      return response;
    }));
  }
}
