<div class="collections" *ngIf="collections && collections.length > 0">
    <div *ngFor="let collection of collections; let i = index" class="collection">
        <ng-container *ngIf="collection.productCount > 0 && collection.subcategory">
            <h3 routerLink="/shop/products/collections/{{ collection.subcategory.id }}/{{collection.subcategory.slug}}">
              {{collection.subcategory.name}} Collection
            </h3>

            <ng-container *ngIf="collection.products && collection.products.length > 0">
              <bml-product-slider 
                [products]="collection.products"
                [isClothes]="collection.subcategory.category === 'Clothes' ? true : false"
                [sliderBackgroundColor]="sliderBackground"
                [sliderTextColor]="textColor"
                [productBackgroundColor]="productBackground">
              </bml-product-slider>
            </ng-container>

        </ng-container>      
    </div>
</div>
