<div class="admin" [class.admin-container]="isAdmin">
  <div *ngIf="currentUser$ | async as user">
    <div class="">
      <div class="bml-default-jumbotron">
        <h1>{{ user.firstName }} {{ user.lastName }}'s Addresses</h1>
      </div>

      <div
        class="btn bml-stepper-custom-outline-btn mb-5"
        routerLink="/{{ componentName }}/address/new"
      >
        <i class="fa fa-plus-circle"></i> Add an Address
      </div>
      <div *ngIf="isDeleted" class="alert alert-danger py-3 mb-5 text-center">
        <h1 class="title">
          You have successfully deleted the your address, {{ user.firstName }}.
        </h1>
      </div>
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Address type</th>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th scope="col">Province</th>
            <th scope="col">Country</th>
            <th scope="col">Phone Number</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let address of addresses">
            <th scope="row">{{ address.addressType }}</th>
            <td>{{ address.addressLine1 }}</td>
            <td>{{ address.city }}</td>
            <td>{{ address.province }}</td>
            <td>{{ address.country }}</td>
            <td>{{ address.countryCode }} {{ address.phoneNumber }}</td>
            <td>
              <button
                class="btn btn-primary"
                routerLink="/{{ componentName }}/profile"
              >
                <i class="fa fa-eye"></i> Detail
              </button>
              <button
                class="btn btn-warning mx-2"
                routerLink="/{{ componentName }}/address/{{ address.id }}"
              >
                <i class="fa fa-edit"></i> Update
              </button>
              <button
                class="btn btn-danger"
                (click)="deleteUserAddress(address.id)"
              >
                <i class="fa fa-trash"></i> Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="btn btn-outline-warning btn-lg mt-3"
        routerLink="/{{ componentName }}/profile"
      >
        <i class="fa fa-backward"></i> Back to Profile
      </div>
    </div>
  </div>
</div>
