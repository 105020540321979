import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {LoggingErrorService} from 'src/app/logging-error/logging-error.service';
import {IProduct, ProductParams} from '../../../shared/models/product';
import {ShopService} from '../../shop.service';

@Component({
  selector: 'bml-user-session-product',
  templateUrl: './user-session-product.component.html',
  styleUrls: ['./user-session-product.component.scss']
})
export class UserSessionProductComponent implements OnInit {
  clothesProducts: IProduct[] = [];
  noClothesProducts: IProduct[] = [];
  allProducts: IProduct[] = [];
  shopParams: ProductParams;
  totalCount: number = 0;

  @Input() textColor = 'white';
  @Input() textColor2 = 'white';
  @Input() sliderBackground1 = '#1b0000' // '#804000';
  @Input() sliderBackground2 = '#322323';

  component = "Session Clickstream products";
  isLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width: 1700px)']) // Fits Handset and Tablet
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private service: ShopService,
              private breakpointObserver: BreakpointObserver,
              private router: Router,
              private logger: LoggingErrorService) {
    this.shopParams = service.getProductsParams();
    this.shopParams.pageSize = 6;
  }

  ngOnInit(): void {
    this.getClickstreamSessionProducts(false);
    //this.signalRConnection();
  }

  // signalRConnection() {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .configureLogging(signalR.LogLevel.Information)
  //     .withUrl(environment.apiUrl + 'notify')
  //     .build();
  //
  //   connection.start().then(() => {
  //     this.getClickstreamSessionProducts(false);
  //   }).catch(err => {
  //     return console.error(err.toString())
  //   })
  //
  //   connection.on("BroadcastMessage", () => {
  //     //this.getClickstreamSessionProducts(false);
  //   })
  // }

  getClickstreamSessionProducts(useCache: boolean) {
    this.service.getClickstreamsSessionViewedProducts(useCache).subscribe((response: any) => {
        this.allProducts = response.data;
        this.noClothesProducts = this.allProducts.filter(x => x.productCategory.toLowerCase() !== "Clothes".toLowerCase());
        this.clothesProducts = this.allProducts.filter(x => x.productCategory.toLowerCase() === "Clothes".toLowerCase())
        this.totalCount = response.count;
      },
      error => {
        this.logger.log(error, this.component);
      })
  }

  onPageChanged(event: any) {
    const params = this.service.getProductsParams();
    if (params.pageNumber !== event) {
      params.pageNumber = event;
      this.service.setProductsParams(params);
      this.getClickstreamSessionProducts(true);
    }
  }

  reload() {
    setTimeout(() => {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    }, 100);
  }
}
