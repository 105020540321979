import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AccountService } from 'src/app/account/account.service';
import { IBasket } from 'src/app/shared/models/shopping-cart';
import { IUserToReturn } from 'src/app/shared/models/user';
import { CategoryService } from 'src/app/shop/category/category.service';
import { ShoppingCartService } from 'src/app/shopping-cart/shopping-cart.service';
import { NavBarService } from '../nav-bar/nav-bar.service';
import { IProductCategory } from 'src/app/shared/models/product-category';
import { Roles } from 'src/app/shared/models/role';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { Platform } from '@angular/cdk/platform';
import { IProductSubcategory } from 'src/app/shared/models/product-subcategory';
import { IProduct, IProductGenre } from 'src/app/shared/models/product';
import { IProductBrand } from 'src/app/shared/models/product-brand';
import { BrandService } from 'src/app/shop/brand/brand.service';
import { SubcategoryService } from 'src/app/shop/collection/collection.service';
import { GenreService } from 'src/app/shop/genre/genre.service';

@Component({
  selector: 'bml-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.scss'],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
})
export class MainNavBarComponent implements OnInit {
  cart$!: Observable<IBasket>;
  cartTotalItems = 0;
  currentUser$!: Observable<IUserToReturn>;
  userRole = Roles;
  isServiceCategory = false;

  products: IProduct[] = [];
  categories: IProductCategory[] = [];
  subcategories: IProductSubcategory[] = [];
  brands: IProductBrand[] = [];
  genres: IProductGenre[] = [];

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.Tablet]) // Fits Handset and Tablet
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isLargeScreen$: Observable<boolean> = this.breakpointObserver
  .observe(['(min-width: 1500px)']) // Fits Handset and Tablet
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public platform: Platform,
    public navbarService: NavBarService,
    private accountService: AccountService,
    private cartService: ShoppingCartService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private subcategoryService: SubcategoryService,
    private genreService: GenreService,
    ) {}

  ngOnInit(): void {
    this.cart$ = this.cartService.basket$;
    this.getCartItems();
    this.currentUser$ = this.accountService.currentUser$;
    this.getCategories();
    this.getCollections();
    this.getBrands();
    this.getGenres();
  }

  getRole(user: IUserToReturn, role: string) {
    return user && user?.roles?.find((x) => x.name === role);
  }

  getCartItems() {
    this.cart$
      .pipe(
        map((res: IBasket) => {
          return res;
        })
      )
      .subscribe((res) => {
        if (res && res.basketItems) {
          this.cartTotalItems = res.basketItems.reduce(
            (a, b) => b.quantity + a,
            0
          );
        }
      });
  }

  getCategories() {
    this.categoryService.getCategories().subscribe(response => {
      this.categories = response;
    })
  }

  getCollections() {
    this.subcategoryService.getCollections(false).subscribe(response => {
      this.subcategories = response.data;
    })
  }

  getBrands() {
    this.brandService.getBrands(false).subscribe(response => {
      this.brands = response.data;
    })
  }

  getGenres() {
    this.genreService.getGenres().subscribe(response => {
      this.genres = response;
    })
  }

}
