<div class="card">
  <div
    (click)="goToProductDetail(product)"
    class="card-body"
  >
    <div class="product-image">
      <ng-container *ngIf="newImageUrl">
        <img *ngIf="newImageUrl" [alt]="product.name" [src]="newImageUrl" class="image"/>
      </ng-container>
      <ng-container *ngIf="!newImageUrl">
        <img *ngIf="product.pictureUrl" [alt]="product.name" [src]="product.pictureUrl" class="image"/>
        <img *ngIf="!product.pictureUrl" [alt]="product.name" class="image" src="/assets/images/product-no-image.jpg"/>
      </ng-container>
    </div>
    <div class="colors-container">
      <ng-container *ngFor="let color of product.productColors; let i = index">
        <span (mouseover)="showColorImage(color)"
              *ngIf="!color.colorPictureUrl"
              [ngStyle]="{'backgroundColor': color.name}"
              class="color"
        >
        </span>
        <span *ngIf="color.colorPictureUrl">
          <img (mouseover)="showColorImage(color)"
               [alt]="color.name"
               [src]="color.colorPictureUrl"
               class="color-image">
        </span>
        <!-- <span class="color"
          *ngIf="color.colorPictureUrl"
          [ngStyle]="{'background': 'url(' + color.colorPictureUrl + ')'}"
          (mouseover)="showColorImage(color)"
        >
        </span> -->
      </ng-container>
    </div>
    <div class="product-info">
      <p class="product-brand">
        <span class="brand-name">{{ product.productBrand }}</span><br>
        <span
          class="product-name">{{ (product.name.length > 50) ? (product.name | slice:0:50) + '...' : product.name }}</span>
      </p>
      <div *ngIf="isNewProduct" class="arrival-container">
        <span class="arrival">New Arrival</span>
      </div>
      <p *ngIf="product.price === product.listPrice" class="product-price">
        {{ product.listPrice | currency }}
      </p>
      <p *ngIf="product.price !== product.listPrice" class="product-price">
        <span class="list-price">{{ product.listPrice | currency }}</span>
        <span class="actual-price">{{ product.price | currency }}</span>
      </p>

      <p class="discount">
        <span *ngIf="(product.isOnSpecialOfferSell && product.specialOfferPercentage)" class="sale-offer">
          On Sale -
        </span>
        <span *ngIf="product.productDiscountPercent">
          Save {{product.productDiscountPercent}}%
        </span>
        <span *ngIf="product.specialOfferPercentage">
          Save {{product.specialOfferPercentage}}%
        </span>
      </p>
    </div>
  </div>
</div>
