import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {IProduct, IProductPagination, ProductPagination, ProductParams} from 'src/app/shared/models/product';
import {IProductBrand} from 'src/app/shared/models/product-brand';
import {CategoryPagination, IProductCategory} from 'src/app/shared/models/product-category';
import {environment} from 'src/environments/environment';
import {IProductGenre} from '../../shared/models/product';
import {IProductProductStyle} from '../../shared/models/product-style';
import {IProductSubcategory} from '../../shared/models/product-subcategory';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  baseUrl = environment.apiUrl;
  categories: IProductCategory[] = [];
  categoryPagination = new CategoryPagination();
  categoryParams = new ProductParams();

  departmentProducts: IProduct[] = [];
  departmentProductParams = new ProductParams();
  departmentProductPagination = new ProductPagination();

  categoryProducts: IProduct[] = [];
  categoryProductParams = new ProductParams();
  categoryProductPagination = new ProductPagination();

  constructor(private http: HttpClient) {
  }

  getCategories(useCache = false) {
    if (useCache === false) {
      this.categories = [];
    }

    if (this.categories.length > 0 && useCache === true) {
      return of(this.categories);
    }

    return this.http.get<IProductCategory[]>(this.baseUrl + 'productCategories')
      .pipe(
        map(response => {
          this.categories = response;
          return this.categories;
        })
      );
  }

  setCategoriesParams(params: ProductParams) {
    this.categoryParams = params;
  }

  getCategoriesParams() {
    return this.categoryParams;
  }


  getOfferCategories() {
    return this.http.get<IProductCategory[]>(this.baseUrl + 'productCategories/discountProducts')
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getSubcategoryOfferCategories(subcategoryId: string) {
    return this.http.get<IProductCategory[]>(this.baseUrl + 'productCategories/discountProducts/subcategory/' + subcategoryId)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getBrandOfferCategories(brandId: string) {
    return this.http.get<IProductCategory[]>(this.baseUrl + 'productCategories/discountProducts/brand/' + brandId)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getCategory(id: string) {
    const category = this.categories.find(s => s.id === id);
    if (category) {
      return of(category);
    }

    return this.http.get<IProductCategory>(this.baseUrl + 'productCategories/' + id);
  }

  getCategoryBrands(categoryId: string) {
    return this.http.get<IProductBrand[]>(this.baseUrl + 'productCategories/brands/' + categoryId)
      .pipe(
        map(response => {
          if (!response) {
            console.log('Error Occurred during category brands retrieval.')
            throw new Error("Value Expected");

          }
          return response;
        }),
        catchError(err => of([]))
      );
  }

  getCategoryCollections(categoryId: string) {
    return this.http.get<IProductSubcategory[]>(this.baseUrl + 'productCategories/subcategories/' + categoryId)
      .pipe(
        map(response => {
          if (!response) {
            console.log('Error Occurred during category brands retrieval.')
            throw new Error("Value Expected");

          }
          return response;
        }),
        catchError(err => of([]))
      );
  }

  getCategoryStyles(categoryId: string) {
    return this.http.get<IProductProductStyle[]>(this.baseUrl + 'productCategories/productStyles/' + categoryId)
      .pipe(
        map(response => {
          if (!response) {
            console.log('Error Occurred during category brands retrieval.')
            throw new Error("Value Expected");

          }
          return response;
        }),
        catchError(err => of([]))
      );
  }

  getCategoryGenres(categoryId: string) {
    return this.http.get<IProductGenre[]>(this.baseUrl + 'productCategories/genres/' + categoryId);
  }

  setDepartmentProductParams(params: ProductParams) {
    this.departmentProductParams = params;
  }

  getDepartmentProductParams() {
    return this.departmentProductParams;
  }

  getDepartmentProductsByName(useCache: boolean, departmentName: string) {
    if (!useCache) {
      this.departmentProducts = [];
    }

    if (this.departmentProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.departmentProducts.length / this.departmentProductParams.pageSize);

      if (this.departmentProductParams.pageNumber <= pagesReceived) {
        this.departmentProductPagination.data = this.departmentProducts
          .slice(
            (this.departmentProductParams.pageNumber - 1) * this.departmentProductParams.pageSize,
            this.departmentProductParams.pageNumber * this.departmentProductParams.pageSize
          );

        return of(this.departmentProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.departmentProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'products/departmentProducts/' + departmentName, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.departmentProducts = response.body ? [...this.departmentProducts, ...response.body.data] : this.departmentProducts;
          this.departmentProductPagination = response.body ? response.body : new ProductPagination();
          return this.departmentProductPagination;
        }),
        catchError(err => of([]))
      );
  }

  setCategoryProductParams(params: ProductParams) {
    this.categoryProductParams = params;
  }

  getCategoryProductParams() {
    return this.categoryProductParams;
  }

  getDepartmentProductsById(categoryId: string, useCache: boolean) {
    if (!useCache) {
      this.categoryProducts = [];
    }

    if (this.categoryProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.categoryProducts.length / this.categoryProductParams.pageSize);

      if (this.categoryProductParams.pageNumber <= pagesReceived) {
        this.categoryProductPagination.data = this.categoryProducts
          .slice(
            (this.categoryProductParams.pageNumber - 1) * this.categoryProductParams.pageSize,
            this.categoryProductParams.pageNumber * this.categoryProductParams.pageSize
          );

        return of(this.categoryProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.categoryProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'productCategories/products/' + categoryId, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.categoryProducts = response.body ? [...this.categoryProducts, ...response.body.data] : this.categoryProducts;
          this.categoryProductPagination = response.body ? response.body : new ProductPagination();
          return this.categoryProductPagination;
        })
      );
  }

  private setParamsMethod(params: HttpParams, productParams: ProductParams) {

    if (productParams.productCategoryId) {
      params = params.append('productCategoryId', productParams.productCategoryId.toString());
    }
    if (productParams.productSubcategoryId) {
      params = params.append('productSubcategoryId', productParams.productSubcategoryId.toString());
    }
    if (productParams.productBrandId) {
      params = params.append('productBrandId', productParams.productBrandId.toString());
    }
    if (productParams.productGenre) {
      params = params.append('productGenre', productParams.productGenre.toString());
    }
    if (productParams.productStyleId) {
      params = params.append('productStyleId', productParams.productStyleId.toString());
    }
    if (productParams.productId) {
      params = params.append('productId', productParams.productId.toString());
    }

    if (productParams.search) {
      params = params.append('search', productParams.search);
    }

    params = params.append('sort', productParams.sort);
    params = params.append('pageIndex', productParams.pageNumber.toString());
    params = params.append('pageSize', productParams.pageSize.toString());

    return params;
  }

}
