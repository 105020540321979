<ng-container *ngIf="products">
  <div class="products" *ngIf="products.length > 0">
    <h3 class="title">Products you may like</h3>
    <div class="bml-custom-row product-items-container" [class.whiteBg]="isBackgroundNotWhite">
      <div
        class="p-0 m-0 product"
        [ngClass]="{'bml-col-8': bmlCol8, 'bml-col-7': !bmlCol8}"
        *ngFor="let product of products"
      >
        <bml-product-item [product]="product"></bml-product-item>
      </div>
    </div>

    <ng-container *ngIf="!isLoadPagination">
      <div class="d-flex justify-content-center product-pagination" *ngIf="totalCount > 0">
        <bml-pagination
          [pageSize]="productParams.pageSize"
          [totalCount]="totalCount"
          [pageNumber]="productParams.pageNumber"
          (pageChanged)="onPageChanged($event)"
        ></bml-pagination>
      </div>
    </ng-container>
    
    <ng-container *ngIf="isLoadPagination">
      <div class="show-more-or-less">
        <div class="text-center">
            <button mat-raised-button color="accent" class="mx-2" *ngIf="products.length > initialCount" (click)="showLess()">
                Show Less
            </button>
            <button *ngIf="productParams.pageSize < totalCount" mat-raised-button color="primary" (click)="loadMore()">
                Load more
            </button>
        </div>
      </div>
      <!-- <div class="load">
        <div class="text-center"
          *ngIf="totalCount > products.length"
        >
          <button mat-raised-button color="primary" (click)="loadMore()">
            Load more
          </button>
        </div>
        <div class="text-center"
          *ngIf="products.length == totalCount"
        >
          <button mat-raised-button color="primary" (click)="loadMore()">
            Show Less
          </button>
        </div>
      </div> -->
    </ng-container>   

  </div>
</ng-container>

