import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from 'src/app/account/account.service';
import { Roles } from 'src/app/shared/models/role';
import { IUserToReturn } from 'src/app/shared/models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AccountService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }
    this.router.navigate(['account/login/'], {queryParams: { returnUrl: state.url }});
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard3  {
  constructor(private authService: AccountService, private router: Router) {}

  getRole(user: IUserToReturn, role: string) {
    return user && user.roles.find((x) => x.name === role);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn) {
      if (
        next.data['roles'] &&
        next.data['roles'].indexOf(
          this.authService.roles.find(
            (x) => x.name === Roles.superAdmin || x.name === Roles.admin
          )?.name
        ) === -1
      ) {

        this.router.navigate(['account/login'], {queryParams: { returnUrl: state.url }});
        return false;
      } 

      return true;     
    }

    this.router.navigate(['/']);
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard4  {
  constructor(private accountservice: AccountService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.accountservice.currentUser$.pipe(
      map((auth) => {
        if (auth) {
          return true;
        }
        else{
          this.router.navigate(['account/login'], {
            queryParams: { returnUrl: state.url },
          });
          return false;
        } 
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard2  {
  constructor(private accountservice: AccountService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.accountservice.currentUser$.pipe(
      map((auth) => {
        if (auth) {
          if (
            next.data['roles'] &&
            next.data['roles'].indexOf(auth.roles[0].name) === -1
          ) {
            this.router.navigate(['/']);
            return false;
          } else {
            return true;
          }
        }
        this.router.navigate(['account/login'], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      })
    );
  }
}

