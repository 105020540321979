<ng-container *ngIf="products && products.length > 0">
  <div [ngStyle]="{'background': sliderBackgroundColor, 'color': sliderTextColor}"
       class="product-content"
  >
    <ngx-slick-carousel #slickModal="slick-carousel"
                        [config]="!isClothes ? slideClothesConfig : slideNoClothesConfig"
                        class="carousel"
                        responsive="breakpoints"
    >
      <div (click)="goToProductDetail(product)" *ngFor="let product of products"
        [ngStyle]="{'background': productBackgroundColor}"
        class="slide product-card"
        ngxSlickItem
      >
        <ng-container *ngIf="currentProductCardId === product.id && newImageUrl">
          <img
            *ngIf="newImageUrl"
            [src]="newImageUrl"

            alt="{{ product.name }}"
            class="image"
          />
        </ng-container>
        <ng-container *ngIf="currentProductCardId !== product.id || !newImageUrl">
          <img
            *ngIf="product.pictureUrl"
            [src]="product.pictureUrl"
            alt="{{ product.name }}"
            class="image"
          />
          <img
            *ngIf="!product.pictureUrl"
            [alt]="product.name"
            class="image"
            src="/assets/images/product-no-image.jpg"
          />
        </ng-container>

        <div class="product-description">
          <div class="colors-container">
            <ng-container *ngFor="let color of product.productColors; let i = index">
                      <span (mouseover)="showColorImage(color, product)"
                            *ngIf="!color.colorPictureUrl"
                            [ngStyle]="{'background': color.name}"
                            class="color"
                      >
                      </span>
              <span *ngIf="color.colorPictureUrl">
                        <img (mouseover)="showColorImage(color, product)"
                             [alt]="color.name"
                             [src]="color.colorPictureUrl"
                             class="color-image">
                      </span>
            </ng-container>
          </div>

          <div class="product-info">
            <p class="product-brand">
              <span class="brand-name">{{product.productBrand}}</span> <br/>

              <span class="wrapper">
                <span
                  class="product-name">{{ (product.name.length > 50) ? (product.name | slice:0:50) + '...' : product.name }}
                </span>
              </span>              
            </p>
            <p *ngIf="product.price === product.listPrice" class="product-price">
              {{ product.listPrice | currency }}
            </p>
            <p *ngIf="product.price !== product.listPrice" class="product-price">
              <span class="list-price">{{ product.listPrice | currency }}</span>
              <span class="actual-price">{{ product.price | currency }}</span>
            </p>

            <div class="wrapper">
              <p class="discount">
                <span *ngIf="(product.isOnSpecialOfferSell && product.specialOfferPercentage)">
                  On Sale -
                </span>
                <span *ngIf="product.productDiscountPercent">
                  Save {{product.productDiscountPercent}}%
                </span>
                <span *ngIf="product.specialOfferPercentage">
                  Save {{product.specialOfferPercentage}}%
                </span>
              </p>
            </div>           
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</ng-container>
