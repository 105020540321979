<div>
  <ul class="nav nav-pills nav-justified mb-3">
    <li class="nav-item" *ngFor="let step of steps; let i = index">
      <button
        (click)="onClick(i)"
        [disabled]="true"
        [class.active]="selectedIndex === i"
        [class.basket-table-header]="isBasket"
        [class.table-header]="!isBasket"
        class="nav-link text-uppercase font-weight-bold btn-block"
      >
        {{ step.label }}
        <i *ngIf="step.completed" class="fa fa-check-circle-o"></i>
      </button>
    </li>
  </ul>
  <div>
    <ng-container [ngTemplateOutlet]="selected!.content" ></ng-container>
  </div>
</div>
