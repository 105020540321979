<div class="form-group">
    <textarea 
        [ngClass]="(controlDir && controlDir.control && controlDir.control.touched) 
            ? (!controlDir.control.valid ? 'is-invalid': 'is-valid') : ''"
        (input)="onChange($any($event.target).value)"
        (blur)="onTouch()"
        id="{{label}}"
        #textarea
        class="form-control" 
        [rows]="rows" 
        placeholder="{{label}}">
    </textarea>
    <div class="text-danger" *ngIf="(controlDir && controlDir.control 
        && controlDir.control?.touched && controlDir.control.invalid)"
    >
        <div *ngIf="controlDir.errors?.['required']">This field is required.</div>
        <div *ngIf="controlDir.errors?.['minlength']">{{label}} should be minimum {{controlDir.errors?.['minlength'].requiredLength}} characters.</div>
        <div *ngIf="controlDir.errors?.['maxlength']">{{label}} should have a maximum of {{controlDir.errors?.['maxlength'].requiredLength}} characters.</div>
    </div>    
</div>
