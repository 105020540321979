<div class="form-group">
  <!-- <label>{{ label }}</label> -->
  <select
    [ngClass]="
      controlDir && controlDir.control && controlDir.control.touched
        ? !controlDir.control.valid
          ? 'is-invalid'
          : 'is-valid'
        : ''
    "
    (input)="onChange($any($event.target).value)"
    (blur)="onTouch()"
    id="{{ label }}"
    #select
    class="form-control"
  >
  <option value="" selected="false">{{label}}</option>
    <option *ngFor="let option of options" 
      [value]="option.name"
      >
      <span *ngIf="option.flag">{{ option.flag }}</span>  {{ option.name }}
    </option>
  </select>
  <div
    *ngIf="
      controlDir &&
      controlDir.control &&
      controlDir.control?.status === 'PENDING'
    "
    class="fa fa-spinner fa-spin loader"
  ></div>
  <div
    class="text-danger"
    *ngIf="
      controlDir &&
      controlDir.control &&
      controlDir.control?.touched &&
      controlDir.control?.invalid
    "
  >
    <div *ngIf="controlDir.errors?.['required']">{{ label }} is required.</div>
  </div>
</div>
