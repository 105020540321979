<div class="privacy-policies p-5">
  <h1 class="title">BML Privacy Policies</h1>
  <div class="container">
    <div *ngFor="let policy of policies" class="policy">
      <h3 class="policy-title">{{ policy.title }}</h3>
      <div [innerHTML]="policy.description | sanitizeHtml"></div>
    </div>
  </div>
  <div class="text-right">
    <button type="button" class="btn btn-dark btn-sm" (click)="bsModalRef.hide()">Close</button>
  </div>
</div>
