import { Component, OnInit } from '@angular/core';
import { AccountService } from './account/account.service';
import { IUserToReturn } from 'src/app/shared/models/user';
import { ShoppingCartService } from './shopping-cart/shopping-cart.service';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoggingErrorService } from './logging-error/logging-error.service';
import { IAnnouncement } from './shared/models/announcement';
import { MaintenanceService } from './maintenance/maintenance.service';
import { IMaintenance } from './shared/models/maintenance';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AnnouncementComponent } from './shared/components/announcement/announcement.component';
import { AnnouncementService } from './shared/services/announcement.service';
import {v4 as uuidV4} from 'uuid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  bsModalRef?: BsModalRef;
  currentUser!: IUserToReturn;
  announcement!: IAnnouncement;
  isAnnouncementSession = false;
  maintenance!: IMaintenance;
  isMaintenanceScheduled: boolean = false; 

  public screenWidth: any;
  public screenHeight: any;

  paypalClientId = environment.paypal.clientId;

  title = 'client';
  showShopLink = false;

  currentPage: any;
  displayMenu: boolean = true;
  showAdminMenu: boolean = false;
  randomUUID = uuidV4();

  constructor(
    private logger: LoggingErrorService,
    private accountService: AccountService,
    private cartService: ShoppingCartService,
    private announcementService: AnnouncementService,
    private maintenanceService: MaintenanceService,
    private modalService: BsModalService, 
  ) {
    
  }

  ngOnInit(): void {
    this.loadShoppingCart();
    this.loadCurrentUser();
    this.getAnnouncements();
    this.getMaintenance();
  }


  loadShoppingCart() {
    const basketStringId = environment.basketStringId;
    const basketId = localStorage.getItem(basketStringId);
    if (basketId) {
      this.cartService.getBasket(basketId).subscribe(
        () => {
          //console.log('product cart initialized');
        },
        (error) => {
          this.logger.log("Cart Initialization", error)
        }
      );
    }
  }

  loadCurrentUser() {
    const tokenKey = environment.userTokenStringId;
    const token = localStorage.getItem(tokenKey);
    const helper = new JwtHelperService();
    
    if (token) {
      if(helper.isTokenExpired(token)) {
        localStorage.removeItem(tokenKey)
      }
      else {
        this.accountService
        .loadCurrentUser(token)
        .toPromise()
        .then()
        .catch((error => this.logger.log("User Initialization", error)));
      }      
    }

    const session = localStorage.getItem(environment.sessionStringId);
    if(!session) {
      localStorage.setItem(environment.sessionStringId, this.randomUUID);
    }
  }

  getAnnouncements() {
    const announcementStringId = environment.announcementStringId;
    const announcementSession = sessionStorage.getItem(announcementStringId);
    if(!announcementSession) {
      this.announcementService.getActivatedAnnouncements().subscribe((response:any) => {
        if(response[0]) {
          this.announcement = response[0];
          this.displayAnnouncementMessage();
          sessionStorage.setItem(announcementStringId, response[0].id);
        }       
      })
    }else {
      this.isAnnouncementSession = true;
    }
  }

  getMaintenance() {
    this.maintenanceService.getMaintenances().subscribe(response => {
      this.maintenance = response.filter(x => x.platformName === "Shopping" && x.activeStatusFlag === true)[0];
      if(this.maintenance) {
        this.isMaintenanceScheduled = this.maintenance.activeStatusFlag;
      }
    })
  }

  displayAnnouncementMessage() {
    setTimeout(() => {
      this.openAnnouncementModal(this.announcement)
    }, 60000);
  }

  openAnnouncementModal(announcement: IAnnouncement) {
    const initialState = {
      initialState: {
        announcement: announcement,
      },
      class: 'modal-lg modal-dialog-centered'
    };

    this.bsModalRef = this.modalService.show(AnnouncementComponent, initialState);
  }

  onActivate(event: any) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
