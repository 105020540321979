<div class="form-label-group">
  <input
    [ngClass]="
      controlDir && controlDir.control && controlDir.control.touched
        ? !controlDir.control.valid
          ? 'is-invalid'
          : 'is-valid'
        : ''
    "
    [type]="type"
    (input)="onChange($any($event.target).value)"
    (blur)="onTouch()"
    id="{{ label }}"
    #input
    class="form-control"
    placeholder="{{ label }}"
    readonly="{{ readonly }}"
  />
  <div
    *ngIf="
      controlDir &&
      controlDir.control &&
      controlDir.control.status === 'PENDING'
    "
    class="fa fa-spinner fa-spin loader"
  ></div>
  <label [for]="label">{{ label }}</label>
  <div
    class="text-danger"
    *ngIf="
      controlDir &&
      controlDir.control &&
      controlDir.control.touched &&
      controlDir.control.invalid
    "
  >
    <div *ngIf="controlDir.errors?.['required']">
      <strong>This field is required.</strong>
    </div>
    <div *ngIf="controlDir.errors?.['pattern']">
      <strong>{{ label }} is invalid.</strong>
    </div>
    <div *ngIf="controlDir.errors?.['minlength']">
      <strong
        >{{ label }} should be minimum
        {{ controlDir.errors?.['minlength'].requiredLength }} characters.</strong
      >
    </div>
    <div *ngIf="controlDir.errors?.['maxlength']">
      <strong
        >{{ label }} should have a maximum of
        {{ controlDir.errors?.['maxlength'].requiredLength }} characters.</strong
      >
    </div>
    <div *ngIf="controlDir.errors?.['max']">
      <strong>{{ label }} maximum is 100.</strong>
    </div>
    <div *ngIf="controlDir.errors?.['min']">
      <strong
        >{{ label }} minimum is
        {{ controlDir.errors?.['min'].requiredLength }}.</strong
      >
    </div>
  </div>
  <div
    *ngIf="
      controlDir.control?.dirty &&
      bmlFormName?.invalid &&
      bmlFormName?.errors?.passwordShouldMatch
    "
    class="text-danger"
  >
    <strong>Passwords don't match.</strong>
  </div>
  <div
    class="text-danger"
    *ngIf="
      controlDir &&
      controlDir.control &&
      controlDir.control?.dirty &&
      controlDir.control?.invalid
    "
  >
    <div *ngIf="controlDir.errors?.['emailExists']">
      <strong>Email address is in use.</strong>
    </div>
    <div *ngIf="controlDir.errors?.['usernameExists']">
      <strong>Username taken. Try another one</strong>
    </div>
  </div>
</div>
