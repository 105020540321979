import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';
import { IPolicy } from 'src/app/shared/models/policy';
import { PrivacyAndPolicyService } from './privacy-and-policy.service';

@Component({
  selector: 'bml-privacy-and-policy',
  templateUrl: './privacy-and-policy.component.html',
  styleUrls: ['./privacy-and-policy.component.scss'],
})
export class PrivacyAndPolicyComponent implements OnInit {
  policies: IPolicy[] = [];

  constructor(
    private service: PrivacyAndPolicyService,
    public bsModalRef: BsModalRef,
    private logger: LoggingErrorService
  ) {}

  ngOnInit(): void {
    this.getPolicies();
  }

  getPolicies() {
    this.service.getPrivacyPolicies().subscribe(
      (response) => {
        this.policies = response;
      },
      (error) => {
        this.logger.log(error, 'privacy-and-policy');
      }
    );
  }
}
