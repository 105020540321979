import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser, IUserAddress, IUserToReturn } from 'src/app/shared/models/user';
import { UserAddressService } from './user-address.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/account/account.service';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';

@Component({
  selector: 'bml-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.scss'],
})
export class UserAddressComponent implements OnInit {
  addresses: IUserAddress[] = [];
  currentUser$!: Observable<IUserToReturn>;
  isDeleted = false;

  @Input() componentName!: string;
  @Input() isAdmin: boolean = false;

  constructor(
    private addressService: UserAddressService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private logger: LoggingErrorService
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.accountService.currentUser$;
    this.getUserAddresses();
  }

  getUserAddresses() {
    this.accountService.getUserAddresses().subscribe(
      (response) => {
        this.addresses = response;
      },
      (error) => {
        this.logger.log(error, this.componentName);
      }
    );
  }

  deleteUserAddress(id: string) {
    const address = this.addresses.find((x) => (x.id = id));
    if (confirm('Are you sure you want to delete : ' + address?.addressType)) {
      if (address) {
        let index = this.addresses.indexOf(address);
        this.addresses.splice(index, 1);

        this.addressService.deleteUserAddress(id).subscribe(
          () => {
            this.isDeleted = true;
            this.toastr.success('Address removed sucessfully.');
          },
          (error) => {
            this.addresses.splice(index, 0, address);
            this.logger.log(error, this.componentName);
          }
        );
      }
    }
  }
}
