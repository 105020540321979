<div class="admin" [class.admin-container]="isAdmin">
  <div class="bml-default-jumbotron" *ngIf="!isCheckout">
    <h1 *ngIf="userAddress?.id">Update your {{ userAddress.addressType }}</h1>
    <h1 *ngIf="!userAddress?.id">Create a new Address</h1>
  </div>
  <div class="d-flex justify-content-center mt-5">
    <div class="col-md-6" [class.col-md-12]="isCheckout">
      <form [formGroup]="userAddressForm" (ngSubmit)="onSubmit()">
        <ul class="alert alert-danger" *ngIf="errors">
          <li class="ml-3" *ngFor="let error of errors">{{ error }}</li>
        </ul>

        <input formControlName="id" hidden *ngIf="userAddress?.id" />
        <bml-text-input
          formControlName="addressLine1"
          [label]="'Address Line 1'"
        ></bml-text-input>
        <bml-text-input
          formControlName="addressLine2"
          [label]="'Address Line 2'"
        ></bml-text-input>
        <bml-text-input
          formControlName="city"
          [label]="'City'"
        ></bml-text-input>
        <bml-text-input
          formControlName="province"
          [label]="'Province/State'"
        ></bml-text-input>
        <bml-text-input
          formControlName="postalCode"
          [label]="'Postal Code'"
        ></bml-text-input>
        <div class="form-group">
          <label>Country *</label>
          <select formControlName="country" class="form-control">
            <option
              *ngFor="let country of countriesList"
              [value]="country.name"
              [selected]="userAddress ? userAddress.country === country.name: ''"
            >
              {{ country.name }}
            </option>
          </select>
          <div
            class="alert alert-danger"
            style="border-radius: 0"
            *ngIf="country?.touched && country?.invalid"
          >
            <div *ngIf="country?.errors?.['required']">Country is required.</div>
          </div>
        </div>
        <div class="form-group mt-3">
          <label>Address Type *</label>
          <select formControlName="addressType" class="form-control">
            <option
              *ngFor="let address of addressTypes"
              [value]="address.name"
              [selected]="userAddress ? userAddress.addressType === address.name: ''"
            >
              {{ address.name }}
            </option>
          </select>
          <div
            class="alert alert-danger"
            style="border-radius: 0"
            *ngIf="address?.touched && address?.invalid"
          >
            <div *ngIf="address?.errors?.['required']">
              Address Type is required.
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <bml-select-country-with-flag-code-input
              [options]="countriesList"
              formControlName="countryCode"
              [label]="'* Dial Code'"
            ></bml-select-country-with-flag-code-input>
          </div>
          <div class="col-9">
            <bml-text-input
              bmlPhoneMask
              formControlName="phoneNumber"
              [label]="'Phone Number *'"
            ></bml-text-input>
          </div>
        </div>
        <div class="d-grid gap-2">
          <button
            [disabled]="userAddressForm.invalid"
            class="btn btn-lg bml-custom-submit-btn btn-block"
            type="submit"
            pb-role="submit"
            *ngIf="userAddress?.id"
          >
            Update
          </button>
          <button
            [disabled]="userAddressForm.invalid"
            class="btn btn-lg bml-custom-submit-btn btn-block"
            type="submit"
            pb-role="submit"
            *ngIf="!userAddress?.id"
          >
            Submit
          </button>
        </div>        
      </form>
      <hr />
      <ng-container *ngIf="!isCheckout">
        <div
          class="btn btn-warning submit mb-2"
          routerLink="/{{ componentName }}/profile"
        >
          <i class="fa fa-backward"></i> Back to Profile
        </div>
        <div
          class="btn btn-outline-warning submit mb-2 "
          routerLink="/{{ componentName }}/addresses"
        >
          <i class="fa fa-backward"></i> Back to address dashboard
        </div>
      </ng-container>
    </div>
  </div>
</div>
