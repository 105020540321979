import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {v4 as uuidv4} from 'uuid';
import {
  IProduct,
  IProductColor,
  IProductDiscount,
  IProductPagination,
  IProductReview,
  ProductClickstream,
  ProductPagination,
  ProductParams
} from '../shared/models/product';
import {IGenreProducts} from '../shared/models/product-genre';
import {IProductProductStyle} from '../shared/models/product-style';


@Injectable({
  providedIn: 'root'
})
export class ShopService {
  baseUrl = environment.apiUrl;

  products: IProduct[] = [];
  productParams = new ProductParams();
  productPagination = new ProductPagination();

  relatedProducts: IProduct[] = [];
  relatedProductParams = new ProductParams();
  relatedProductPagination = new ProductPagination();

  styleRelatedProducts: IProduct[] = [];
  styleRelatedProductParams = new ProductParams();
  styleRelatedProductPagination = new ProductPagination();

  discountProducts: IProduct[] = [];
  discountProductParams = new ProductParams();
  discountProductPagination = new ProductPagination();

  searchResultProducts: IProduct[] = [];
  searchResultProductParams = new ProductParams();
  searchResultProductPagination = new ProductPagination();

  clickstreamProducts: IProduct[] = [];
  clickstreamProductParams = new ProductParams();
  clickstreamProductPagination = new ProductPagination();

  productColors: IProductColor[] = [];
  activatedDiscounts: IProductDiscount[] = [];

  productStyles: IProductProductStyle[] = []

  constructor(private http: HttpClient) {
  }

  getProducts(useCache: boolean) {
    if (!useCache) {
      this.products = [];
    }

    if (this.products.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.products.length / this.productParams.pageSize);

      if (this.productParams.pageNumber <= pagesReceived) {
        this.productPagination.data = this.products
          .slice(
            (this.productParams.pageNumber - 1) * this.productParams.pageSize,
            this.productParams.pageNumber * this.productParams.pageSize
          );

        return of(this.productPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.productParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'products', {observe: 'response', params})
      .pipe(
        map(response => {
          if (response.body) {
            this.products = [...this.products, ...response.body.data];
            this.productPagination = response.body;
          }

          return this.productPagination;
        })
      );
  }

  setProductsParams(params: ProductParams) {
    this.productParams = params;
  }

  getProductsParams() {
    return this.productParams;
  }

  getProduct(id: string) {
    const product = this.products.find(s => s.id === id);
    if (product) {
      return of(product);
    }
    return this.http.get<IProduct>(this.baseUrl + 'products/' + id);
  }

  searchProducts(term: string) {
    return this.http.get<IProduct[]>(this.baseUrl + 'products/products-search/' + term);
  }

  setRelatedProductsParams(params: ProductParams) {
    this.relatedProductParams = params;
  }

  getRelatedProductsParams() {
    return this.relatedProductParams;
  }

  getRelatedProducts(id: string, useCache: boolean) {
    if (!useCache) {
      this.relatedProducts = [];
    }

    if (this.relatedProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.relatedProducts.length / this.relatedProductParams.pageSize);

      if (this.relatedProductParams.pageNumber <= pagesReceived) {
        this.relatedProductPagination.data = this.relatedProducts
          .slice(
            (this.relatedProductParams.pageNumber - 1) * this.relatedProductParams.pageSize,
            this.relatedProductParams.pageNumber * this.relatedProductParams.pageSize
          );

        return of(this.relatedProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.relatedProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'products/relatedProducts/' + id, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.relatedProducts = response.body ? [...this.relatedProducts, ...response.body.data] : this.relatedProducts;
          this.relatedProductPagination = response.body ? response.body : new ProductPagination();
          return this.relatedProductPagination;
        }),
        catchError(err => of([]))
      );
  }

  setStyleRelatedProductsParams(params: ProductParams) {
    this.styleRelatedProductParams = params;
  }

  getStyleRelatedProductsParams() {
    return this.styleRelatedProductParams;
  }

  getStyleRelatedProducts(productId: string, useCache: boolean) {
    if (!useCache) {
      this.styleRelatedProducts = [];
    }

    if (this.styleRelatedProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.styleRelatedProducts.length / this.styleRelatedProductParams.pageSize);

      if (this.styleRelatedProductParams.pageNumber <= pagesReceived) {
        this.styleRelatedProductPagination.data = this.styleRelatedProducts
          .slice(
            (this.styleRelatedProductParams.pageNumber - 1) * this.styleRelatedProductParams.pageSize,
            this.styleRelatedProductParams.pageNumber * this.styleRelatedProductParams.pageSize
          );

        return of(this.styleRelatedProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.styleRelatedProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'products/relatedProducts/product-style/' + productId, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.styleRelatedProducts = response.body ? [...this.styleRelatedProducts, ...response.body.data] : this.styleRelatedProducts;
          this.styleRelatedProductPagination = response.body ? response.body : new ProductPagination();
          return this.styleRelatedProductPagination;
        }),
        catchError(err => of([]))
      );
  }

  setDiscountProductsParams(params: ProductParams) {
    this.discountProductParams = params;
  }

  getDiscountProductsParams() {
    return this.discountProductParams;
  }

  getDiscountProducts(useCache: boolean) {
    if (!useCache) {
      this.discountProducts = [];
    }

    if (this.discountProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.discountProducts.length / this.discountProductParams.pageSize);

      if (this.discountProductParams.pageNumber <= pagesReceived) {
        this.discountProductPagination.data = this.discountProducts
          .slice(
            (this.discountProductParams.pageNumber - 1) * this.discountProductParams.pageSize,
            this.discountProductParams.pageNumber * this.discountProductParams.pageSize
          );

        return of(this.discountProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.discountProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'products/discountProducts', {observe: 'response', params})
      .pipe(
        map(response => {
          this.discountProducts = response.body ? [...this.discountProducts, ...response.body.data] : this.discountProducts;
          this.discountProductPagination = response.body ? response.body : new ProductPagination();
          return this.discountProductPagination;
        }),
        catchError(err => of([]))
      );
  }

  createProductClickstream(productId: string) {
    let sessionId = sessionStorage.getItem(environment.sessionStringId);
    if (!sessionId) {
      sessionId = uuidv4();
      sessionStorage.setItem(environment.sessionStringId, sessionId);
    }

    let clickstream = new ProductClickstream();

    clickstream.sessionId = sessionId;
    clickstream.productId = productId;

    return this.http.post<IProductReview>(this.baseUrl + 'productClickstreams', clickstream);
  }

  setClickstreamProductsParams(params: ProductParams) {
    this.clickstreamProductParams = params;
  }

  getClickstreamProductsParams() {
    return this.clickstreamProductParams;
  }

  getClickstreamsSessionViewedProducts(useCache: boolean) {
    let sessionId = sessionStorage.getItem(environment.sessionStringId);

    if (!useCache) {
      this.clickstreamProducts = [];
    }

    if (this.clickstreamProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.clickstreamProducts.length / this.clickstreamProductParams.pageSize);

      if (this.clickstreamProductParams.pageNumber <= pagesReceived) {
        this.clickstreamProductPagination.data = this.clickstreamProducts
          .slice(
            (this.clickstreamProductParams.pageNumber - 1) * this.clickstreamProductParams.pageSize,
            this.clickstreamProductParams.pageNumber * this.clickstreamProductParams.pageSize
          );

        return of(this.clickstreamProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.clickstreamProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'productClickstreams/' + sessionId, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.clickstreamProducts = response.body ? [...this.clickstreamProducts, ...response.body.data] : this.clickstreamProducts;
          this.clickstreamProductPagination = response.body ? response.body : new ProductPagination();
          return this.clickstreamProductPagination;
        }),
        catchError(err => of([]))
      );
  } 

  getActivatedProductDiscounts(useCache: boolean) {
    if (!useCache) {
      this.activatedDiscounts = [];
    }

    if (this.activatedDiscounts.length > 0 && useCache) {
      return of(this.activatedDiscounts);
    }

    return this.http.get<IProductDiscount[]>(this.baseUrl + 'productDiscounts/activatedDiscounts')
      .pipe(
        map((response) => {
          this.activatedDiscounts = response;
          return this.activatedDiscounts;
        })
      );
  }

  getProductColors(productId: string) {
    return this.http.get<IProductColor[]>(this.baseUrl + 'productColors/product/' + productId)
      .pipe(
        map((response) => {
          this.productColors = response;
          return this.productColors;
        })
      );
  }

  setSearchResultProductsParams(params: ProductParams) {
    this.searchResultProductParams = params;
  }

  getSearchResultProductsParams() {
    return this.searchResultProductParams;
  }

  getProductsSearchResult(searchTerm: string, useCache: boolean) {
    if (!useCache) {
      this.searchResultProducts = [];
    }

    if (this.searchResultProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.searchResultProducts.length / this.searchResultProductParams.pageSize);

      if (this.searchResultProductParams.pageNumber <= pagesReceived) {
        this.searchResultProductPagination.data = this.searchResultProducts
          .slice(
            (this.searchResultProductParams.pageNumber - 1) * this.searchResultProductParams.pageSize,
            this.searchResultProductParams.pageNumber * this.searchResultProductParams.pageSize
          );

        return of(this.searchResultProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.searchResultProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'products/products-search/' + searchTerm, {observe: 'response', params})
      .pipe(
        map(response => {
          if (response.body) {
            this.searchResultProducts = [...this.searchResultProducts, ...response.body.data];
            this.searchResultProductPagination = response.body;
          }

          return this.searchResultProductPagination;
        })
      )
  }

  private setParamsMethod(params: HttpParams, productParams: ProductParams) {

    if (productParams.productCategoryId) {
      params = params.append('productCategoryId', productParams.productCategoryId.toString());
    }
    if (productParams.productSubcategoryId) {
      params = params.append('productSubcategoryId', productParams.productSubcategoryId.toString());
    }
    if (productParams.productBrandId) {
      params = params.append('productBrandId', productParams.productBrandId.toString());
    }
    if (productParams.productGenre) {
      params = params.append('productGenre', productParams.productGenre.toString());
    }
    if (productParams.productStyleId) {
      params = params.append('productStyleId', productParams.productStyleId.toString());
    }
    if (productParams.productId) {
      params = params.append('productId', productParams.productId.toString());
    }

    if (productParams.search) {
      params = params.append('search', productParams.search);
    }

    if (productParams.pageNumber) {
      params = params.append('pageIndex', productParams.pageNumber.toString());
      params = params.append('pageNumber', productParams.pageNumber.toString());
    }

    if (productParams.pageSize) {
      params = params.append('pageSize', productParams.pageSize.toString());
    }

    params = params.append('sort', productParams.sort);
    
    return params;
  }
}
