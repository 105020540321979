import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'bml-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss'],
})
export class RecaptchaComponent implements OnInit {
  constructor(private recaptchaV3Service: ReCaptchaV3Service) {}

  ngOnInit(): void {}

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
}
