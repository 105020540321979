import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IProduct, IProductGenre, IProductPagination, ProductPagination, ProductParams } from 'src/app/shared/models/product';
import { environment } from 'src/environments/environment';
import { IProductBrand } from '../../shared/models/product-brand';
import { IProductSubcategory } from '../../shared/models/product-subcategory';
import { IProductCategory } from '../../shared/models/product-category';
import { IProductProductStyle, IProductStyle } from '../../shared/models/product-style';
import { of } from 'rxjs';
import { IGenreProducts } from 'src/app/shared/models/product-genre';

@Injectable({
  providedIn: 'root'
})
export class GenreService {
  baseUrl = environment.apiUrl;
  productGenres: IProductGenre[] = [];

  genreProducts: IProduct[] = [];
  genreProductParams = new ProductParams();
  genreProductPagination = new ProductPagination();

  kidsGenreProducts: IProduct[] = [];
  kidsGenreProductParams = new ProductParams();
  kidsGenreProductPagination = new ProductPagination();

  genresAndProducts: IGenreProducts[] = [];

  constructor(private http: HttpClient) { }

  getGenres() {
    return this.http.get<IProductGenre[]>(this.baseUrl + 'productGenres/genres')
    .pipe(
      map((response) => {
        this.productGenres = response;
        return this.productGenres;
      })
    );;
  }

  getGenreBrands(genre: string, categoryId: string) {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId);
    
    return this.http.get<IProductBrand[]>(this.baseUrl + 'productGenres/genreBrands/' + genre, {params: params})
    .pipe(
      map((response) => {
        return response;
      })
    );;
  }

  getGenreSubcategories(genre: string, categoryId: string) {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId);

    return this.http.get<IProductSubcategory[]>(this.baseUrl + 'productGenres/genreSubcategories/' + genre, {params: params})
    .pipe(
      map((response) => {
        return response;
      })
    );;
  }

  getGenreSubcategoryBrands(genre: string, categoryId: string, subcategoryId: string) {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId);
    params = params.append('subcategoryId', subcategoryId);

    return this.http.get<IProductBrand[]>(this.baseUrl + 'productGenres/genreSubcategoryBrands/' + genre, {params: params})
    .pipe(
      map((response) => {
        return response;
      })
    );;
  }

  getGenreSubcategoryProductStyles(genre: string, categoryId: string, subcategoryId: string) {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId);
    params = params.append('subcategoryId', subcategoryId);

    return this.http.get<IProductProductStyle[]>(this.baseUrl + 'productGenres/genreSubcategoryProductStyles/' + genre, {params: params})
    .pipe(
      map((response) => {
        return response;
      })
    );;
  }

  getGenreSubcategoryProductStyleBrands(genre: string, categoryId: string, subcategoryId: string, styleId: string) {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId);
    params = params.append('subcategoryId', subcategoryId);
    params = params.append('styleId', styleId);

    return this.http.get<IProductBrand[]>(this.baseUrl + 'productGenres/genreSubcategoryStyleBrands/' + genre, {params: params})
    .pipe(
      map((response) => {
        return response;
      })
    );;
  }

  getGenreProductStyles(genre: string, categoryId: string) {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId);

    return this.http.get<IProductProductStyle[]>(this.baseUrl + 'productGenres/genreProductStyles/' + genre, {params: params})
    .pipe(
      map((response) => {
        return response;
      })
    );;
  }

  getGenreCategories(genre: string) {
    return this.http.get<IProductCategory[]>(this.baseUrl + 'productGenres/genreCategories/' + genre)
    .pipe(
      map((response) => {
        return response;
      })
    );;
  }

  getGenresAndTheirProducts(useCache: boolean) {
    if (!useCache) {
      this.genresAndProducts = [];
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.genreProductParams);
    return this.http.get<IGenreProducts[]>(this.baseUrl + 'productGenres/genres/products', {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.genresAndProducts = response.body ? [...this.genresAndProducts, ...response.body] : this.genresAndProducts;
          return this.genresAndProducts;
        })
      );
  }

  setGenreProductsParams(params: ProductParams) {
    this.genreProductParams = params;
  }

  getGenreProductsParams() {
    return this.genreProductParams;
  }

  getGenreProducts(genre: string, useCache: boolean) {
    if (!useCache) {
      this.genreProducts = [];
    }

    if (this.genreProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.genreProducts.length / this.genreProductParams.pageSize);

      if (this.genreProductParams.pageNumber <= pagesReceived) {
        this.genreProductPagination.data = this.genreProducts
          .slice(
            (this.genreProductParams.pageNumber - 1) * this.genreProductParams.pageSize,
            this.genreProductParams.pageNumber * this.genreProductParams.pageSize
          );

        return of(this.genreProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.genreProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'productGenres/genreProducts/' + genre, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.genreProducts = response.body ? [...this.genreProducts, ...response.body.data] : this.genreProducts;
          this.genreProductPagination = response.body ? response.body : new ProductPagination();
          return this.genreProductPagination;
        })
      );
  }

  setKidsGenreProductsParams(params: ProductParams) {
    this.kidsGenreProductParams = params;
  }

  getKidsGenreProductsParams() {
    return this.kidsGenreProductParams;
  }

  getKidsGenreProducts(useCache: boolean) {
    if (!useCache) {
      this.kidsGenreProducts = [];
    }

    if (this.kidsGenreProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.kidsGenreProducts.length / this.kidsGenreProductParams.pageSize);

      if (this.kidsGenreProductParams.pageNumber <= pagesReceived) {
        this.kidsGenreProductPagination.data = this.kidsGenreProducts
          .slice(
            (this.kidsGenreProductParams.pageNumber - 1) * this.kidsGenreProductParams.pageSize,
            this.kidsGenreProductParams.pageNumber * this.kidsGenreProductParams.pageSize
          );

        return of(this.kidsGenreProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.kidsGenreProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'productGenres/genreProducts/kids', {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.kidsGenreProducts = response.body ? [...this.kidsGenreProducts, ...response.body.data] : this.kidsGenreProducts;
          this.kidsGenreProductPagination = response.body ? response.body : new ProductPagination();
          return this.kidsGenreProductPagination;
        })
      );
  }

  private setParamsMethod(params: HttpParams, productParams: ProductParams) {

    if (productParams.productCategoryId) {
      params = params.append('productCategoryId', productParams.productCategoryId.toString());
    }
    if (productParams.productSubcategoryId) {
      params = params.append('productSubcategoryId', productParams.productSubcategoryId.toString());
    }
    if (productParams.productBrandId) {
      params = params.append('productBrandId', productParams.productBrandId.toString());
    }
    if (productParams.productGenre) {
      params = params.append('productGenre', productParams.productGenre.toString());
    }
    if (productParams.productStyleId) {
      params = params.append('productStyleId', productParams.productStyleId.toString());
    }
    if (productParams.productId) {
      params = params.append('productId', productParams.productId.toString());
    }

    if (productParams.search) {
      params = params.append('search', productParams.search);
    }

    params = params.append('sort', productParams.sort);
    params = params.append('pageIndex', productParams.pageNumber.toString());
    params = params.append('pageSize', productParams.pageSize.toString());

    return params;
  }
  
}
