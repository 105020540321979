<div class="modal-body announcement" *ngIf="announcement">
    <button type="button" class="close" data-dismiss="alert" (click)="bsModalRef.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h1 class="title">{{announcement.title}}</h1>
    <div class="message" >
        <h3 class="subtitle">{{announcement.subtitle}}</h3>
        <div
          *ngIf="announcement.message"
          class="message"
          [innerHTML]="announcement.message | sanitizeHtml"
        ></div>    
    </div>
</div>


