import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';
import { IUserAddress } from 'src/app/shared/models/user';
import { AddressType } from 'src/app/shared/static-data/addressType';
import { CountriesList } from 'src/app/shared/static-data/countries-list';
import { CountriesWithDialCode } from 'src/app/shared/static-data/country-with-dial-code';
import { CountriesWithDialCodeAndFalgs } from 'src/app/shared/static-data/country-with-dialcode-and-flags';
import { UserAddressService } from '../user-address.service';

@Component({
  selector: 'bml-user-address-form',
  templateUrl: './user-address-form.component.html',
  styleUrls: ['./user-address-form.component.scss'],
})
export class UserAddressFormComponent implements OnInit {
  userAddressForm!: UntypedFormGroup;
  userAddress!: IUserAddress;
  addressTypes = AddressType.address;
  countriesList = CountriesWithDialCodeAndFalgs.data;
  errors!: string[];

  @Input() componentName!: string;
  @Input() isAdmin: boolean = false;
  @Input() isCheckout: boolean = false;
  @Input() formId: any;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private userAddressService: UserAddressService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggingErrorService
  ) {}

  ngOnInit(): void {
    this.createUserAddressForm();
    this.getUserAddressFormValues();
  }

  createUserAddressForm() {
    this.userAddressForm = this.fb.group({
      id: [Validators.required],
      addressLine1: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      addressLine2: [null, [Validators.maxLength(50), Validators.minLength(2)]],
      city: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      province: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      postalCode: [null, [Validators.maxLength(15), Validators.minLength(2)]],
      country: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      addressType: [
        null,
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
        ],
      ],
      countryCode: ['', Validators.required],
      phoneNumber: [null, [Validators.maxLength(18), Validators.minLength(8)]],
    });
  }

  getUserAddressFormValues() {
    const id = this.route.snapshot.paramMap.get('id');
    this.accountService.getUserProfile().subscribe(
      (response) => {
        if (response) {
          const address = response.customerAddresses.find((x) => x.id === id);
          if (address) {
            this.userAddress = address;
            this.userAddressForm.patchValue(address);
          }
        }
      },
      (error) => {
        this.logger.log(error, this.componentName);
      }
    );
  }

  onSubmit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id || this.formId) {
      this.accountService
        .UpdateUserAddresses(this.userAddressForm.value)
        .subscribe(
          () => {
            if (this.isCheckout) {
              this.router.navigateByUrl('/checkout');
            } else {
              this.router.navigateByUrl('/' + this.componentName + '/profile');
            }
          },
          (error) => {
            this.errors = error.errors;
            this.logger.log(error, this.componentName);
          }
        );
    } else {
      this.userAddressService
        .createUserAddress(this.userAddressForm.value)
        .subscribe(
          () => {
            if (this.isCheckout) {
              this.router.navigateByUrl('/checkout');
            } else {
              this.router.navigateByUrl(
                '/' + this.componentName + '/addresses'
              );
            }
          },
          (error) => {
            this.logger.log(error, this.componentName);
          }
        );
    }
  }

  get address() {
    return this.userAddressForm.get('addressType');
  }

  get country() {
    return this.userAddressForm.get('country');
  }
}
