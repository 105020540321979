import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IProduct, IProductPagination, ProductPagination, ProductParams} from 'src/app/shared/models/product';
import { IOfferProductBrand, IOfferProductSubcatgory, ISpecialOffer, ISpecialOfferProductBrand } from 'src/app/shared/models/special-offer';
import { environment } from 'src/environments/environment';
import { ISpecialOfferProductSubcategory } from '../../shared/models/special-offer';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesOfferService {
  baseUrl = environment.apiUrl;

  specialOfferProducts: IProduct[] = [];
  specialOfferProductParams = new ProductParams();
  specialOfferProductPagination = new ProductPagination();

  subcategoryOfferProducts: IProduct[] = [];
  subcategoryOfferProductParams = new ProductParams();
  subcategoryOfferProductPagination = new ProductPagination();

  constructor(private http: HttpClient) { }

  getSpecialOffers() {
    return this.http.get<ISpecialOffer[]>(this.baseUrl + "specialOffers/activeSpecialOffers")
  }

  checkExpiredSpecialOffer(offer: ISpecialOffer) {
    return this.http.put(this.baseUrl + "specialOffers/offerDeActivation/" + offer.id, offer);
  }

  getSpecialOfferProducts() {
    return this.http.get<IProduct[]>(this.baseUrl + "specialOfferProducts/products")
  }

  getSpecialOfferProductBrands() {
    return this.http.get<IOfferProductBrand[]>(this.baseUrl + "specialOfferProductBrands/activeProductBrandOffers")
  }

  getSpecialOfferProductBrand(offerId: string) { 
    return this.http.get<ISpecialOfferProductBrand>(this.baseUrl + 'specialOfferProductBrands/users/' + offerId)
      .pipe(
        map(response =>{
            return response;
        })
      );
  }

  getSpecialOfferProductSubcategories() {
    return this.http.get<IOfferProductSubcatgory[]>(this.baseUrl + "specialOfferProductsubcategories/activeProductSubcategoryOffers")
  }

  getSpecialOfferProductSubcategory(offerId: string) { 
    return this.http.get<ISpecialOfferProductSubcategory>(this.baseUrl + 'specialOfferProductsubcategories/users/' + offerId)
      .pipe(
        map(response =>{
            return response;
        })
      );
  }

  setSpecialOfferProductsParams(params: ProductParams) {
    this.specialOfferProductParams = params;
  }

  getSpecialOfferProductsParams() {
    return this.specialOfferProductParams;
  }

  getSpecialOfferProductBrandById(offerId: string, useCache: boolean) {
    if (!useCache) {
      this.specialOfferProducts = [];
    }

    if (this.specialOfferProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.specialOfferProducts.length / this.specialOfferProductParams.pageSize);

      if (this.specialOfferProductParams.pageNumber <= pagesReceived) {
        this.specialOfferProductPagination.data = this.specialOfferProducts
          .slice(
            (this.specialOfferProductParams.pageNumber - 1) * this.specialOfferProductParams.pageSize,
            this.specialOfferProductParams.pageNumber * this.specialOfferProductParams.pageSize
          );

        return of(this.specialOfferProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.specialOfferProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'specialOfferProductBrands/active/' + offerId, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.specialOfferProducts = response.body ? [...this.specialOfferProducts, ...response.body.data] : this.specialOfferProducts;
          this.specialOfferProductPagination = response.body ? response.body : new ProductPagination();
          return this.specialOfferProductPagination;
        })
      );
  }

  setSpecialOfferProductSubcategoryParams(params: ProductParams) {
    this.subcategoryOfferProductParams = params;
  }

  getSpecialOfferProductSubcategoryParams() {
    return this.subcategoryOfferProductParams;
  }

  getSpecialOfferProductSubcategoryById(offerId: string, useCache: boolean) {
    if (!useCache) {
      this.subcategoryOfferProducts = [];
    }

    if (this.subcategoryOfferProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.subcategoryOfferProducts.length / this.subcategoryOfferProductParams.pageSize);

      if (this.subcategoryOfferProductParams.pageNumber <= pagesReceived) {
        this.subcategoryOfferProductPagination.data = this.subcategoryOfferProducts
          .slice(
            (this.subcategoryOfferProductParams.pageNumber - 1) * this.subcategoryOfferProductParams.pageSize,
            this.subcategoryOfferProductParams.pageNumber * this.subcategoryOfferProductParams.pageSize
          );

        return of(this.subcategoryOfferProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.subcategoryOfferProductParams);

    return this.http.get<IProductPagination>(this.baseUrl + 'specialOfferProductSubcategories/active/' + offerId, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.subcategoryOfferProducts = response.body ? [...this.subcategoryOfferProducts, ...response.body.data] : this.subcategoryOfferProducts;
          this.subcategoryOfferProductPagination = response.body ? response.body : new ProductPagination();
          return this.subcategoryOfferProductPagination;
        })
      );
  }

  private setParamsMethod(params: HttpParams, productParams: ProductParams) {

    if (productParams.productCategoryId) {
      params = params.append('productCategoryId', productParams.productCategoryId.toString());
    }
    if (productParams.productSubcategoryId) {
      params = params.append('productSubcategoryId', productParams.productSubcategoryId.toString());
    }
    if (productParams.productBrandId) {
      params = params.append('productBrandId', productParams.productBrandId.toString());
    }
    if (productParams.productGenre) {
      params = params.append('productGenre', productParams.productGenre.toString());
    }
    if (productParams.productStyleId) {
      params = params.append('productStyleId', productParams.productStyleId.toString());
    }
    if (productParams.productId) {
      params = params.append('productId', productParams.productId.toString());
    }

    if (productParams.search) {
      params = params.append('search', productParams.search);
    }

    params = params.append('sort', productParams.sort);
    params = params.append('pageIndex', productParams.pageNumber.toString());
    params = params.append('pageSize', productParams.pageSize.toString());

    return params;
  }
}
