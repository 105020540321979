<div class="editor">
    <label>{{label}}</label>
    <ckeditor 
        [ngClass]="(controlDir && controlDir.control && controlDir.control.touched) 
        ? (!controlDir.control.valid ? 'is-invalid': 'is-valid') : ''"
        debounce="500"
        (input)="onChange($any($event.target).value)"
        (blur)="onTouch()"
        id="{{label}}"
        #texteditor
    >    
    </ckeditor>
    <div class="invalid-feedback" *ngIf="(controlDir && controlDir.control 
            && controlDir.control?.touched && controlDir.control.invalid)"
        >
        <div *ngIf="controlDir.errors?.['required']">{{label}} is required.</div>
        <div *ngIf="controlDir.errors?.['minlength']">{{label}} should be minimum {{controlDir.errors?.['minlength'].requiredLength}} characters.</div>
        <div *ngIf="controlDir.errors?.['maxlength']">{{label}} should have a maximum of {{controlDir.errors?.['maxlength'].requiredLength}} characters.</div>
    </div> 
</div>

