<div class="features">
  <!-- <div class="row">
    <div class="col-6 col-sm-6 col-lg-3">
      <div class="bml-card" routerLink="/shop/products/top-brands">
        <h1 class="feature-name">Top Brands</h1>
      </div>
    </div>
    <div class="col-6 col-sm-6 col-lg-3">
      <div class="bml-card" routerLink="/shop/products/best-sellers">
        <h1 class="feature-name">Best Sellers</h1>
      </div>
    </div>
    <div class="col-6 col-sm-6 col-lg-3">
      <div class="bml-card" routerLink="/shop/products/african-style">
        <h1 class="feature-name">African Style</h1>
      </div>
    </div>
    <div class="col-6 col-sm-6 col-lg-3">
      <div class="bml-card" routerLink="/shop/products/sales-and-deals">
        <h1 class="feature-name">Best Deals</h1>
      </div>
    </div>
  </div> -->
  
  <div class="row">
    <div class="col-12 col-md-6 feature-col">
      <div class="content" routerLink="/shop/products/departments/genres/men">
        <div class="image men-genre">
          <div class="collection">
            MEN
          </div>
        </div>      
      </div>
    </div>
    <div class="col-12 col-md-6 feature-col">
      <div class="content" routerLink="/shop/products/departments/genres/women">
        <div class="image women-genre">
          <div class="collection">
            WOMEN
          </div> 
        </div>               
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4 feature-col">
      <div class="content" routerLink="/shop/products/departments/genres/kids">
        <div class="image kids-genre">
          <div class="collection">
            KIDS
          </div> 
        </div>      
      </div>
    </div>
    <div class="col-12 col-md-4 feature-col">
      <div class="content" routerLink="/shop/products">
        <div class="image shop-all">
          <div class="collection">
            SHOP
          </div> 
        </div>       
      </div>
    </div>
    <div class="col-12 col-md-4 feature-col">
      <div class="content" routerLink="/shop/products/sales-and-deals">
        <div class="image deal-genre">
          <div class="collection">
            DEALS
          </div> 
        </div>              
      </div>
    </div>
  </div>
</div>
