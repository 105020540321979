import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IProduct, IProductPagination, ProductPagination, ProductParams } from 'src/app/shared/models/product';
import {
  IProductBrand,
  BrandPagination,
  IBrandPagination,
  ProductBrandParams,
} from 'src/app/shared/models/product-brand';
import { environment } from 'src/environments/environment';
import { IProductProductStyle, IProductStyle } from '../../shared/models/product-style';
import { IProductSubcategory } from '../../shared/models/product-subcategory';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  baseUrl = environment.apiUrl;
  brands: IProductBrand[] = [];
  brandPagination = new BrandPagination();
  brandParams = new ProductBrandParams();

  brandProducts: IProduct[] = [];
  brandProductParams = new ProductParams();
  brandProductPagination = new ProductPagination();

  constructor(private http: HttpClient) {}

  getBrands(useCache: boolean) {
    if (useCache === false) {
      this.brands = [];
    }

    if (this.brands.length > 0 && useCache === true) {
      const pagesReceived = Math.ceil(
        this.brands.length / this.brandParams.pageSize
      );

      if (this.brandParams.pageNumber <= pagesReceived) {
        this.brandPagination.data = this.brands.slice(
          (this.brandParams.pageNumber - 1) * this.brandParams.pageSize,
          this.brandParams.pageNumber * this.brandParams.pageSize
        );

        return of(this.brandPagination);
      }
    }

    let params = new HttpParams();

    if (this.brandParams.productCategoryId) {
      params = params.append('productCategoryId', this.brandParams.productCategoryId.toString());
    }
  

    if (this.brandParams.search) {
      params = params.append('search', this.brandParams.search);
    }

    params = params.append('pageIndex', this.brandParams.pageNumber.toString());
    params = params.append('pageSize', this.brandParams.pageSize.toString());

    return this.http
      .get<IBrandPagination>(this.baseUrl + 'productBrands', {
        observe: 'response',
        params,
      })
      .pipe(
        map((response) => {
          this.brands = response.body
            ? [...this.brands, ...response.body.data]
            : this.brands;
          this.brandPagination = response.body
            ? response.body
            : new BrandPagination();
          return this.brandPagination;
        })
      );
  }

  getBrandSubcategories(brandId: string) {
    return this.http.get<IProductSubcategory[]>(this.baseUrl + "productBrands/brandSubcategories/" + brandId)
    .pipe(
      map(response => {
        return response;
      })
    )
  }

  getBrandGenres(brandId: string) {
    return this.http.get<IProductBrand[]>(this.baseUrl + "productBrands/brandGenres/" + brandId)
    .pipe(
      map(response => {
        return response;
      })
    )
  }

  getBrandStyles(brandId: string) {
    return this.http.get<IProductProductStyle[]>(this.baseUrl + "productBrands/brandStyles/" + brandId)
      .pipe(
        map(response => {
          return response;
        })
      )
  }

  setBrandParams(params: ProductBrandParams) {
    this.brandParams = params;
  }

  getBrandParams() {
    return this.brandParams;
  }

  getBrand(id: string) {
    const brand = this.brands.find((s) => s.id === id);
    if (brand) {
      return of(brand);
    }

    return this.http.get<IProductBrand>(this.baseUrl + 'productBrands/' + id);
  }

  setBrandProductsParams(params: ProductParams) {
    this.brandProductParams = params;
  }

  getBrandProductsParams() {
    return this.brandProductParams;
  }

  getBrandProducts(brandId: string, useCache: boolean) {
    if (!useCache) {
      this.brandProducts = [];
    }

    if (this.brandProducts.length > 0 && useCache) {
      const pagesReceived = Math.ceil(this.brandProducts.length / this.brandProductParams.pageSize);

      if (this.brandProductParams.pageNumber <= pagesReceived) {
        this.brandProductPagination.data = this.brandProducts
          .slice(
            (this.brandProductParams.pageNumber - 1) * this.brandProductParams.pageSize,
            this.brandProductParams.pageNumber * this.brandProductParams.pageSize
          );

        return of(this.brandProductPagination);
      }
    }

    let params = new HttpParams();
    params = this.setParamsMethod(params, this.brandProductParams)

    return this.http.get<IProductPagination>(this.baseUrl + 'productBrands/products/' + brandId, {
        observe: 'response',
        params
      })
      .pipe(
        map(response => {
          this.brandProducts = response.body ? [...this.brandProducts, ...response.body.data] : this.brandProducts;
          this.brandProductPagination = response.body ? response.body : new ProductPagination();
          return this.brandProductPagination;
        })
      );
  }

  private setParamsMethod(params: HttpParams, productParams: ProductParams) {

    if (productParams.productCategoryId) {
      params = params.append('productCategoryId', productParams.productCategoryId.toString());
    }
    if (productParams.productSubcategoryId) {
      params = params.append('productSubcategoryId', productParams.productSubcategoryId.toString());
    }
    if (productParams.productBrandId) {
      params = params.append('productBrandId', productParams.productBrandId.toString());
    }
    if (productParams.productGenre) {
      params = params.append('productGenre', productParams.productGenre.toString());
    }
    if (productParams.productStyleId) {
      params = params.append('productStyleId', productParams.productStyleId.toString());
    }
    if (productParams.productId) {
      params = params.append('productId', productParams.productId.toString());
    }

    if (productParams.search) {
      params = params.append('search', productParams.search);
    }

    params = params.append('sort', productParams.sort);
    params = params.append('pageIndex', productParams.pageNumber.toString());
    params = params.append('pageSize', productParams.pageSize.toString());

    return params;
  }
}
