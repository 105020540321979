import { Pipe, PipeTransform } from '@angular/core';

  @Pipe({
    name: 'unique',
    pure: false
  })
  export class UniquePipe implements PipeTransform {

    transform(value: any, args?: any): any {

      // Remove the duplicate elements
      let uniqueArray = value.filter(function (el: any, index: any, array: string | any[]) {
        return array.indexOf (el) == index;
      });

      return uniqueArray;
    }
  }


  @Pipe({
    name: 'uniquename',
    pure: false
  })
  export class UniqueNamePipe implements PipeTransform {

    transform(value: any, args?: any): any {

      // this.genres.map(item => item.name)
      //     .filter((value, index, self) => self.indexOf(value) === index);

      // Remove the duplicate elements
      let uniqueArray = value.map((item: { name: any; }) => item.name).filter(function (el: any, index: any, array: string | any[]) {
        return array.indexOf (el) == index;
      });

      return uniqueArray;
    }
  }
