<header>
    <span *ngIf="this.totalCount && this.totalCount > 0">
        Showing 
        <strong> 
            {{(pageNumber - 1) * pageSize + 1}} -  
            {{
                pageNumber * pageSize > this.totalCount
                ? this.totalCount 
                : pageNumber * pageSize
            }}
        </strong> of 
        <strong>{{this.totalCount}}</strong> 
        results
    </span>
    <span *ngIf="this.totalCount === 0"> 
        There are <strong>0</strong> result for this filter. 
    </span>
</header>
