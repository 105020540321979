export const AddressType = {
    address: [
        {
            id: 0,
            name: "Home Address"
        },
        {
            id: 1,
            name: "Business Address"
        },
        {
            id: 2,
            name: "Shipping Address"
        },
        {
            id: 3,
            name: "Billing Address"
        }
    ]
}

export const UserGenre = {
    genres: [
        {
            id: 1,
            name: "Male"
        },
        {
            id: 2,
            name: "Female"
        }
    ]
}