<div class="mobile">
  <div class="search">
    <div class="form-group d-flex justify-content-between">
      <input
        #search
        (keyup.enter)="onSearch()"
        class="form-control search-input"
        placeholder="Search Product"
        type="text"
      />
      <div (click)="onSearch()" class="search-btn bml-search-btn">
        <i class="fa fa-search"></i>
      </div>
    </div>
  </div>
</div>
