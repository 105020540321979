import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { TermsAndConditionsComponent } from 'src/app/policies/terms-and-conditions/terms-and-conditions.component';
import { PrivacyAndPolicyComponent } from 'src/app/policies/privacy-and-policy/privacy-and-policy.component';
import { environment } from 'src/environments/environment';
import { Platform } from '@angular/cdk/platform';
import { Observable } from 'rxjs';
import { IUserToReturn } from 'src/app/shared/models/user';
import { AccountService } from 'src/app/account/account.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'bml-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  bsModalRef?: BsModalRef;
  
  currentDate = new Date();
  currentUser$!: Observable<IUserToReturn>;
  sessionId = "current-session";

  constructor(
    public footerService: FooterService,
    private modalService: BsModalService, 
    public platform: Platform, 
    private accountService: AccountService
  ) {
    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "current-session";
  }

  ngOnInit(): void {
    this.currentUser$ = this.accountService.currentUser$;
  }

  openDialog() {
    const initialState = {
      class: 'modal-xl modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(TermsAndConditionsComponent, initialState);
  }

  openPrivacyDialog() {
    const initialState = {
      class: 'modal-xl modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(PrivacyAndPolicyComponent, initialState);
  }

  goToBmlServices() {
    window.open(environment.serviceUrl, "_blank");
  }

  goToBmlServiceAlls() {
    window.open(environment.serviceUrl + "services", "_blank");
  }

  goToBmlServiceCategories() {
    window.open(environment.serviceUrl + "services/categories", "_blank");
  }

  goToBmlServiceQuotes() {
    window.open(environment.serviceUrl + "services/quote", "_blank");
  }

  goToBmlServiceDemos() {
    window.open(environment.serviceUrl + "services/demo-request", "_blank");
  }

  goToBmlServiceContacts() {
    window.open(environment.serviceUrl + "contact", "_blank");
  }

  goToBmlServiceAboutUs() {
    window.open(environment.serviceUrl + "about-bml", "_blank");
  }

  goToBmlServiceBlogs() {
    window.open(environment.serviceUrl + "blogs", "_blank");
  }

  goToBmlSellerPortal() {
    window.open(environment.sellerUrl, "_blank");
  }

}
