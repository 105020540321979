<pagination
  [boundaryLinks]="true"
  [totalItems]="totalCount"
  (pageChanged)="onPageChange($event)"
  [ngModel]="pageNumber"
  [itemsPerPage]="pageSize"
  previousText="&lsaquo;"
  nextText="&rsaquo;"
  firstText="&laquo;"
  lastText="&raquo;"
  [rotate]="true"
  [maxSize]="(isHandset$ | async) ? 3 : 5"
>
</pagination>
