<div class="footer" [ngClass]="{'ios-mobile-sidenav': platform.IOS, 'no-ios-sidenav': !platform.IOS}" *ngIf="footerService.visible">
  <div class="footer-header">
    <div class="container">
      <div class="row">
        <div class="col-sm-3 col-6">
          <h3>Company</h3>
          <p><a (click)="goToBmlServiceAboutUs()">About us</a></p>
          <p><a (click)="goToBmlServiceContacts()">Contact us</a></p>
          <ng-container *ngIf="(currentUser$ | async) === null">
            <p><a routerLink="/account/login">Manage your Account</a></p>
          </ng-container>
          <ng-container *ngIf="currentUser$ | async as user">
            <p><a routerLink="/account/profile">Manage your Account</a></p>
          </ng-container>
          
          <!-- <p><a routerLink="/partners">Our Partners</a></p>
          <p><a href="#">Explore BML Careers</a></p>-->
          <p><a (click)="goToBmlServiceBlogs()">Blogs</a></p>
        </div>
        <div class="col-sm-3 col-6">
          <h3 class="service-link" (click)="goToBmlServices()">Services</h3>
          <p><a (click)="goToBmlServiceAlls()">All Services</a></p>
          <p><a (click)="goToBmlServiceCategories()">Service Categories</a></p>
          <p><a (click)="goToBmlServiceQuotes()">Request a Quote</a></p>
          <p><a (click)="goToBmlServiceDemos()">Request a Demo</a></p>
          <p><a (click)="goToBmlServiceContacts()">Contact Sales</a></p>
          <!-- <p><a>Developers</a></p> -->
        </div>
        <div class="col-sm-3 col-6">
          <h3>Shoppings</h3>
          <p>
            <a routerLink="/shop/products/departments"
              [queryParams]="{
                sessionId: sessionId,
                'departments': 'all' | lowercase
              }"
              queryParamsHandling="merge"
            >Shop by Departments</a>
          </p>
          <p>
            <a routerLink="/shop/products/collections"
              [queryParams]="{
                sessionId: sessionId,
                'collections': 'all' | lowercase
              }"
              queryParamsHandling="merge"
            >Shop by Collections</a>
          </p>
          <p>
            <a routerLink="/shop/products/brands"
              [queryParams]="{
                sessionId: sessionId,
                'brands': 'all' | lowercase
              }"
              queryParamsHandling="merge"
            >Shop by Brands</a>
          </p>
          <p>
            <a routerLink="/shop/products"
              [queryParams]="{
                sessionId: sessionId,
                'products': 'all' | lowercase
              }"
              queryParamsHandling="merge"
            >Shop by Products</a>
          </p>
          <p><a (click)="goToBmlSellerPortal()">Become a Seller</a></p>
          <p><a>Become our Supplier</a></p>
        </div>
        <div class="col-sm-3 col-6 contact-info">
          <h3>Contacts</h3>
          <p><a href="#">+1 (888) 896-2698</a></p>
          <p><a (click)="goToBmlServiceContacts()">Send us a Message</a></p>
          <p><a outerLink="/service-locations">Service Locations</a></p>
          <p><a outerLink="/sales-locations">Sales Locations</a></p>
          <div class="social d-flex justify-content-between mt-5">
            <a
              href="https://www.facebook.com/BML-Business-Center-LLC-1016096515268065"
              target="_blank"
              ><i class="fa fa-facebook-square fa-2x"></i
            ></a>
            <a
              href="https://www.youtube.com/channel/UC0ICKNuzOMO0nscEuJmW1Dg"
              target="_blank"
              ><i class="fa fa-youtube-square fa-2x"></i
            ></a>
            <a
              href="https://www.instagram.com/bmlbusinesscenter/?hl=en"
              target="_blank"
              ><i class="fa fa-instagram fa-2x"></i
            ></a>
            <a href="https://twitter.com/BmlCenter" target="_blank"
              ><i class="fa fa-twitter-square fa-2x"></i
            ></a>
          </div>
        </div>
      </div>
      <hr />
      <div class="translate text-center">
        <p class="translate-text">
          Translate the site into your language <br>
          <small>Note: The translation may not be 100% accurate</small>
        </p>        
        <div id="google_translate_element"></div>
      </div>
    </div>
  </div>

  <div class="copyright-section">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-6 ">
          <div class="copyright">
            <img
              class="logo"
              src="/assets/images/bmlresize.png"
              alt="BML logo"
            />
            &copy; {{ currentDate | date: "y" }} BML Business Center. All rights
            reserved.
          </div>          
        </div>
        <div class="col-md-6">
          <div class="policies">
            <span (click)="openDialog()">Terms & Conditions</span>
            <span (click)="openPrivacyDialog()">Privacy Policy</span>
          </div>          
        </div>
      </div>
    </div>
  </div>
</div>
