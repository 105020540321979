import {Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {ShopService} from '../../../shop/shop.service';
import {ProductParams} from '../../models/product';

@Component({
  selector: 'bml-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @ViewChild('search', {static: false}) searchTerm!: ElementRef;
  shopParams!: ProductParams;

  constructor(private productService: ShopService, private router: Router) {
    this.shopParams = this.productService.getProductsParams();
    this.shopParams.pageSize = 12;
  }

  onSearch() {
    if (this.searchTerm.nativeElement.value) {
      this.shopParams = new ProductParams();
      this.shopParams.search = this.searchTerm.nativeElement.value;
      this.shopParams.pageNumber = 1;
      this.shopParams.pageSize = 12;
      this.productService.setProductsParams(this.shopParams);
      this.searchTerm.nativeElement.value = '';

      let sessionId = sessionStorage.getItem(environment.sessionStringId);

      this.router.navigate(
        ["/shop/products/search-query-result"],
        {
          queryParams: {searchTerm: this.shopParams.search, sessionId: sessionId},
          queryParamsHandling: '',
          state: {params: this.shopParams}
        })
    }
  }
}
