import { NgModule } from '@angular/core';
import { Routes, RouterModule, TitleStrategy, PreloadAllModules } from '@angular/router';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { ShopComponent } from './shop/shop.component';
import { TemplatePageTitleStrategy } from './shared/templates/template-page-title-strategy';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: ShopComponent },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((mod) => mod.AccountModule),
  },
  {
    path: 'shop',
    loadChildren: () =>
      import('./shop/shop.module').then((mod) => mod.ShopModule),
  },
  {
    path: 'shopping-cart',
    loadChildren: () =>
      import('./shopping-cart/shopping-cart.module').then(
        (mod) => mod.ShoppingCartModule
      ),
    pathMatch: 'full',
  },
  {
    path: 'checkout',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./checkout/checkout.module').then((mod) => mod.CheckoutModule),
    pathMatch: 'full',
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./orders/orders.module').then((mod) => mod.OrdersModule),
  },
  {
    path: 'policies',
    loadChildren: () =>
      import('./policies/policies.module').then((mod) => mod.PoliciesModule),
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [CoreModule, RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy
    }
  ]
})
export class AppRoutingModule {}
