import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {LoggingErrorService} from 'src/app/logging-error/logging-error.service';
import {IProduct, IProductColor, ProductParams} from 'src/app/shared/models/product';
import {ShopService} from '../../shop.service';

@Component({
  selector: 'bml-arrival-preview',
  templateUrl: './arrival-preview.component.html',
  styleUrls: ['./arrival-preview.component.scss']
})
export class ArrivalPreviewComponent implements OnInit {
  allProducts: IProduct[] = [];
  clothesProducts: IProduct[] = [];
  noClothesProducts: IProduct[] = [];

  loadMoreProducts: IProduct[] = [];
  productParams!: ProductParams;
  totalCount: number = 0;
  initialCount: number = 6;

  newImageUrl!: string;
  currentProductCardId: string = '';

  productBackground = "white";
  sliderBackground = "#804000" // "#1b0000";
  textColor = "black";

  isNewArrived: boolean = false;

  constructor(private service: ShopService,
              private logger: LoggingErrorService) {

    this.productParams = service.getProductsParams();
    this.productParams.pageSize = 12;
  }

  ngOnInit(): void {
    this.getArrivedProducts(false);
    //this.signalRConnection();
  }

  // signalRConnection() {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .configureLogging(signalR.LogLevel.Information)
  //     .withUrl(environment.apiUrl + 'notify')
  //     .build();
  //
  //   connection.start().then(function() {
  //     console.log('SignalR Connected')
  //   }).catch( err => {
  //     return console.error(err.toString())
  //   })
  //
  //   connection.on("BroadcastMessage", () => {
  //     this.getArrivedProducts(true);
  //   })
  // }

  showColorImage(color: IProductColor, product: IProduct) {
    this.currentProductCardId = product.id;
    this.newImageUrl = color.mainProductPictureUrl;
  }

  createProductClickStream(productId: string) {
    this.service.createProductClickstream(productId).subscribe(() => {
    });
  }

  getArrivedProducts(useCache: boolean) {
    this.service.getProducts(useCache).subscribe(response => {
      let now = moment();
      this.allProducts = response.data
        .filter(x => (moment(x.createdAt).add(30, 'days')).diff(now, 'seconds') >= 0)
        .sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);

      this.noClothesProducts = this.allProducts.filter(x => x.productCategory.toLowerCase() !== "Clothes".toLowerCase());
      this.clothesProducts = this.allProducts.filter(x => x.productCategory.toLowerCase() === "Clothes".toLowerCase())
      this.totalCount = this.noClothesProducts.length;

      this.isNewArrived = this.noClothesProducts.length > 0;

    }, error => {
      this.logger.log("Arrival Product Component", error);
    })
  }

  onPageChanged(event: any) {
    const params = this.service.getProductsParams();
    if (params.pageNumber !== event) {
      params.pageNumber = event;
      this.service.setProductsParams(params);
      this.getArrivedProducts(true);
    }
  }

  loadMore() {
    const params = this.service.getProductsParams();
    params.pageNumber = 1;
    params.pageSize += 6;
    this.service.setProductsParams(params);
    this.getArrivedProducts(false);
  }

  showLess() {
    if (this.noClothesProducts.length > this.initialCount) {
      const params = this.service.getProductsParams();
      params.pageNumber = 1;
      params.pageSize -= 6;
      this.service.setProductsParams(params);
      this.getArrivedProducts(false);
    }
  }

}
