import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {IProduct} from 'src/app/shared/models/product';
import {environment} from 'src/environments/environment';
import {ShopService} from '../../../shop/shop.service';
import {IProductColor} from '../../models/product';

@Component({
  selector: 'bml-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit {
  @Input() product!: IProduct;
  isNewProduct: boolean = false;
  newImageUrl!: string;
  sessionId: string = "current-session";

  constructor(private router: Router, private service: ShopService) {
  }

  ngOnInit(): void {
    this.getNewArrived();
    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "";
  }

  getNewArrived() {
    let now = moment();
    this.isNewProduct = (moment(this.product.createdAt).add(30, 'days')).diff(now, 'seconds') >= 0;
  }

  showColorImage(color: IProductColor) {
    this.newImageUrl = color.mainProductPictureUrl;
    // if(color.mainProductPictureUrl == null) {
    //   const tempImage = color.productColorImages.find(x => x.isMain);
    //   if(tempImage) {
    //     this.newImageUrl = tempImage?.fileUrl;
    //   }
    // }
    // else {
    //   this.newImageUrl = color.mainProductPictureUrl;
    // }
  }

  createProductClickstream(productId: string) {
    this.service.createProductClickstream(productId).subscribe(() => {
    });
  }

  goToProductDetail(product: IProduct) {
    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "current-session";
    this.createProductClickstream(product.id)
    this.router.navigate(
      ['/shop/products/' + product.id + '/' + product.slug],
      {
        queryParams: {
          sessionId: this.sessionId,
          'category': product.productCategory.toLowerCase(),
          'brand': product.productBrand.toLowerCase(),
          'product': product.slug.toLowerCase()
        },
        queryParamsHandling: ""
      }
    )
    this.reload()
  }

  reload() {
    setTimeout(() => {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    }, 100);
  }
}
