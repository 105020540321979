<mat-toolbar 
  class="d-flex justify-content-between"
  [ngClass]="{'ios-mobile': platform.IOS, 'mobile-footer-toolbar': !platform.IOS}"
>
    <span class="fa fa-home" routerLink="/"></span>
    <ng-container *ngIf="currentUser$ | async as user">
      <span class="fa fa-user" routerLink="/account/profile"></span>
    </ng-container>
    <ng-container *ngIf="!(currentUser$ | async)">
      <span class="fa fa-user" routerLink="/account/login"></span>
    </ng-container>
    
    <span
      matBadge="{{ cartTotalItems }}"
      matBadgeOverlap="false"
      matBadgeColor="accent"
      class="fa fa-shopping-cart"
      routerLink="/shopping-cart"
    ></span>
    <button mat-button [matMenuTriggerFor]="aboveMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #aboveMenu="matMenu" yPosition="above">
      <button mat-menu-item routerLink="/orders">
        <i class="fa fa-history"></i> Orders
      </button>
      <button mat-menu-item routerLink="/shop/products/sales-and-deals">
        <i class="fa fa-shopping-basket"></i> Sales & Deals
      </button>
      <button mat-menu-item (click)="goToBmlServices()">
        <i class="fa fa-globe"></i> BML Services
      </button>
    </mat-menu>
</mat-toolbar>