import {Component, Input, OnInit} from '@angular/core';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'bml-timer-count-down',
  templateUrl: './timer-count-down.component.html',
  styleUrls: ['./timer-count-down.component.scss']
})
export class TimerCountDownComponent implements OnInit {
  @Input() endDate!: string;
  @Input() color!: string;
  @Input() fontSize: string = '1.2em';
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  public timeDifference: number | undefined;
  public secondsToDay: number | undefined;
  public minutesToDay: number | undefined;
  public hoursToDay: number | undefined;
  public daysToDay: number | undefined;
  private subscription!: Subscription;

  ngOnInit() {
    this.subscription = interval(1000).subscribe(x => {
      this.getTimeDifference()
    })
  }

  private getTimeDifference() {
    this.timeDifference = Date.parse(this.endDate) - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference: any) {
    this.secondsToDay = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDay = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDay = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDay = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }
}
