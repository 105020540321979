import {Component, OnInit} from '@angular/core';
import {LoggingErrorService} from 'src/app/logging-error/logging-error.service';
import {Department, IProduct, ProductParams} from 'src/app/shared/models/product';
import {ShopService} from '../../shop.service';
import { CategoryService } from '../../category/category.service';

@Component({
  selector: 'bml-accessories-preview',
  templateUrl: './accessories-preview.component.html',
  styleUrls: ['./accessories-preview.component.scss'],
})
export class AccessoriesPreviewComponent implements OnInit {
  products: IProduct[] = [];
  productParams!: ProductParams;
  totalCount: number = 0;

  constructor(
    private categoryService: CategoryService,
    private logger: LoggingErrorService
  ) {
    this.productParams = categoryService.getDepartmentProductParams();
    this.productParams.pageSize = 12;
  }

  ngOnInit(): void {
    this.getProducts(false);
    //this.signalRConnection();
  }

  // signalRConnection() {
  //   const connection = new signalR.HubConnectionBuilder() 
  //     .configureLogging(signalR.LogLevel.Information)
  //     .withUrl(environment.apiUrl + 'notify')
  //     .build();
  //
  //   connection.start().then(function() {
  //     console.log('SignalR Connected')
  //   }).catch( err => {
  //     return console.error(err.toString())
  //   })
  //
  //   connection.on("BroadcastMessage", () => {
  //     this.getProducts(true);
  //   })
  // }

  getProducts(useCash: boolean = false) {
    this.categoryService.getDepartmentProductsByName(useCash, Department.accessories).subscribe(
      (response: any) => {
        this.products = response.data;
        this.totalCount = response.count;
      },
      (error) => {
        this.logger.log(error, 'accessories-preview');
      }
    );
  }

  onPageChanged(event: any) {
    const params = this.categoryService.getDepartmentProductParams();
    if (params.pageNumber !== event) {
      params.pageNumber = event;
      this.categoryService.setDepartmentProductParams(params);
      this.getProducts(true);
    }
  }
}
