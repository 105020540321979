
export const environment = {
  name: "testnet",
  production: true,
  apiUrl: 'https://bmlmarketplace-customer-dev-api.azurewebsites.net/api/v1/customer/',
  apiModelUrl: 'https://testnet.models.bmlmarketplace.com/',
  clientUrl: 'https://testnet.bmlmarketplace.com/',
  adminUrl: 'https://testnet.management.bmlmarketplace.com/',
  sellerUrl: 'https://testnet.seller.bmlmarketplace.com/',
  eCommerceUrl: 'https://shop.bmlcenter.com/',
  serviceUrl: 'https://services.bmlcenter.com/',
  shoppingUrl: 'https://www.bmlshopping.com/',
  
  captchaV2ChallengeSiteKey: '6Ld9geAiAAAAAAm0hg3YqtjsacIRDI9OJoeJ2yaA',
  
  paypal: {
    clientId: "ASxea8KOm3mcdz07mayQFZKO5v5gem6O3cm0OZe9PvqZWBovcqd5OHnDHf9YdgzBklAIKkMKwazowLLi",
    secret: "EHjpxCC3744LRwx5TBTdGcchy3GrRVtBFMS2WJZ0bEvnVguFVS7Yauwn4ARwrYr7s3dKGpqIObEJbIub"
  },

  stripe: {
    clientKey: 'pk_test_51M0pD7IcehOXPjRFxsWbK27J8r7IhM2HUiFm5KCJAfr5XvlVyelUoS7xzO3sRPCHJOdw4fAB1FhDSTxhlnUqvQWd00Jdp3jXpb'
  },

  twoDaysFreeShippingId: "c703624d-a650-4494-ba5f-13d4479cbabb",
  subcategory: {
    africanId: '1f5a0762-9f84-4bc9-a631-b6970049db15'
  },
  
  userTokenStringId: 'bml-4b9bc926-nekot-644f-42a8-dorp-9062-fc1475a1682e-marketplace',
  basketStringId: 'bml-1a8ec37a-tek5ab-410e-4666-orp-ac2f-d16eff879b70-marketplace',
  announcementStringId: 'bml-f9ae44d3-announcement-af0e-46f5-prod-aae1-2593a9808012-marketplace',
  sessionStringId: 'bml-370049df-7342-session-4423-be1d-prod-9066c0bc69ef-marketplace',
  productReviewHelpfulId: 'bml-b809e2a8-helpful-9f97-4d4f-review-8ba6-cf5c676-productd4121-marketplace'
};
