import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bml-password-rule',
  templateUrl: './password-rule.component.html',
  styleUrls: ['./password-rule.component.scss'],
})
export class PasswordRuleComponent implements OnInit {
  @Input() passwordElement: any;

  constructor() {}

  ngOnInit(): void {
  }
}
