import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastr: ToastrService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error) {
          if (error.status === 400) {
            if (req.url.toLowerCase().includes('account')) {
            } 
            else {
              if (error.error.errors) {
                throw error.error;
              } else {
                this.toastr.error(error.error.message, error.error.statusCode);
              }
            }  
          }

          if ([401, 403].includes(error.status || error.error.statusCode)) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            if (req.url.toLowerCase().includes('account')) {
            } 
            else {
              if(error.error) {
                this.toastr.error(error.error.message, error.error.statusCode);
              }              
            }
          }

          if (error.status === 404) {
            this.router.navigateByUrl('/not-found');
            this.reloadPage();
          }

          if (error.status === 500) {
            const navigationExtras: NavigationExtras = {
              state: { error: error.error },
            };
            this.router.navigateByUrl('/server-error', navigationExtras);
            //this.reloadPage();
          }
        }

        return throwError(error);
      })
    );
  }

  reloadPage() {
    setTimeout(() => {
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }, 100);
  }
}
