<div class="shop">
  <div class="row">
    <div class="col-md-12 offset-md-0 col-xl-10 offset-xl-1 p-0">
      <div class="bml-carousel">
        <carousel
          [interval]="10000"
          [isAnimated]="true"
          [noPause]="false"
          [pauseOnFocus]="true"
          [showIndicators]="false"
        >
          <slide
            *ngFor="let category of categories"
            [class.bml-slide]="!category.pictureUrl"
          >
            <img
              *ngIf="category.pictureUrl"
              [alt]="category.name"
              [src]="category.pictureUrl"
              class="carousel-image img-responsive"
              style="display: block; width: 100%"
            />
            <div class="carousel-caption">
              <h1 class="title">{{ category.name }}</h1>
              <p class="description mb-5">{{ category.shortDescription }}</p>
              <button
                [queryParams]="{
                  sessionId: sessionId,
                  'category': category.name | lowercase
                }"
                class="btn bml-custom-outline-btn"
                queryParamsHandling="merge"
                routerLink="/shop/products/departments/{{ category.id }}/{{ category.slug }}"
              >
                Explore
              </button>
            </div>
          </slide>
        </carousel>
      </div>

      <div class="shop-container">
        <bml-product-features></bml-product-features>

        <bml-arrival-preview></bml-arrival-preview>

        <div class="pp-message mb-4"></div>

        <ng-container *ngIf="isSales">
          <bml-sales-offer [isHomePage]="true"></bml-sales-offer>
        </ng-container>

        <bml-deal-of-day></bml-deal-of-day>

        <div class="mx-4">
          <bml-brand-preview></bml-brand-preview>
        </div>

        <bml-collection-product-preview></bml-collection-product-preview>

        <bml-shoes-slider></bml-shoes-slider>
        <bml-product-preview></bml-product-preview>
        <bml-accessories-preview></bml-accessories-preview>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid session-products mb-3">
  <bml-user-session-product 
    [sliderBackground2]="bg2" 
    [textColor2]="textColor2"
    [sliderBackground1]="bg1"
    [textColor]="textColor">
  </bml-user-session-product>
</div>
