import { Component, Input } from '@angular/core';

@Component({
  selector: 'bml-zippy',
  templateUrl: './zippy.component.html',
  styleUrls: ['./zippy.component.scss']
})
export class ZippyComponent {
  @Input() title!: string;
  isExpanded!: boolean;

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

}
