<div class="admin" *ngIf="userProfile" [class.admin-container]="isAdmin">
  <div class="container my-5">
    <!-- <h3 class="">User Profile</h3> -->
    <div class="card principal personal-info">
      <div class="card-header main">
        <h3 class="title">Personal Information</h3>
      </div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <th scope="row">Account Number</th>
              <td>{{ userProfile.accountNumber }}</td>
            </tr>
            <tr>
              <th scope="row">Name</th>
              <td>{{ userProfile.firstName }} {{ userProfile.lastName }}</td>
            </tr>
            <tr>
              <th scope="row">Email</th>
              <td>{{ userProfile.email }}</td>
            </tr>
            <tr>
              <th scope="row">Username</th>
              <td>{{ userProfile.userName }}</td>
            </tr>
            <tr>
              <th scope="row">Phone Number</th>
              <td>
                {{ userProfile.countryCode }} {{ userProfile.phoneNumber }}
              </td>
            </tr>
            <tr>
              <th scope="row">Genre</th>
              <td>{{ userProfile.genre }}</td>
            </tr>
          </tbody>
        </table>
        <hr />

        <div class="d-flex justify-content-between flex-column flex-lg-row">
          <div
            class="btn btn-warning btn-md"
            routerLink="/{{ componentName }}/profile/update"
          >
            <i class="fa fa-edit"></i> Update your Information
          </div>
          <div
            class="btn btn-outline-primary btn-md"
            routerLink="/{{ componentName }}/profile/change-password"
          >
            <i class="fa fa-edit"></i> Change your password
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="roles mb-5">
      <div class="card principal">
        <div class="card-header main">
          <h3 class="title">Roles</h3>
        </div>
        <div class="card-body">
          <ng-container *ngFor="let role of userProfile.roles">
            <bml-zippy title="{{ role.name }}">
              <div
                [innerHTML]="role.description | sanitizeHtml"
                class="role-description"
              ></div>
            </bml-zippy>
          </ng-container>
        </div>
      </div>
    </div> -->

    <div class="address" *ngIf="userProfile.customerAddresses && userProfile.customerAddresses.length > 0">
      <div class="card principal mt-5">
        <div class="card-header main">
          <h3 class="title">Addresses</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              class="col-md-6"
              *ngFor="let address of userProfile.customerAddresses"
            >
              <div class="card">
                <div class="card-header">
                  <h3 class="address-header">{{ address?.addressType }}</h3>
                </div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th scope="row">Address Line 1</th>
                        <td>{{ address.addressLine1 }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Address Line 2</th>
                        <td>{{ address.addressLine2 }}</td>
                      </tr>
                      <tr>
                        <th scope="row">City</th>
                        <td>{{ address.city }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Province</th>
                        <td>{{ address.province }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Postal Code</th>
                        <td>{{ address.postalCode }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Country</th>
                        <td>{{ address.country }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <div
                    class="btn btn-warning btn-block btn-md w-100"
                    routerLink="/{{ componentName }}/address/{{ address.id }}"
                  >
                    Update
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="btn bml-stepper-custom-outline-btn"
              routerLink="/{{ componentName }}/addresses"
            >
              <i class="fa fa-id-badge"></i> Manage your Address
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="btn bml-stepper-custom-outline-btn"
        routerLink="/{{ componentName }}/address/new"
      >
        <i class="fa fa-plus-circle"></i> Add an Address
      </div>
    </div>
  </div>
</div>
