import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { IUserAddress } from 'src/app/shared/models/user';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserAddressService {
  baseUrl = environment.apiUrl;
  accountUrl = "";
  addresses: IUserAddress[] = [];

  constructor(private http: HttpClient) {
    this.accountUrl = this.baseUrl + "customerAddresses";
  }

  adminGetUserAddresses(useCache: boolean) {
    if (useCache === false) {
      this.addresses = [];
    }

    if (this.addresses.length > 0 && useCache === true) {
      return of(this.addresses);
    }

    return this.http.get<IUserAddress[]>(this.baseUrl + 'customerAddresses')
      .pipe(
        map((response) => {
          this.addresses = response;
          return this.addresses;
        })
      );
  }

  getUserAddress(id: string){
    const address = this.addresses.find(s => s.id === id);
    if (address) {
      return of(address);
    }

    return this.http.get<IUserAddress>(this.baseUrl + 'customerAddresses/' + id);
  }

  createUserAddress(address: IUserAddress) {
    return this.http.post(this.baseUrl + 'customerAddresses', address)
    .pipe(
      map(response => {
        if(!response){
          console.log('Error Occured while creating.')
          throw new Error("Value Expected");
          
        }
        return response;
      }),
      catchError(err => of([]))
    )
  }

  updateUserAddress(address: IUserAddress, id: string) {
    return this.http.put(this.baseUrl + 'customerAddresses/' + id, address)
    .pipe(
      map(response => {
        if(!response){
          console.log('Error Occured during update.')
          throw new Error("Value Expected");
          
        }
        return response;
      }),
      catchError(err => of([]))
    )
  }

  deleteUserAddress(id: string) {
    return this.http.delete(this.baseUrl + 'customerAddresses/' + id);
  }
}
