import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggingErrorService } from '../logging-error/logging-error.service';
import { IOrderDeliveryMethod } from '../shared/models/order';
import { IProduct } from '../shared/models/product';
import {
  IBasketItem,
  IBasketSavedForLater,
  IBasketItemSavedForLater,
  IBasket,
  IBasketTotals,
  Basket,
  BasketTotals,
  BasketInitialization,
} from '../shared/models/shopping-cart';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  baseUrl = environment.apiUrl;
  localbasketStringId = environment.basketStringId;
  savedBasket!: IBasketSavedForLater;
  private basketSource = new BehaviorSubject<any>(null);
  basket$ = this.basketSource.asObservable();
  private basketTotalSource = new BehaviorSubject<IBasketTotals>(
    new BasketTotals()
  );
  basketTotal$ = this.basketTotalSource.asObservable();
  shipping = 0;
  tax = 7;

  component = 'shopping-cart service';

  constructor(private http: HttpClient, private logger: LoggingErrorService) {}

  createPaymentIntent() {
    return this.http
      .post(
        this.baseUrl + 'productPayments/' + this.getCurrentBasketValue().id,
        {}
      )
      .pipe(
        map((basket: any) => {
          this.basketSource.next(basket);
        })
      );
  }

  setShippingPrice(deliveryMethod: IOrderDeliveryMethod) {
    this.shipping = deliveryMethod.price;
    const basket = this.getCurrentBasketValue();
    basket.deliveryMethodId = deliveryMethod.id;
    basket.shippingPrice = deliveryMethod.price;
    this.calculateTotals();
    this.setBasket(basket);
  }

  getBasket(id: string) {
    return this.http.get(this.baseUrl + 'shoppingCart?id=' + id).pipe(
      map((basket: any) => {
        this.basketSource.next(basket);
        this.shipping = basket?.shippingPrice;
        this.calculateTotals();
      })
    );
  }

  setBasket(basket: IBasket) {
    return this.http.post(this.baseUrl + 'shoppingCart', basket).subscribe(
      (response: any) => {
        this.basketSource.next(response);
        this.calculateTotals();
      },
      (error) => {
        this.logger.log(error, this.component);
      }
    );
  }

  getCurrentBasketValue() {
    return this.basketSource.value;
  }

  addItemToBasket(item: IProduct, quantity = 1, size: string, color: string, colorImageUrl: string) {
    const itemToAdd: IBasketItem = this.mapProductItemToBasketItem(
      item,
      quantity,
      size,
      color,
      colorImageUrl
    );

    let basket = this.getCurrentBasketValue();

    if (basket === null) {
      basket = this.createBasket();
    }

    basket.basketItems = this.addOrUpdateItem(basket.basketItems, itemToAdd, quantity);
    this.setBasket(basket);
  }

  incrementItemQuantity(item: IBasketItem) {
    const basket = this.getCurrentBasketValue();
    const foundItemIndex = basket.basketItems.findIndex(
      (x: IBasketItem) => x.id === item.id
    );
    basket.basketItems[foundItemIndex].quantity++;
    this.setBasket(basket);
  }

  decrementItemQuantity(item: IBasketItem) {
    const basket = this.getCurrentBasketValue();
    const foundItemIndex = basket.basketItems.findIndex(
      (x: IBasketItem) => x.id === item.id
    );
    if (basket.basketItems[foundItemIndex].quantity > 1) {
      basket.basketItems[foundItemIndex].quantity--;
      this.setBasket(basket);
    } else {
      this.removeItemFromBasket(item);
      this.setBasket(basket);
    }
  }

  removeItemFromBasket(item: IBasketItem) {
    const basket = this.getCurrentBasketValue();
    if (basket.basketItems.some((x: IBasketItem) => x.id === item.id)) {
      basket.basketItems = basket.basketItems.filter((i: IBasketItem) => i.id !== item.id);
      if (basket.basketItems.length > 0) {
        this.deleteBasketItem(item);
        this.setBasket(basket);
        //location.reload();
      } else {
        this.deleteBasket(basket);
        this.setBasket(basket);
      }
    }
  }

  deleteBasketItem(item: IBasketItem) {
    const basket = this.getCurrentBasketValue();
    basket.basketItems = basket.basketItems.filter((i: IBasketItem) => i.id !== item.id);
    return this.http
      .delete(this.baseUrl + 'shoppingCart/cartItem/' + item.id)
      .subscribe(() => {
        this.setBasket(basket);
      });
  }

  deleteAllSelectedBasketItems(items: IBasketItem[]) {   
    const basket = this.getCurrentBasketValue();  
    items.forEach((item:IBasketItem) => {
      basket.basketItems = basket.basketItems.filter((i: IBasketItem) => i.id !== item.id);
    });
    return this.http
      .post(this.baseUrl + 'shoppingCart/selectedCartItems', items)
      .subscribe(() => {          
        if (basket.basketItems.length > 0) {
          this.setBasket(basket);
        } else {
          this.deleteBasket(basket);
          this.setBasket(basket);
        }
      });
  }

  deleteLocalBasket(id: string) {
    this.basketSource.next(null);
    this.basketTotalSource.next(new BasketTotals());
    localStorage.removeItem(this.localbasketStringId);
  }

  deleteBasket(basket: IBasket) {
    return this.http
      .delete(this.baseUrl + 'shoppingCart?id=' + basket.id)
      .subscribe(
        () => {
          this.basketSource.next(new Basket());
          this.basketTotalSource.next(new BasketTotals());
          localStorage.removeItem(this.localbasketStringId);
        },
        (error) => {
          this.logger.log(error, this.component);
        }
      );
  }

  getUserSavedbasket() {
    return this.http.get<IBasketSavedForLater>(
      this.baseUrl + 'shoppingCart/userSavedCart'
    );
  }

  saveProductForLater(product: IBasketItem) {
    const itemToAdd: IBasketItemSavedForLater = this.mapProductToSaveProductForLater(
      product
    );
    return this.http
      .post(this.baseUrl + 'shoppingCart/saveForLater', itemToAdd);
  }

  moveItemToBasket(product: IBasketItemSavedForLater) {
    const itemToAdd: IBasketItem = this.mapMoveProductItemBackToBasketItem(product);

    let basket = this.getCurrentBasketValue();

    if (basket === null) {
      basket = this.createBasket();
    }

    basket.basketItems = this.addOrUpdateItem(
      basket.basketItems,
      itemToAdd,
      itemToAdd.quantity
    );

    this.deleteSavedItem(product);  
    this.getUserSavedbasket();
    this.setBasket(basket);
  }

  deleteSavedItem(product: IBasketItemSavedForLater) {
    return this.http
      .delete(this.baseUrl + 'shoppingCart/savedForLater/' + product.id)
      .pipe(
        map((response) => {
          if (!response) {
            console.log('Error Occured while creating.');
            throw new Error('Value Expected');
          }
          return response;
        }),
        catchError((err) => of([]))
      );
  }

  private calculateTotals() {
    const basket = this.getCurrentBasketValue();
    if(basket) {
      const shipping = this.shipping;
      const subtotal = basket?.basketItems.reduce(
        (a: any, b: any) => b.price * b.quantity + a,
        0
      );
      
      const listPriceSubtotal = basket?.basketItems.reduce(
        (a: any, b: any) => b.listPrice * b.quantity + a,
        0
      );

      const amountSaved = listPriceSubtotal - subtotal;
      const promotionAmount = subtotal * (basket?.promotionPercent ? (basket?.promotionPercent / 100.00) : 0);
      const subtotalAfterPromotion = subtotal - promotionAmount;
      const tax = (subtotalAfterPromotion * (this.tax / 100.00));
      const subtotalAfterTax = subtotalAfterPromotion + tax;
      const originalTotal = listPriceSubtotal + (listPriceSubtotal * (this.tax / 100.00)) + shipping;
      const total = subtotalAfterTax + shipping;
      this.basketTotalSource.next({
        shipping,
        listPriceSubtotal,
        subtotal,
        amountSaved,
        promotionAmount,
        subtotalAfterPromotion,
        subtotalAfterTax,
        tax,
        originalTotal,
        total,
      });
    }
    
  }

  private addOrUpdateItem(
    items: IBasketItem[],
    itemToAdd: IBasketItem,
    quantity: number
  ): IBasketItem[] {
    const index = items.findIndex(
      (i) =>
        i.productId === itemToAdd.productId &&
        i.size === itemToAdd.size &&
        i.color === itemToAdd.color
    );

    if (index === -1) {
      itemToAdd.quantity = quantity;
      items.push(itemToAdd);
    } else {
      items[index].quantity += quantity;
    }
    return items;
  }

  private createBasket(): IBasket {
    const basket = new Basket();
    localStorage.setItem(this.localbasketStringId, basket.id);
    return basket;
  }

  private mapProductItemToBasketItem(
    item: IProduct,
    quantity: number,
    size: string,
    color: string,
    colorImageUrl: string
  ): IBasketItem {
    return {
      sellerId: item.sellerAccountId,
      productId: item.id,
      productName: item.name,
      shortDescription: item.shortDescription,
      price: item.price,
      listPrice: item.listPrice,
      productDiscount: item.productDiscountPercent,
      pictureUrl: colorImageUrl,
      quantity,
      size,
      color,
      brand: item.productBrand,
      category: item.productCategory,
      subcategory: item.productSubcategory,
      genre: item.genre
    };
  }

  private mapProductToSaveProductForLater(
    item: IBasketItem
  ): IBasketItemSavedForLater {
    return {
      productId: item.productId,
      productName: item.productName,
      shortDescription: item.shortDescription,
      price: item.price,
      listPrice: item.listPrice,
      productDiscount: item.productDiscount,
      pictureUrl: item.pictureUrl,
      quantity: item.quantity,
      size: item.size,
      color: item.color,
      brand: item.brand,
      category: item.category,
      subcategory: item.subcategory,
      genre: item.genre
    };
  }

  private mapMoveProductItemBackToBasketItem(
    item: IBasketItemSavedForLater
  ): IBasketItem {
    return {
      productId: item.productId,
      productName: item.productName,
      shortDescription: item.shortDescription,
      price: item.price,
      listPrice: item.listPrice,
      productDiscount: item.productDiscount,
      pictureUrl: item.pictureUrl,
      quantity: item.quantity,
      size: item.size,
      color: item.color,
      brand: item.brand,
      category: item.category,
      subcategory: item.subcategory,
      genre: item.genre
    };
  }
}
