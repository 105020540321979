import {Component, OnInit} from '@angular/core';
import {IProductColor} from 'src/app/shared/models/product';
import {LoggingErrorService} from '../../../logging-error/logging-error.service';
import {IProduct} from '../../../shared/models/product';
import {ISubcategoryProducts} from '../../../shared/models/product-subcategory';
import {SubcategoryService} from '../../collection/collection.service';
import {ShopService} from '../../shop.service';

@Component({
  selector: 'bml-collection-product-preview',
  templateUrl: './collection-product-preview.component.html',
  styleUrls: ['./collection-product-preview.component.scss']
})
export class CollectionProductPreviewComponent implements OnInit {
  collections: ISubcategoryProducts[] = [];
  newImageUrl!: string;
  currentProductCardId: string = '';

  productBackground = "white";
  sliderBackground = "linear-gradient(to bottom, #dcdcdc 0%, #322323 100%)" //"#dcdcdc"// "#3e2723" //"#322323" //"#804000" // "#1b0000";
  textColor = "black";

  constructor(private service: SubcategoryService,
              private shopService: ShopService,
              private logger: LoggingErrorService
  ) {
  }

  ngOnInit(): void {
    this.getCollections(false);
    //this.signalRConnection();
  }

  // signalRConnection() {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .configureLogging(signalR.LogLevel.Information)
  //     .withUrl(environment.apiUrl + 'notify')
  //     .build();
  //
  //   connection.start().then(function () {
  //     console.log('SignalR Connected')
  //   }).catch(err => {
  //     return console.error(err.toString())
  //   })
  //
  //   connection.on("BroadcastMessage", () => {
  //     this.getCollections(false);
  //   })
  // }

  getCollections(useCache: boolean) {
    this.service.getCollectionsWithProducts(useCache).subscribe(response => {
      this.collections = response;
    }, error => {
      this.logger.log(error, "Collections-Products")
    })
  }

  createProductClickstream(productId: string) {
    this.shopService.createProductClickstream(productId).subscribe(() => {
    });
  }

  // showColorImage(color: IProductColor, product: IProduct) {
  //   this.currentProductCardId = product.id;
  //   this.newImageUrl = color.mainProductPictureUrl;
  // }

  showColorImage(color: IProductColor, product: IProduct) {
    this.currentProductCardId = product.id;
    if (color.mainProductPictureUrl == null) {
      const tempImage = color.productColorImages.find(x => x.isMain);
      if (tempImage) {
        this.newImageUrl = tempImage?.fileUrl;
      }
    } else {
      this.newImageUrl = color.mainProductPictureUrl;
    }
  }

}
