<div class="preview" *ngIf="brands && brands.length > 0">
  <h3 class="brand-title" routerLink="/shop/products/brands">Brands</h3>
  <ngx-slick-carousel class="carousel" 
      #slickModal="slick-carousel" 
      [config]="slideConfig"
      responsive="breakpoints"
  >
      <div ngxSlickItem *ngFor="let brand of brands" class="slide brand-card">
          <img
            [src]="brand.pictureUrl"
            alt="{{ brand.name }}"
            routerLink="/shop/products/brands/{{brand.id}}/{{brand.slug}}"
            class="image"
            *ngIf="brand.pictureUrl"
          />
          <img                         
              src="/assets/images/product-no-image.jpg"
              routerLink="/shop/products/brands/{{brand.id}}/{{brand.slug}}"
              [alt]="brand.name" 
              class="image no-image"
              *ngIf="!brand.pictureUrl"
          />
      </div>
  </ngx-slick-carousel>
</div>


