<div *ngIf="products && products.length > 0">
  <div class="products">
    <h3 class="deal-title">Accessories you may like</h3>
    <div class="product-container">
      <div class="row">
        <div *ngFor="let item of products" class="col-6 col-sm-6 col-md-4 col-xl-2 p-2 product">
          <bml-product-item [product]="item"></bml-product-item>
        </div>
      </div>
      <div *ngIf="totalCount > 0" class="d-flex justify-content-center mt-3">
        <bml-pagination
          (pageChanged)="onPageChanged($event)"
          [pageNumber]="productParams.pageNumber"
          [pageSize]="productParams.pageSize"
          [totalCount]="totalCount"
        ></bml-pagination>
      </div>
    </div>
  </div>
</div>

