import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { IBasket } from 'src/app/shared/models/shopping-cart';
import { IUserToReturn } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'bml-main-nav-item',
  templateUrl: './main-nav-item.component.html',
  styleUrls: ['./main-nav-item.component.scss']
})
export class MainNavItemComponent implements OnInit {
  @Input() cart$!: Observable<IBasket>;
  @Input() cartTotalItems = 0;
  @Input() currentUser$!: Observable<IUserToReturn>;

  isAccountOpen: boolean = false
  sessionId: string = "current-session";
  cartId: string = "empty-cart";
  radomUUID = uuidv4();

  constructor(private accountService: AccountService, 
    public platform: Platform,
    private router: Router) { 
    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "current-session";
    let cartSessionId = localStorage.getItem(environment.basketStringId);
    this.cartId = cartSessionId ? cartSessionId.toLowerCase() : "shopping-basket";
  }

  ngOnInit(): void {
  }

  logout() {
    this.accountService.logout();
  }

  goToBmlECommerce() {
    window.open(environment.clientUrl, "_blank");
  }

  goToBmlServices() {
    window.open(environment.serviceUrl, "_blank");
  }

  toggleAccount() {
    this.isAccountOpen = !this.isAccountOpen
  }

  reload() {
    setTimeout(() => {
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }, 100);
  }

}
