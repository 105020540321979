<ng-container *ngIf="products">
  <div *ngIf="products.length > 0" class="products">
    <h3 class="deal-title">Shoes you may like</h3>
    <div class="mt-3">
      <bml-product-slider
        [products]="products"
        [sliderBackgroundColor]="sliderBackground"
        [sliderTextColor]="textColor">
      </bml-product-slider>
    </div>
  </div>
</ng-container>
