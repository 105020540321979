import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggingErrorService } from '../logging-error/logging-error.service';
import { IProduct } from '../shared/models/product';
import { IProductCategory } from '../shared/models/product-category';
import { CategoryService } from './category/category.service';
import { SalesOfferService } from './sales-offer/sales-offer.service';
import { ShopService } from './shop.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';  

declare var paypal: any;

@Component({
  selector: 'bml-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit {
  categories: IProductCategory[] = [];
  products: IProduct[] = [];

  addScript = false;
  paypalClientkey = environment.paypal.clientId;
  productPrice: number = 0;

  isSales: boolean = false;
  sessionId: string = "current-session"

  bg1 = "#d9d9d9"
  textColor = "#000";

  bg2 = "#1a0d00"
  textColor2 = '#ffffff'

  constructor(
    private breakpointObserver: BreakpointObserver,
    private categoryService: CategoryService,
    private shopService: ShopService,
    private salesService: SalesOfferService,
    private logger: LoggingErrorService
  ) {
    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "current-session";
  }

  isTablet$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Tablet]) // Fits Handset and Tablet
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  ngOnInit(): void {
    this.getCategories();
    this.getSpecialOffers();
  }

  getCategories() {
    this.categoryService.getCategories().subscribe(
      (response) => {
        this.categories = response;
        response.forEach(element => {
          this.getCategoryProducts(true, element.id)
        });
      },
      (error) => {
        this.logger.log(error, 'shop');
      }
    );
  }

  getCategoryProducts(useChe: boolean, id: string) {    
      this.categoryService.getDepartmentProductsById(id, useChe).subscribe(response => {
        this.products = response.data;
      }, error => {
        this.logger.log(error, "category-detail");
      })  
  }

  getSpecialOffers() {
    this.salesService.getSpecialOffers().subscribe(response => {
      if(response.length > 0) {
        this.isSales = true;
      }
    });
  }

  displayPaypalPayLaterMessage() {
    paypal.Messages({
      amount: (Math.round(this.productPrice * 100) / 100).toFixed(2),
      placement: 'home',
      style: {
        layout: 'flex',
        logo: {
          type: 'inline',
          position: 'top'
        },
        text: {
          color: 'black',
          size: '16',
          align: 'center'
        },
        color: 'white',
        ratio: '20x1'
      }
    })
    .render('.pp-message');
  }

  addPaypalMessageScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id='+this.paypalClientkey+'&components=messages';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }

  ngAfterViewChecked(): void {
    if(!this.addScript) {
      this.addPaypalMessageScript().then(() => {
        this.displayPaypalPayLaterMessage();
      })
    }
  }
}
