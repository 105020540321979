import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from './maintenance.service';
import { IMaintenance } from '../shared/models/maintenance';

@Component({
  selector: 'bml-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  maintenance!: IMaintenance;

  constructor(private service: MaintenanceService) { }

  ngOnInit(): void {
    this.getMaintenance()
  }

  getMaintenance() {
    this.service.getMaintenances().subscribe(response => {
      this.maintenance = response.filter(x => x.platformName === "BML eCommerce" && x.activeStatusFlag)[0];
    })
  }

}
