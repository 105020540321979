<div [class.admin-container]="isAdmin">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h3>Update your Account</h3>
        <div class="card">
          <div class="card-body">
            <form [formGroup]="userAccountForm" (ngSubmit)="onSubmit()">
              <ul class="alert alert-danger" *ngIf="errors">
                <li class="ml-3" *ngFor="let error of errors">{{ error }}</li>
              </ul>
      
              <bml-text-input
                formControlName="firstName"
                [label]="'First Name'"
              ></bml-text-input>
              <bml-text-input
                formControlName="lastName"
                [label]="'Last Name'"
              ></bml-text-input>
              <bml-text-input
                formControlName="userName"
                [label]="'Username'"
                [readonly]="true"
              ></bml-text-input>
      
              <div class="row">
                <div class="col-3">
                  <bml-select-phone-code-input
                    [options]="countriesList"
                    formControlName="countryCode"
                    [label]="'* Dial Code'"
                    [valuekey]="user.countryCode"
                  ></bml-select-phone-code-input>
                </div>
                <div class="col-9">
                  <bml-text-input
                    bmlPhoneMask
                    formControlName="phoneNumber"
                    [label]="'Phone Number'"
                  ></bml-text-input>
                </div>
              </div>
      
              <p for="genre">Choose your Genre</p>
              <div
                class="form-check form-check-inline"
                *ngFor="let genre of genres"
              >
                <input
                  class="form-check-input"
                  formControlName="genre"
                  type="radio"
                  id="{{ genre.id }}"
                  [value]="genre.name"
                  [checked]="user.genre === genre.name"
                />
                <label class="form-check-label" for="{{ genre.id }}">{{
                  genre.name
                }}</label>
              </div>
              <div
                class="alert alert-danger"
                style="border-radius: 0"
                *ngIf="genre?.touched && genre?.invalid"
              >
                <div *ngIf="genre?.errors?.['required']">Genre is required.</div>
              </div>
              <br>
              <button
                [disabled]="userAccountForm.invalid"
                class="bml-stepper-custom-outline-btn"
                type="submit"
                pb-role="submit"
              >
                Update
              </button>
            </form>
            <hr />
            <div class="btn btn-warning" routerLink="/{{ componentName }}/profile">
              <i class="fa fa-backward"></i> Back to Profile
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
