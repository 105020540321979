import { AbstractControl } from '@angular/forms';

export class PasswordValidator {
  static passwordShouldMatch(control: AbstractControl) {
    let newPassword = control.get('password');
    let confirmPassword = control.get('confirmPassword');

    if (newPassword?.value !== confirmPassword?.value) {
      return { passwordShouldMatch: true };
    }

    return null;
  }

  static newPasswordShouldMatch(control: AbstractControl) {
    let newPassword = control.get('newPassword');
    let confirmPassword = control.get('confirmNewPassword');

    if (newPassword?.value !== confirmPassword?.value) {
      return { passwordShouldMatch: true };
    }

    return null;
  }
}
