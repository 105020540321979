import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IProduct, IProductColor } from 'src/app/shared/models/product';
import { environment } from 'src/environments/environment';
import { ShopService } from '../../../shop/shop.service';

@Component({
  selector: 'bml-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnInit {
  @Input() products!: IProduct[];
  @Input() isClothes: boolean = false;
  @Input() sliderBackgroundColor : string = "lightblue";
  @Input() sliderTextColor : string = "#000";
  @Input() productBackgroundColor : string = "transparent";

  sessionId: string = "current-session"

  clothesNumber: number = 7;
  noClothesNumber: number = 5;
  newImageUrl!: string;
  currentProductCardId: string = '';

  slideClothesConfig = { 
    slidesToShow: this.clothesNumber,
    slidesToScroll: this.clothesNumber,
    dots: false,  
    arrows: true,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7
        }
      },
      {
        breakpoint: 1480,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ] 
  };

  slideNoClothesConfig = { 
    slidesToShow: this.noClothesNumber, 
    slidesToScroll: this.noClothesNumber,
    dots: false,  
    arrows: true,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7
        }
      },
      {
        breakpoint: 1480,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ] 
  };

  // slideClothesConfig = { 
  //   slidesToShow: 6,
  //   slidesToScroll: 6,
  //   dots: false,  
  //   arrows: true,
  //   swipeToSlide: true,
  //   infinite: false,
  //   responsive: [
  //     {
  //       breakpoint: 1920,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 5
  //       }
  //     },
  //     {
  //       breakpoint: 1480,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4
  //       }
  //     },
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4
  //       }
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ] 
  // };

  constructor(
    private router: Router,
    private productService: ShopService,
    private breakpointObserver: BreakpointObserver
    ) {  
  }

  isLargeScreen$: Observable<boolean> = this.breakpointObserver
  .observe(['(min-width: 1700px)']) // Fits Handset and Tablet
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  ngOnInit(): void {
    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "";
  }

  showColorImage(color: IProductColor, product: IProduct) {
    this.currentProductCardId = product.id;
    this.newImageUrl = color.mainProductPictureUrl;
    // if(color.mainProductPictureUrl == null) {
    //   const tempImage = color.productColorImages.find(x => x.isMain);
    //   if(tempImage) {
    //     this.newImageUrl = tempImage?.fileUrl;
    //   }
    // }
    // else {
    //   this.newImageUrl = color.mainProductPictureUrl;
    // }
  }

  createProductClickstream(productId: string) {
    this.productService.createProductClickstream(productId).subscribe(() => {});
  }

  goToProductDetail(product: IProduct) {
    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "current-session";
    this.createProductClickstream(product.id)
    this.router.navigate(
      ['/shop/products/' + product.id + '/' + product.slug],
      {
        queryParams: {
          sessionId: this.sessionId,
          'category': product.productCategory.toLowerCase(),
          'brand': product.productBrand.toLowerCase(),
          'product': product.slug.toLowerCase()
        }
      }
    )
  }

  reload() {
    setTimeout(() => {
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }, 100);
  }
}
