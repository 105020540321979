import {Platform} from '@angular/cdk/platform';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import { IProduct, IProductGenre } from 'src/app/shared/models/product';
import {IProductBrand} from 'src/app/shared/models/product-brand';
import {IProductCategory} from 'src/app/shared/models/product-category';
import {IProductSubcategory} from 'src/app/shared/models/product-subcategory';
import {IBasket} from 'src/app/shared/models/shopping-cart';
import {IUserToReturn} from 'src/app/shared/models/user';
import {ProductComponent} from 'src/app/shop/product/product.component';
import {environment} from 'src/environments/environment';
import {v4 as uuidV4} from 'uuid';

@Component({
  selector: 'bml-main-sub-nav-item',
  templateUrl: './main-sub-nav-item.component.html',
  styleUrls: ['./main-sub-nav-item.component.scss']
})
export class MainSubNavItemComponent implements OnInit, AfterViewInit {
  @Input() cart$!: Observable<IBasket>;
  @Input() cartTotalItems = 0;
  @Input() currentUser$!: Observable<IUserToReturn>;
  @ViewChild('search', {static: false}) searchTerm!: ElementRef;
  @ViewChild('stickyMenu') menuElement!: ElementRef;

  @ContentChild(TemplateRef) template!: TemplateRef<ProductComponent>;

  products: IProduct[] = [];
  @Input() categories: IProductCategory[] = [];
  @Input() subcategories: IProductSubcategory[] = [];
  @Input() brands: IProductBrand[] = [];
  @Input() genres: IProductGenre[] = [];
  //shopParams!: ProductParams;
  //totalCount: number = 0;

  sticky: boolean = false;
  isDropdownOpen: boolean = false;
  elementPosition: any;

  sessionId: string = "current-session";
  cartId: string = "empty-cart";
  randomUUID = uuidV4();

  constructor(public platform: Platform,
              private router: Router,) {

    let session = sessionStorage.getItem(environment.sessionStringId);
    this.sessionId = session ? session.toLowerCase() : "current-session";
    let cartSessionId = localStorage.getItem(environment.basketStringId);
    this.cartId = cartSessionId ? cartSessionId.toLowerCase() : "empty-cart";
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= this.elementPosition;
  }

  openDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  reload() {
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
    }, 100);
  }
}
