import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'specialCharacter'
})
export class SpecialCharacterPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let newValue = value.replace(/[^a-zA-Z0-9]+/g, "-")
    return newValue.toLowerCase();
  }

}
