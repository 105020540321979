import { IProductSubcategory } from './product-subcategory';

export interface IProductCategory {
    id: string;
    userId: string;
    name: string;
    shortDescription: string;
    longDescription: string;
    pictureUrl: string;
    slug: string;
    selected: boolean;
    productSubcategories: IProductSubcategory[];
}

export interface ICategoryPagination {
    pageIndex: number;
    pageSize: number;
    count: number;
    data: IProductCategory[];
}

export class CategoryPagination implements ICategoryPagination {
    pageIndex: number = 1;
    pageSize: number = 12;
    count: number = 0;
    data!: IProductCategory[];
}
