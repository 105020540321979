import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { LoggingErrorService } from 'src/app/logging-error/logging-error.service';
import { Department, IProduct } from 'src/app/shared/models/product';
import {
  IBasketItem,
  IBasketItemSavedForLater,
  IBasket,
} from 'src/app/shared/models/shopping-cart';
import { IUserToReturn } from 'src/app/shared/models/user';
import { ShopService } from 'src/app/shop/shop.service';
import { ShoppingCartService } from 'src/app/shopping-cart/shopping-cart.service';

@Component({
  selector: 'bml-cart-table',
  templateUrl: './cart-table.component.html',
  styleUrls: ['./cart-table.component.scss'],
})
export class CartTableComponent implements OnInit {
  currentUser$!: Observable<IUserToReturn>;
  @Input() basket!: IBasket;
  @Input() productQuantity = 0;
  @Input() savedProductQuantity = 0;
  @Input() isChechout: boolean = false;
  @Input() isSavedForLater: boolean = false;

  @Input() savedProduct!: IProduct;

  selectedItems: IBasketItem[] = [];
  isProductSelected: boolean = false;
  form: UntypedFormGroup;

  constructor(
    private accountService: AccountService,
    private cartService: ShoppingCartService,
    private shopService: ShopService,
    private logger: LoggingErrorService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder
  ) {
    this.form = this.fb.group({
      checkArray: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.currentUser$ = this.accountService.currentUser$;
    this.getItemsQuantity();
  }

  saveProductForLater(product: IBasketItem) {
    this.cartService.saveProductForLater(product).subscribe(() => {
      this.removeCartItem(product);
      location.reload();
    });
  }

  getItemsQuantity() {
    this.basket.basketItems.forEach(element => {
      if(element.category === Department.clothes) {
        this.shopService.getProductColors(element.productId).subscribe((response) => {
          const color = response.find(x => x.name === element.color);
          if(color) {
            const colorSize = color.productColorSizes.find(x => x.productColorId == color.id);
            if(colorSize) {
              if(colorSize.quantity > 0) {
                element.stockQuantity = colorSize.quantity;
              }
              else {
                element.stockQuantity = 0;
              }              
            }
            else {
              element.stockQuantity = 0
            }
          }        
        })
      }
      else {
        this.shopService.getProduct(element.productId).subscribe(response => {
          element.stockQuantity = response.quantity;
        })
      }
    });
  }


  removeCartItem(item: IBasketItem) {
    this.cartService.removeItemFromBasket(item);
  }

  incrementCartItem(item: IBasketItem) {
    this.basket.basketItems.forEach(element => {
      this.shopService.getProductColors(element.productId).subscribe((response) => {
        const color = response.find(x => x.name === element.color);
        if(color) {
          const colorSize = color.productColorSizes.find(x => x.productColorId == color.id);
          if(colorSize) {
            if (item.quantity < colorSize.quantity) {
              this.cartService.incrementItemQuantity(item);
            }
            else {
              item.isLimit = true
            }             
          }
          else {
            if (item.quantity < color.quantity) {
              this.cartService.incrementItemQuantity(item);
            }
          }
        } 
        else {
          if (item.stockQuantity) {
            if(item.quantity < item.stockQuantity){
              this.cartService.incrementItemQuantity(item);
            }           
          }
        }       
      })
    });
    // if (item.quantity < this.savedProduct.quantity) {
    //   this.cartService.incrementItemQuantity(item);
    // }
  }

  decrementCartItem(item: IBasketItem) {
    this.cartService.decrementItemQuantity(item);
  }

  /*** Saved for later cart */
  moveProductToCart(product: IBasketItemSavedForLater) {
    this.basket.basketItems = this.basket.basketItems.filter(
      (i) => i.id !== product.id
    );
    this.savedProductQuantity = this.savedProductQuantity - 1;
    if (product) {
      const cfx = this.cartService.moveItemToBasket(product);

      if (cfx !== null) {
        this.deleteSavedItem(product);
      }
    }
  }

  deleteSavedItem(product: IBasketItemSavedForLater) {
    this.basket.basketItems = this.basket.basketItems.filter(
      (i) => i.id !== product.id
    );
    if (product) {
      this.cartService.deleteSavedItem(product).subscribe(
        () => {},
        (error) => {
          this.logger.log(error, 'cart-table');
        }
      );
    }
  }

  onCheckboxChange(e: any, product: IBasketItem) {
    const checkArray: UntypedFormArray = this.form.get('checkArray') as UntypedFormArray;
  
    if (e.target.checked) {
      checkArray.push(new UntypedFormControl(e.target.value));
      this.selectedItems.push(product);
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item) => {
        const productItem = this.selectedItems.find(x => x.id === product.id);
        if (item.value == e.target.value) {
          if(productItem) {
            const index = this.selectedItems.indexOf(productItem);
            this.selectedItems.splice(index, 1);
          }          
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  deleteSelectedItems() {
    if(this.selectedItems.length <= 0) {
      this.toastr.error('Please select items you need to remove from your shopping cart');
    }
    else {
      this.cartService.deleteAllSelectedBasketItems(this.selectedItems).add(() => {
        this.toastr.success(this.selectedItems.length + ' items successfully deleted from your cart');
      })
    }
  }
}
