import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  IError,
  ILoggingError,
  ILoggingErrorForApi,
} from '../shared/models/logging-error';

import {} from '../core/interceptors/error.interceptor';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggingErrorService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  log(error: any, component: string) {
    const errorToLog: ILoggingErrorForApi = {
      component: component,
      statusCode: error.status
        ? error.status + ' : ' + error.statusText
        : error.statusCode,
      errorMessage: error.error
        ? error.error.message
        : error.error
        ? error.error
        : error.message,
      errorDetail: error.errors
        ? error.errors[0]
        : error.error
        ? error.error.details
          ? error.error.details
          : error.message
        : error.message,
    };

    return this.http
      .post(this.baseUrl + 'loggingErrors/', errorToLog)
      .subscribe(() => {
        console.log(
          new Date() + ': ' + JSON.stringify(errorToLog.errorMessage)
        );
        console.log(error);
      });
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = error.errors;
    }
    return throwError(errorMessage);
  }
}
