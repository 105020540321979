import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IPolicy } from 'src/app/shared/models/policy';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrivacyAndPolicyService {
  baseUrl = environment.apiUrl;
  policies: IPolicy[] = [];

  constructor(private http: HttpClient) {}

  getPrivacyPolicies() {
    return this.http.get<IPolicy[]>(this.baseUrl + 'privacyPolicies').pipe(
      map((response) => {
        if (!response) {
          console.log('Error Occured while creating.');
          throw new Error('Value Expected');
        }
        return response;
      })
    );
  }

  getPrivacyPoliciesById(id: string) {
    const policy = this.policies.find((s) => s.id === id);
    if (policy) {
      return of(policy);
    }

    return this.http.get<IPolicy>(this.baseUrl + 'privacyPolicies/' + id);
  }

  createPrivacyPolicies(policy: IPolicy) {
    return this.http.post(this.baseUrl + 'privacyPolicies', policy).pipe(
      map((response) => {
        if (!response) {
          console.log('Error Occured while creating.');
          throw new Error('Value Expected');
        }
        return response;
      }),
      catchError((err) => of([]))
    );
  }

  updatePrivacyPolicies(policy: IPolicy, id: string) {
    return this.http.put(this.baseUrl + 'privacyPolicies/' + id, policy).pipe(
      map((response) => {
        if (!response) {
          console.log('Error Occured while creating.');
          throw new Error('Value Expected');
        }
        return response;
      }),
      catchError((err) => of([]))
    );
  }

  deletePrivacyPolicies(id: string) {
    this.policies = this.policies.filter((val) => val.id !== id);
    return this.http.delete(this.baseUrl + 'privacyPolicies/' + id);
  }
}
