<ng-container *ngIf="clothProducts || noClothProducts">
  <div *ngIf="clothProducts.length > 0 || noClothProducts.length > 0 " class="products">
    <h3 [routerLink]="['/shop/products/sales-and-deals']" class="title">Products On Sale</h3>
    <ng-container *ngIf="clothProducts">
      <div class="product-container">
        <div class="bml-custom-row">
          <div *ngFor="let item of clothProducts | slice:0:14" class="bml-col-7 p-0 pb-1">
            <div class="product">
              <div (click)="createProductClickstream(item.id)"
                   class="card text-white mb-1"
                   routerLink="/shop/products/{{ item.id }}/{{ item.slug }}">
                <img *ngIf="!item.pictureUrl" [alt]="item.name" class="card-img"
                     src="/assets/images/product-no-image.jpg"/>
                <img *ngIf="item.pictureUrl" alt="{{ item.name }}" class="card-img" src="{{ item.pictureUrl }}">
                <div class="card-img-overlay">
                  <div>
                    <p class="card-title">{{ item.productBrand }}</p>
                    <p class="product-name">
                      {{ (item.name.length > 50) ? (item.name | slice:0:50) + '...' : item.name }}
                    </p>
                    <ng-container *ngIf="item.price < item.listPrice">
                      <span class="sale">
                        Save
                        <ng-container *ngIf="item.productDiscountPercent">
                          {{item.productDiscountPercent}}%
                        </ng-container>
                        <ng-container *ngIf="item.specialOfferPercentage">
                          {{item.specialOfferPercentage}}%
                        </ng-container>
                      </span>
                      <p class="product-price">
                        <span class="list-price">{{ item.listPrice | currency }}</span>
                        <span class="actual-price">{{ item.price | currency }}</span>
                      </p>
                      <p *ngIf="item.isOnSpecialOfferSell" class="offer-sale">
                        On Sale
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="noClothProducts.length > 0">
      <div class="product-container">
        <div class="bml-custom-row">
          <div *ngFor="let item of noClothProducts | slice:0:14"
               class="bml-col-7 p-0 pb-1"
          >
            <div class="product">
              <div (click)="createProductClickstream(item.id)"
                   class="card text-white mb-1"
                   routerLink="/shop/products/{{ item.id }}/{{ item.slug }}">
                <img *ngIf="!item.pictureUrl" [alt]="item.name" class="card-img"
                     src="/assets/images/product-no-image.jpg"/>
                <img *ngIf="item.pictureUrl" alt="{{ item.name }}" class="card-img" src="{{ item.pictureUrl }}">
                <div class="card-img-overlay">
                  <div>
                    <p class="card-title">{{ item.productBrand }}</p>
                    <p
                      class="product-name">{{ (item.name.length > 50) ? (item.name | slice:0:50) + '...' : item.name }}</p>
                    <ng-container *ngIf="item.price < item.listPrice">
                      <span class="sale">
                        Save
                        <ng-container *ngIf="item.productDiscountPercent">
                          {{item.productDiscountPercent}}%
                        </ng-container>
                        <ng-container *ngIf="item.specialOfferPercentage">
                          {{item.specialOfferPercentage}}%
                        </ng-container>
                      </span>
                      <p class="product-price">
                        <span class="list-price">{{ item.listPrice | currency }}</span>
                        <span class="actual-price">{{ item.price | currency }}</span>
                      </p>
                      <p *ngIf="item.isOnSpecialOfferSell" class="offer-sale">
                        On Sale
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="clothProducts.length > 12 || noClothProducts.length > 12" class="text-center">
      <button [routerLink]="['/shop/products/sales-and-deals']" class="mb-3" color="primary" mat-raised-button>View
        More
      </button>
    </div>
  </div>
</ng-container>


